import { Component, OnInit } from "@angular/core";
import { UsuarioService } from "src/app/services/usuario.service";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ServerInterface } from "src/app/models/interface/Server.interface";
import { ServicioService } from "src/app/services/servicio.service";
import { LocalDataSource } from "ng2-smart-table";
import * as _ from "lodash";
import * as moment from "moment";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { ServerService } from "src/app/services/server.service";
import { PlanRecurrenteService } from "src/app/services/plan-recurrente.service";
import { setNews, setServicesClient } from "../../utility/settings.table";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image-more";
import { ViewChild, ElementRef } from "@angular/core";
import { TbodyEditDeleteComponent } from "ng2-smart-table/lib/components/tbody/cells/edit-delete.component";
import { funcionesUsuario } from "src/app/services/funcionesUsuario";
import { FormBuilder } from "@angular/forms";
import { count } from "rxjs/operators";
import { italianno } from "../../../assets/fonts/italiano.ttf.Base64.encoded";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-ver-usurario",
  templateUrl: "./ver-usurario.component.html",
  styleUrls: ["./ver-usurario.component.scss"],
})
export class VerUsurarioComponent implements OnInit {
  @ViewChild("mdlVerCobro", { static: true }) mdlVerCobro: ElementRef;
  @ViewChild("mdlCrear", { static: true }) mdlCrear: ElementRef;
  loading = true;
  verMdlInfoSevicio = false;
  switch: boolean = false;
  switch1: boolean = false;
  public planesPorPersona: Array<object> = [];
  public keyword: any = "nameServer";
  usuario: any;
  idUsuario: any;
  public servicios: Array<Object> = [];
  public newsByServer: Array<Object> = [];
  public totalServicio: Array<Object> = [];
  public plantoActivo: Array<Object> = [];
  public source: LocalDataSource;
  public sourceNew: LocalDataSource;
  public server: any;
  public fechaActual = moment().format("YYYY-MM-DD");
  serv: any;
  public TimeMesUser: any;
  public status: any;
  public amountServicios: number;
  public TotalComprado: number;
  public PromedioMensualPagado: number;
  public PromedioMensualServicios: number;
  public CantServicio = 0;
  public comenta: any;
  public crear: boolean;
  public editar: boolean;
  public ingresarComentarioLogistica: boolean = false;
  public ingresarComentarioServer: boolean = false;
  public fechaCert: any;
  public user: any;
  public infoVideos: any;
  public habilSig = false;
  public planSelec: any;
  public valorContrato = this.formBuilder.group({ precio: [""] });
  public diasServicio: Array<Object> = [];

  // public comentario: any;
  public servers: any = [];
  public currentPlanMensual: any = {
    server: {},
    horaInicio: "",
    duracionServicio: "",
    numeroServicios: "",
    diasSemana: "",
    comentarios: "",
    userId: "",
  };
  public comentario = {
    name: "",
    comentario: "",
    fecha: this.fechaActual,
  };
  public comentarioArray: any = [];
  public serversVerificadas: any = [];

  settingsTH = setServicesClient;
  settingsNEW = setNews;
  settingsPagosUsuario = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "verComprobante", title: '<i class="nb-search"></i>' },
        { name: "verCobro", title: '<i class="nb-compose"></i>' },
      ],
    },
    columns: {
      numeroServicios: { title: "num.Servicios" },
      comprobante: {
        title: "Comprobante",
        valuePrepareFunction: (comprobante) => {
          if (comprobante == "falta" || comprobante == "Falta") {
            return comprobante;
          } else {
            return `Subido`;
          }
        },
      },
      estadoDelPago: { title: "Estado del pago" },
      factura: { title: "Factura" },
      fechaAgendamiento: { title: "Fecha de creacion" },
      horas: { title: "Horas" },
      precio: { title: "Precio" },
      recargo: { title: "Recargo" },
      descuento: { title: "Descuento" },
      total: { title: "Total" },
    },
    pager: {
      perPage: 15,
    },
  };
  settingsPlanes = {
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    columns: {
      estado: { title: "Estado" },
      cantidadServicios: { title: "num.Servicios" },
      estadoPago: { title: "Estado del pago" },
      numeroFactura: { title: "Factura" },
      fechaReagendamiento: { title: "Fecha de creacion" },
      horasDeServicio: { title: "Horas" },
      precio: { title: "Precio" },
      recargo: { title: "Recargo" },
      descuento: { title: "Descuento" },
      total: { title: "Total" },
    },
  };
  usuarioEnSecion: any = "juan";
  urlArchivo: string = "falta";
  serversList: any = [];
  blackServerSeleccionada: any;
  serverFavoritaSeleccionada: any;

  public planesRecurrentes: any = [];
  settingsPlanRec = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: "detalle", title: '<i class="nb-search"></i>' },
        { name: "eliminar", title: '<i class="nb-trash"></i>' },
      ],
    },
    columns: {
      checkList: {title: "Checklist"},
      serverName: { title: "Server" },
      horas: { title: "duracion de Servicios" },
      horaInicio: { title: "Hora de inicio" },
      numServicios: { title: "numero de servicios" },
      diasAgendado: {
        title: "Dias del plan",
        type: "htlm",
        valuePrepareFunction: (cell) => {
          return cell;
        },
      },
    },
  };

  constructor(
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private authService: AuthService,
    private serverService: ServerService,
    private toastrService: NbToastrService,
    private usuarioService: UsuarioService,
    private ServicioService: ServicioService,
    private nbDialogService: NbDialogService,
    private funcionesUsuario: funcionesUsuario,
    private planRecurrenteService: PlanRecurrenteService,
    private formBuilder: FormBuilder
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
    this.idUsuario = JSON.parse(this.rutaActiva.snapshot.params.idUsuario);
    console.log("Id que llega",this.idUsuario);
  }

  ngOnInit() {
    this.cargarUsuarios();
    this.getServicios(this.idUsuario);
    this.getServersUnicaVez();
    this.numeroServicios(this.idUsuario);
    this.getcobrosCliente(this.idUsuario);
    this.getPlanesRecurrentesAsociados(this.idUsuario);
    this.getPlanesCliente(this.idUsuario);
    this.getPlanesActivos(this.idUsuario);
    this.fecha();
  }

  // ionViewWillEnter() {
  //   this.fecha();
  // }

  public sourcePagosUsuarios: any;
  public cobrosCliente: any;
  getcobrosCliente(id) {
    this.cobrosCliente = [];
    let count = 0;
    this.ServicioService.getPagosUsuarioByClientId(id).then((res) => {
      if (res.docs.length <= 0) {
        console.log("Sin historial de cobros");
      } else {
        res.docs.forEach((data) => {
          let cobro = data.data();
          this.cobrosCliente.push(cobro);
          count++;
          if (count == res.docs.length) {
            this.sourcePagosUsuarios =  this.cobrosCliente;
            console.log(this.sourcePagosUsuarios);
          }
        });
      }
    });
  }

  public planesUsuario: any = [];

  getPlanesCliente(id) {
    let listaPlanes = [];
    this.ServicioService.getPlanesCliente(id).then((res) => {
      if (res.docs.length > 0) {
        res.docs.forEach((data) => {
          let plan = data.data();
          plan.serverName = plan.server.name + " " + plan.server.apellido;
          listaPlanes.push(plan);
          if (listaPlanes.length == res.docs.length) {
            this.planesUsuario = listaPlanes;
          }
        });
      }
    });
  }

  getPlanesActivos(id) {
    console.log("entro en get planes activos", id);
    this.plantoActivo = [];
    let listaPlanes = [];
    this.ServicioService.getPlanesCliente(id).then((res) => {
      console.log("res.docs.length :", res.docs.length);
      if (res.docs.length > 0) {
        res.docs.forEach((data) => {
          let plan = data.data();
          plan.serverName = plan.server.name + " " + plan.server.apellido;
          listaPlanes.push(plan);
          console.log("estado", plan.estado);
          if (plan.estado != "Cancelado") {
            this.plantoActivo.push(plan);
            console.log("estos son los planes activos", this.plantoActivo);
          }
          if (listaPlanes.length == res.docs.length) {
            this.planesUsuario = listaPlanes;
          }
        });
      }
    });
  }

  verComprobante(id: string = "verComprobanteTs") {
    console.log("etro e ver comprobante");
    console.log(this.comprobanteAvisualizar);
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  numeroServicios(id) {
    let servicios = [];
    let servic: any;
    this.ServicioService.getByUser(id).then((value) => {
      value.docs.forEach((doc) => {
        let aux = doc.data();
        // console.log(aux.fecha);
        if (aux.fecha <= "2020-12-31" && aux.fecha >= "2020-01-01") {
          servicios.push(aux);
        }
      });
      this.planesPorPersona = servicios;
      servic = this.planesPorPersona;
    });
  }

  async cargarUsuarios() {
    this.usuario = (await this.usuarioService.detail(this.idUsuario)).data();
    console.log(this.usuario)
    this.loading = false;
    // this.usuarioService.detail(this.idUsuario).subscribe((res) => {
    //   console.log(res);
    // });
    if (this.usuario.comentario) {
      this.comenta = true;
    } else {
      this.comenta = false;
    }
    this.planRecurrenteService.getPlanRecurrente(this.usuario.id).then((res) => {
      res.forEach((doc) => {
        this.currentPlanMensual = doc.data();
        // console.log(this.currentPlanMensual);
        this.currentPlanMensual.server.nameUser =
          this.currentPlanMensual.server.name +
          " " +
          this.currentPlanMensual.server.apellido;
      });
    });
    let fecha = moment(this.usuario.fecha).format("YYYY-MM-DD");
    this.TimeMesUser = moment().diff(fecha, "month", true);
  }
  public getPlanesRecurrentesAsociados(id) {
    let planesRec: any = [];
    this.ServicioService.getPlanRecurrenteIdClient(id).then((res) => {
      res.docs.forEach((data) => {
        let planRec = data.data();
        planesRec.push(planRec);
        this.planesRecurrentes = planesRec;
      });
    });
  }

  public crearPlanRecurrente() {
    this.currentPlanMensual.userId = this.usuario.id;
    this.planRecurrenteService.post(this.currentPlanMensual).then((res) => {
      this.usuario.planRecurrente = "Si";
      this.usuarioService.updateClient(this.usuario);
      alert("Se actualizo el usuario exitosamente");
    });
  }

  public actualizarPlanRecurrente() {
    // console.log(this.currentPlanMensual)
    this.planRecurrenteService
      .editPlanRecurrente(this.currentPlanMensual)
      .then((res) => {
        this.usuario.planRecurrente = "Si";
        this.usuarioService.updateClient(this.usuario);
        alert("Se actualizo el usuario exitosamente");
      });
  }

  //Permite obtener las servers
  async getServersUnicaVez() {
    // let source: any;
    // let sub = this.serverService
    // .getActivasUnicaVez("estado", "activo")
    //   .pipe()
    //   .subscribe((item) => {
    //     sub.unsubscribe();
    //     source = item;
    //     source.filter(
    //       (Server: ServerInterface) =>
    //         (Server["nameServer"] = `${Server.name} ${Server.apellido}`)
    //     );
    //     this.servers = source;
    //     if (this.usuario.blackList) {
    //       this.servers.forEach((element) => {
    //         let bloqueada = false;
    //         let count = 0;
    //         const length = this.usuario.blackList.length;
    //         for (let idBlack of this.usuario.blackList) {
    //           if (idBlack.id == element.id) {
    //             console.log("server en blacklist", element);
    //             bloqueada = true;
    //           }
    //           count++;
    //           if (count == length && bloqueada == false) {
    //             bloqueada = true;
    //             // console.log("server que paso", element);
    //             this.serversVerificadas.push(element);
    //           }
    //         }
    //       });
    //     } else {
    //       this.serversVerificadas = this.servers;
    //       console.log("Sin server en black list");
    //     }
    //   });

    let source = [];
    const a = await this.serverService.Actives();
    a.docs.forEach((res)=>{
      const server = res.data();
      server.nameServer = `${server.name} ${server.apellido}`
      source.push(server);
      this.servers = source;
      if(this.usuario.blackList){
        const indexArray =  this.usuario.blackList.findIndex(res=>{res.id == server.id })
        console.log(indexArray)
        if(indexArray == -1 ){
          this.serversVerificadas.push(server)
        }
      }else{
        this.serversVerificadas.push(server)
      }
    })

  }

  public onSelectServer(event) {
    // console.log(event)
    this.currentPlanMensual.server = event;
    this.currentPlanMensual.server.nameUser =
      this.currentPlanMensual.server.name +
      " " +
      this.currentPlanMensual.server.apellido;
  }

  public actualizarUsuario() {
    this.usuarioService.updateClient(this.usuario);
  }

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  AddComentario(tipo) {
    this.comentario.name = this.usuarioEnSecion.displayName;
    if (tipo == "server") {
      this.ingresarComentarioServer = true;
    } else if (tipo == "logistica") {
      this.ingresarComentarioLogistica = true;
    }
  }
  //Permite guardar un comentario
  async GuardarComentario(tipo) {
    this.comentario.name = this.usuarioEnSecion.displayName;
    const ret: any =  await this.funcionesUsuario.comentariosCliente(
      tipo,
      this.usuario,
      this.comentario
    );
    this.ingresarComentarioLogistica = false;
    this.ingresarComentarioServer = false;
    this.comentario = {
      name: "",
      comentario: "",
      fecha: this.fechaActual,
    };
  }

  public downloadPDF() {
    var node = document.getElementById("exportProfile");

    var img;
    var filename;
    var newImage;

    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;

          //FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = "mypdf_" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  Cancelar() {
    this.ingresarComentarioLogistica = false;
    this.ingresarComentarioServer = false;
  }

  public cobroVisualizado: any = null;
  public comprobanteAvisualizar: string = "";
  onCustomServicios(event) {
    switch (event.action) {
      case "detalle":
        this.verServicio(event.data.id);
        break;
      case "verComprobante":
        this.comprobanteAvisualizar = event.data.comprobante;
        console.log(event.data.comprobante, "  ", this.comprobanteAvisualizar);
        setTimeout(() => {
          this.verComprobante();
        }, 1000);
        break;
      case "verCobro":
        this.urlArchivo = "falta";
        this.cobroVisualizado = event.data;
        console.log(this.cobroVisualizado);
        // @ts-ignore
        this.nbDialogService.open(this.mdlVerCobro, {
          context: "this is some additional data passed to dialog",
        });
        break;
      case "eliminar":
        this.eliminarPlanRecurrente(event.data);
    }
  }

  public eliminarPlanRecurrente(info){
    this.planRecurrenteService.deletePlanRecurrente(info.id).then(res=>{
      Swal.fire('Éxito',
      'Se Elimino correctamente',
      'success');
    })
  }


  urlnuevoComprobanteCobro: any;
  subirComprobantes(info) {
    let pagos = [];
    this.sourcePagosUsuarios.map((res) => {
      if(res.fechaAgendamiento == info.fechaAgendamiento && res.idPlanOServicio == info.idPlanOServicio) {
        if(this.urlArchivo != "falta") {
          res.comprobante = this.urlArchivo;
          res.estadoDelPago = res.tipoServicio == "Plan Mensual" ? "Pago" : "Aceptada";
        }
        pagos.push(res);
      } else {
        pagos.push(res);
      }
      if (pagos.length == this.sourcePagosUsuarios.length) {
        this.cobrosCliente.cobrosRealizados = pagos;
        this.ServicioService.actualizarCobro(this.cobrosCliente).then((res) => {
          Swal.fire(
            "Actualizado",
            "Se actualizoel cobro con exito",
            "success"
          ).then((res) => {
            const element: HTMLElement = document.getElementById(
              "botonSalir"
            ) as HTMLElement;
            element.click();
          });
        });
      }
    });
  }

  verServicio(id) {
    this.router.navigate(["dashboard/ver-servicio", id]);
  }

  //Obtenemos los servicios agendados por el usuario
  getServicios(id) {
    var count = 0;
    this.ServicioService.getByUser(id).then((value) => {
      var Serv = value.docs;
      let total = 0;
      value.docs.forEach((doc) => {
        let aux = doc.data();
        let FechaCreacion = moment(new Date(aux.fechaCreacion)).format("MM");
        let FechaActual = moment().format("MM");
        if (FechaActual == FechaCreacion) {
          this.CantServicio += 1;
        }
        total += aux.total;
        console.log(aux.id)
        aux.server = aux.server ? `${aux.server.name} ${aux.server.apellido}` : "Desconocido";
        this.servicios.push(aux);
        if (this.servicios.length == Serv.length) {
          this.servicios = _.orderBy(this.servicios, ["fecha"], ["desc"]);
          this.source = new LocalDataSource(this.servicios);
          // this.sourceNew = new LocalDataSource(this.newsByServer);
        }
        // this.ServicioService.getServer(aux).subscribe((infoServer) => {
       
        //   if (this.server.name == undefined && this.server.apellido == undefined
        //   ) {
        //     this.serv = "N/A";
        //     aux.server = this.serv;
        //   } else {
        //     this.serv = this.server.name + " " + this.server.apellido;
        //     aux.server = this.serv;
        //   }
        //   count++;
      
        //   if (aux["news"] !== null && aux["news"] !== undefined) {
        //     aux["news"].map((news) => {
        //       this.newsByServer.push(news);
        //     });
        //   }
       
        // });
      });
      this.amountServicios = Serv.length;
      this.TotalComprado = total;
      this.PromedioMensualPagado = total / this.TimeMesUser;
      this.PromedioMensualServicios = Serv.length / this.TimeMesUser;

      if (this.CantServicio > 0 && this.CantServicio < 5) {
        this.status = "Azul";
      }
      if (this.CantServicio > 4 && this.CantServicio < 13) {
        this.status = "Verde";
      }
      if (this.CantServicio > 12) {
        this.status = "Dorado";
      }
    });
  }

  onUpload(event) {
    let file: any;
    let pictureName: any;
    let image = false;
    let nameImage: any;
    let fotoSeleccionada: any;
    console.log("files", event.target.files[0]);
    file = event.target.files[0];
    pictureName = Date.now() + file.name;
    image = true;
    if (event.target.files && file) {
      nameImage = file.name;
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        fotoSeleccionada = (<FileReader>event.target).result;
        console.log("foto seeccionada", fotoSeleccionada);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      console.log("no asa el primer if");
    }
    if (file && pictureName) {
      this.ServicioService.putPicture(pictureName, file)
        .then((value) => {
          console.log("value", value);
          if (value.state == "success") {
            this.ServicioService.getURL(pictureName).subscribe((url) => {
              this.urlArchivo = url;
              console.log(this.urlArchivo);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no asa el segundo if");
    }
    console.log(this.urlArchivo);
  }

  guadarArchivo(tipo) {
    if (tipo == "contrato") {
      this.usuario.contrato = this.urlArchivo;
    } else if (tipo == "rut") {
      this.usuario.rut = this.urlArchivo;
    } else if (tipo == "cedula") {
      this.usuario.cedula = this.urlArchivo;
    }
    this.usuarioService.updateClient(this.usuario).then((res) => {
      console.log("res", res);
      if (tipo == "contrato") {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "se guardo el contrato"
        );
      } else if (tipo == "rut") {
        this.showToast("top-right", "success", "Éxito!", "se guardo el RUT");
      } else if (tipo.cedula) {
        this.showToast("top-right", "success", "Éxito!", "se guardo la cedula");
      }
    });
  }

  onSelectServerBlackList(event) {
    this.blackServerSeleccionada = event;
    console.log("evento", event);
  }
  gurdarBlackList() {
    let blackServer = {
      id: this.blackServerSeleccionada.id,
      nombreServer:
        this.blackServerSeleccionada.name +
        " " +
        this.blackServerSeleccionada.name,
      foto: this.blackServerSeleccionada.foto,
    };

    if (this.usuario.blackList) {
      this.usuario.blackList.push(blackServer);
    } else {
      this.usuario.blackList = [];
      this.usuario.blackList.push(blackServer);
    }
    this.usuarioService.updateClient(this.usuario);
  }

  onSelectServerFavorita(event) {
    this.serverFavoritaSeleccionada = event;
  }

  gurdarServerFavorita() {
    let serverFavorita = {
      id: this.serverFavoritaSeleccionada.id,
      nombreServer: `${this.serverFavoritaSeleccionada.name} ${this.serverFavoritaSeleccionada.apellido}`,
      foto: this.serverFavoritaSeleccionada.foto,
    };
    this.usuario.serverFavorita = serverFavorita;
    console.log(this.usuario);
    this.usuarioService.updateClient(this.usuario);
  }

  escogerPlan(plan) {
    this.diasServicio = [];
    this.habilSig = true;
    this.planSelec = plan;
    console.log("este es el plan que escogiste", this.planSelec);

    for (let diaServ of this.planSelec.infoServicio) {
      diaServ.fecha = moment(diaServ.fecha).format("DD");
      this.diasServicio.push(diaServ.fecha);
      console.log("dias servicio: ", this.diasServicio);
    }
  }

  public fecha() {
    // const date: Date = new Date();
    // console.log('Date =  '+ date);
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const daysNames = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const interval = setInterval(() => {
      const local = new Date();
      const day = local.getDay();
      const num = local.getDate();
      const month = local.getMonth();
      const year = local.getFullYear();

      this.fechaCert = `${daysNames[day]} ${num} ${monthNames[month]} ${year}`;
    }, 1000);
  }

  async openPDF() {
    console.log("este es el usuario", this.usuario);
    pdfFonts.pdfMake.vfs["italiano_b64"] = italianno;

    pdfMake.fonts = {
      Italianno: {
        normal: "italiano_b64",
        bold: "italiano_b64",
        italics: "italiano_b64",
        bolditalics: "italiano_b64",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
    };

    const documentDefinition = {
      pageSize: {
        width: 550,
        height: 700,
      },
      background: [
        {
          image: "fondo",
          width: 550,
          height: 700,
        },
      ],
      content: [
        {
          text: " CONTRATO DE PRESTACIÓN DE SERVICIO SERV S.A.S \n NIT.901.169.432-3",
          style: "titulo",
        },
        {
          style: "tabla",
          table: {
            body: [
              [
                { text: "CONTRATISTA", style: "subtitulo" },
                { text: "SERV S.A.S NIT.901.169.432-3", style: "texto" },
              ],
              [
                { text: "CONTRATANTE", style: "subtitulo" },
                {
                  text: this.usuario.name + " " + this.usuario.apellido,
                  style: "texto",
                },
              ],
              [
                { text: "VALOR DEL CONTRATO", style: "subtitulo" },
                { text: this.valorContrato.value.precio, style: "texto" },
              ],
              [
                { text: "FORMA DE PAGO", style: "subtitulo" },
                { text: this.planSelec.formaDePago, style: "texto" },
              ],
              [
                { text: "FECHA DE EMISIÓN: ", style: "subtitulo" },
                { text: this.fechaCert, style: "texto" },
              ],
            ],
          },
        },
        {
          text: [
            { text: "OBJETO:", style: "negrilla" },
            "El CONTRATISTA de manera independiente es decir, sin que exista subordinación  y utilizando sus propios medios, prestará sus servicios profesionales y ejecutará actividades bajo criterios de eficiencia, eficacia, diligencia, responsabilidad y calidad, los servicios de aseo y limpieza de la totalidad de las áreas comunes del inmueble ubicado en la dirección: " +
              this.usuario.direccionesArray[0].direccion,
          ],
          style: "texto",
        },
        {
          style: "texto",
          text: "Llevando a cabo actividades tales como: barrer, trapear, cocinar, planchar, aspirar, limpiar y utilizar maquinaria para mantener el espacio con la limpieza y orden requeridos. Para la ejecución de estas actividades, EL CONTRATANTE suministrará la totalidad de los insumos y materiales necesarios, con el fin de mantener en buen estado de higiene y limpieza el inmueble señalado. ",
        },
        {
          text: [
            { text: "Número de servicios: ", style: "negrilla" },
            this.planSelec.totalServicios + "\n",
            { text: "Duración: ", style: "negrilla" },
            this.planSelec.horasDeServicio + " horas" + "\n",
            { text: "Fechas: ", style: "negrilla" },
            this.diasServicio + "\n",
            { text: "Frecuencia: ", style: "negrilla" },
            this.planSelec.frecuencia + "\n",
          ],
          style: "texto",
        },
        {
          text: [
            { text: "FORMA DE PAGO: ", style: "negrilla" },
            "El CONTRATANTE cancelará la por adelantado y a manera de reserva la totalidad de los servicios contratados, de esta manera SERV S.A.S en calidad de CONTRATISTA empezará a prestar los servicios en el cronograma antes mencionado.",
          ],
          style: "texto",
        },
        {
          text: [
            {
              text: "\n\n\n\n\nTERCERA: DURACIÓN DEL CONTRATO:",
              style: "negrilla",
            },
            "TERCERA: DURACIÓN DEL CONTRATO. El término de duración del presente es de " +
              this.planSelec.totalServicios +
              "contados a partir de la fecha " +
              this.planSelec.fechaInicio +
              " y se prorrogará automáticamente si ninguna de las partes cancela la obligación con al menos 8 días de anticipación a la fecha del último servicio. El cronograma de servicios prestados será parte integral del presente contrato que se deberá indicar por medio escrito al inicio de cada mes.",
          ],
          style: "texto",
        },
        {
          style: "texto",
          text: [
            { text: "CUARTA: CANTIDAD DE SERVICIOS.", style: "negrilla" },
            " El número de servicios pactados en este contrato se renovará automáticamente cuando se realice el pago de la factura. El número de servicios no hace referencia al número de días hábiles que contiene el mes del calendario habitual.",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "QUINTA: MODIFICACIONES.", style: "negrilla" },
            "Las modificaciones del presente contrato que afecten directamente al monto contratado se deben solicitar con al menos una (1) semana de anticipación de la fecha del último servicio contratado del plan activo.",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "OBLIGACIONES GENERALES DEL CONTRATISTA:",
              style: "negrilla",
            },
            "Constituyen las principales obligaciones del contratista las siguientes: 1. Obrar con seriedad y diligencia en los asuntos que ",
            { text: "EL CONTRATANTE", style: "negrilla" },
            " le haya contratado. 2. Mantener una comunicación permanente con ",
            { text: "EL CONTRATANTE", style: "negrilla" },
            " e informarle detallada y oportunamente sobre la ejecución de los servicios contratados.",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "OBLIGACIONES DEL CONTRATANTE:", style: "negrilla" },
            " Son obligaciones de ",
            { text: "EL CONTRATANTE:", style: "negrilla" },
            " 1. Realizar el pago de las facturas emitidas por el ",
            { text: "CONTRATISTA ", style: "negrilla" },
            " 2. Suministrar la información necesaria al ",
            { text: "CONTRATISTA", style: "negrilla" },
            " para el desarrollo de sus tareas. 3. Garantizar la ejecución del contrato de conformidad con lo dispuesto en la Ley. 4. Leer y revisar los términos y condiciones del servicio los cuales son parte integral del presente contrato.",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "AUSENCIA DE RELACIÓN LABORAL:", style: "negrilla" },
            " El presente contrato será ejecutado por el contratista con absoluta autonomía e independencia y en desarrollo de este no se generará vínculo laboral alguno entre ",
            { text: "EL CONTRATANTE", style: "negrilla" },
            " y ",
            { text: "EL CONTRATISTA", style: "style" },
            " o sus dependientes o subcontratistas o cualquier otro tipo de personal a su cargo, asumiendo los pagos de planilla de seguridad (ARL, EPS) todos los meses.",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "AUTORIZACIÓN DE TRATAMIENTO DE DATOS: ",
              style: "negrilla",
            },
            "AUTORIZACIÓN DE TRATAMIENTO DE DATOS: " +
              this.usuario.name +
              " " +
              this.usuario.apellido +
              " en calidad de CONTRATANTE en ejercicio de mi derecho a la libertad y autodeterminación informática, AUTORIZO de manera expresa, concreta, suficiente, voluntaria e informada a SERV S.A.S NIT.901.169.432-3 o a quien represente sus derechos u ostente en el futuro a cualquier título la calidad de acreedor a: Consultar, tratar, procesar, operar, verificar, transmitir, transferir, usar, poner en circulación, divulgar, reportar y solicitar toda la información que se refiere a nuestro comportamiento crediticio, financiero, comercial y de servicios de los cuales somos sus titulares, referida al nacimiento, ejecución y extinción de obligaciones dinerarias (independientemente de la naturaleza del contrato que les dé origen)a nuestro comportamiento e historial crediticio, incluida la información positiva y negativa de mis hábitos de pago y aquella que se refiera a la información personal necesaria para el",
          ],
        },
        {
          style: "texto",
          text: "\n\n\n\n\n\n estudio, análisis y eventual otorgamiento de un crédito o celebración de un contrato, para que dicha información sea concernida y reportada en cualquier CENTRAL DEL RIESGOS o BASE DE DATOS, tales como CENTRAL DE INFORMACIÓN DE ENTIDADES FINANCIERAS DE COLOMBIA o PROCRÉDITO o a quien represente sus derechos u opere dichas entidades. La permanencia de mi información en las bases de datos será determinada por el ordenamiento jurídico aplicable, en especial por las normas legales y la jurisprudencia, los cuales contienen mis derechos y obligaciones, que por ser públicos, conozco plenamente. Así mismo manifiesto que conozco el reglamento de las CENTRALES DE RIESGOS. En caso de que en el futuro, el autorizado en este documento efectúe una venta de cartera o una cesión de cualquier título de las obligaciones a mi cargo a favor de un tercero, los efectos de la presente autorización se extenderán a éste, en los mismos términos y condiciones. Ley 1581 de 2012. Las partes acuerdan que el presente documento presta mérito ejecutivo para hacer exigible las obligaciones pecuniarias a las que haya lugar.",
        },
        {
          style: "texto",
          text: [
            { text: "El CONTRATISTA ", style: "negrilla" },
            "otorga su consentimiento informado y expreso para la transferencia de información personal suya y de su personal, en los términos descritos anteriormente, dentro y fuera de Colombia, e incluyendo a países o territorios que no tengan estándares de protección de datos equivalentes a los de Colombia.",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "El CONTRATISTA ", style: "negrilla" },
            "puede solicitar el acceso a su información personal registrada en las bases de datos del ",
            { text: "CONTRATANTE", style: "negrilla" },
            " o solicitar la corrección o supresión de su información personal, contactando directamente al ",
            { text: "CONTRATANTE", style: "negrilla" },
          ],
        },
        {
          style: "texto",
          text: "Las Partes darán fiel cumplimiento a la Ley 1581 de 2012 relativa a la Protección de Datos y Política de Privacidad, su Decreto reglamentario 1377 del 27 de junio de 2013, así como cualesquiera otras normas sobre la protección de datos personales que sustituyan y reglamenten la normativa citada y, en tal sentido, se obligan a usar la información a la que tengan acceso exclusivamente para los fines relacionados con el Acuerdo y a obtener los consentimientos que sean necesarios de las personas cuyos datos personales deba tratar.",
        },
        {
          style: "texto",
          text: [
            { text: "LEGISLACIÓN APLICABLE:", style: "negrilla" },
            "  En todo lo no previsto por ",
            { text: "LAS PARTES", style: "negrilla" },
            " en el presente contrato, ambas se someten a  lo establecido por a los términos y condiciones de la empresa SERV S.A.S publicados en su página web, el Código Civil, El Código de Comercio y demás leyes correspondientes que resulten aplicables\n Página Web: ",
            {
              text: "https://www.serv.com.co/terminos-y-condiciones",
              style: "negrilla",
            },
          ],
        },
        {
          style: "texto",
          text: [
            { text: "ACUERDO ÚNICO: ", style: "negrilla" },
            "Las consideraciones y cláusulas contenidas en el presente contrato deja sin efecto cualquier acuerdo verbal o escrito convenido con anterioridad. Por lo que el presente contrato refleja las condiciones y términos en que ",
            { text: "LAS PARTES", style: "negrilla" },
            " desean obligarse.",
          ],
        },
        {
          style: "texto",
          text:
            "\n\n\n\n\n\n\n\n\n\n\nPara constancia se firma en la ciudad de Bogotá D.C al " +
            this.fechaCert,
        },
        {
          aligment: "justify",
          columns: [
            [
              {
                image: "firma",
              },
              {
                text: "__________________________________________",
                style: "linea",
              },
              {
                text: "Nombre: \n MICHAEL HEREIRA SALCEDO",
                style: "nombre",
              },
              {
                text: "REPRESENTANTE LEGAL SERV SAS",
                style: "nombre",
              },
              {
                text: "C.C:1020804347",
                style: "cedula",
              },
            ],
            [
              {
                text: "\n\n",
                style: "firma",
              },
              {
                text: "__________________________________________",
                style: "linea",
              },
              {
                text:
                  "Nombre: \n " +
                  this.usuario.name +
                  " " +
                  this.usuario.apellido,
                style: "nombre",
              },
              {
                text: "CONTRATANTE",
                style: "nombre",
              },
              {
                text: "C.C: " + this.usuario.documento,
                style: "cedula",
              },
            ],
          ],
        },
      ],
      images: {
        fondo: "https://i.ibb.co/qRjdnZ0/fondo-Contrato-Cliente.png",
        firma: "https://i.ibb.co/whsqcrg/Firma-Mike.png",
      },
      styles: {
        tabla: {
          margin: [0, 0, 0, 20],
        },
        titulo: {
          fontSize: 14,
          color: "#000",
          bold: true,
          margin: [0, 60, 0, 20],
          alignment: "center",
        },
        subtitulo: {
          fontSize: 14,
          color: "#000",
          bold: true,
          margin: [0, 0, 0, 20],
        },
        texto: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 0, 0, 20],
          alignment: "justify",
        },
        texto2: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 10, 0, 20],
          alignment: "justify",
        },
        texto3: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 0, 0, 5],
          alignment: "justify",
        },
        negrilla: {
          fontSize: 10,
          color: "#000",
          bold: true,
        },
        Atentamente: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 70, 0, 20],
        },
        cedula: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 0],
          color: "#000",
        },
        firma: {
          fontSize: 30,
          margin: [0, 0, 0, 2],
          color: "#000",
          font: "Italianno",
          bold: true,
        },
        nombre: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 5],
          color: "#000",
        },
        apellido: {
          fontSize: 30,
          color: "#16304F",
          bold: true,
          margin: [0, 0, 0, 0],
          alignment: "center",
        },
        curso: {
          fontSize: 25,
          color: "#16254F",
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: "center",
        },
        linea: {
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 15],
          color: "#000",
        },
      },
    };

    pdfMake.createPdf(documentDefinition);
    pdfMake.createPdf(documentDefinition).open();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      console.log(blob);
      const b = blob;
      console.log(b, "y lo otro", documentDefinition);
      this.onUploadContrato(b);
    });
    const a = {
      name: "certificado",
      size: 36182,
      type: "application/pdf",
    };

    this.getPlanesActivos(this.idUsuario);
  }

  public onUploadContrato(event) {
    let updateUser: any = this.usuario;
    this.usuarioService.updateClient(updateUser);
    const file: any = event;
    let image = false;
    let nameImage: any;
    let fotoSeleccionada: any;
    const pictureName: any = Date.now() + "certificado";
    image = true;
    if (file) {
      nameImage = file;
      const reader = new FileReader();
      reader.onload = (evento: ProgressEvent) => {
        fotoSeleccionada = (<FileReader>evento.target).result;
        console.log("foto seeccionada", fotoSeleccionada);
      };
      reader.readAsDataURL(event);
      console.log("este es picturName", pictureName);
    } else {
      console.log("no pasa el primer if");
    }
    if (file && pictureName) {
      this.usuarioService
        .putPicture(pictureName, file)
        .then((value) => {
          console.log("value", value);
          if (value.state === "success") {
            this.usuarioService.getURL(pictureName).subscribe((url) => {
              const urlComprobanteServicio = url;
              if (this.usuario.urlContrato === undefined) {
                this.usuario.urlContrato = [];
                console.log("es undefined :", this.usuario.urlContrato);
              }
              let count = 0;
              console.log("antes de for ", this.usuario.urlContrato.length);
              for (let i = 0; i < this.usuario.urlContrato.length; i++) {
                if (this.usuario.urlContrato[i].id === this.planSelec.id) {
                  this.usuario.urlContrato[i] = {
                    url: urlComprobanteServicio,
                    id: this.planSelec.id,
                  };
                } else {
                  count++;
                }
                console.log(
                  "entro en count",
                  count,
                  this.usuario.urlContrato.length
                );
                if (count === this.usuario.urlContrato.length) {
                  this.usuario.urlContrato.push({
                    url: urlComprobanteServicio,
                    id: this.planSelec.id,
                  });
                }
              }
              if (this.usuario.urlContrato.length == 0) {
                this.usuario.urlContrato.push({
                  url: urlComprobanteServicio,
                  id: this.planSelec.id,
                });
              }
              this.usuarioService.updateClient(this.usuario).then(() => {
                Swal.fire({
                  title:
                    "Se ha agregado el contrato del cliente correctamente!",
                  type: "success",
                });
              });
              console.log(urlComprobanteServicio);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no pasa el segundo if");
    }
  }
  abrirModalPDF() {
    const lengt = this.plantoActivo.length;
    if (lengt > 0) {
      /* this.maskLoad(true, 'Preparando formulario'); */
      // @ts-ignore
      this.nbDialogService.open(this.mdlCrear, {
        context: "this is some additional data passed to dialog",
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Lo sentimos",
        text: "No cuenta con planes activos para generar un contrato ",
        showConfirmButton: true,
      });
    }
  }

  /* Lista de tareas */
  clientTaskList = [];
  activeAddNewTask: boolean = false;
  arrayDirationTask = [
    {name: '30 minutos', value: 30},
    {name: '60 minutos', value: 60},
    {name: '90 minutos', value: 90},
    {name: '120 minutos', value: 120},
  ]
  newTask = {
    name: '',
    duration: 0,
    description:'',
  }
  taskHours = 0; 
  async onSaveNewTask(){
    if(this.newTask.description.length > 5 ){
      let newTaskRef = JSON.stringify(this.newTask);
      await this.funcionesUsuario.tareasCliente(this.usuario,newTaskRef);
      this.activeAddNewTask = false;
      this.clientTaskList.push(JSON.parse(newTaskRef));
      this.newTask = {
        name: '',
        duration: 0,
        description:'',
      }
    }
  }

  onCancelNewTask(){
    this.activeAddNewTask = false;
    this.newTask = {
      name: '',
      duration: 0,
      description:'',
    }
  }
  
}