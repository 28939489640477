import { Component, OnInit,ViewChild,ElementRef,Renderer2 } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import * as moment from 'moment';
import * as _ from "lodash";
import { environment } from '../../utility/config';
/* Servicios */
import { ServicioService } from 'src/app/services/servicio.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ServerService } from 'src/app/services/server.service';
import { PagosServerService } from 'src/app/services/pagos-server.service';
import { Metricas } from 'src/app/services/metricas.service'
import { AuthService } from 'src/app/services/auth.service';
import { ServBackendService } from 'src/app/services/serv-backend.service'

import Swal from "sweetalert2";
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

import { festivosColombia } from "src/app/utility/festivosColombia";
import { MatTabGroup } from '@angular/material/tabs';
import { jsPDF } from 'jspdf'
import { CodigoReferidoServerService } from "src/app/services/codigo-referido-server.service";

import { airbnb } from "../../../assets/fonts/airbnb.ttf.Base64.encoded";
import { AirbnbCereal_W_Bd } from "../../../assets/fonts/AirbnbCereal_W_Bd.base64";
import { JazminAltBoldEncoded } from "../../../assets/fonts/JazminAlt-Bold.encoded";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ChangeDetectorRef } from '@angular/core';
const direct_base_value = 679000;
const direct_part_time_base_value = 380000;
@Component({
  selector: 'app-pagos-server',
  templateUrl: './pagos-server.component.html',
  styleUrls: ['./pagos-server.component.scss']
})

export class PagosServerComponent implements OnInit {

  festivosColombia = festivosColombia;
  /* Modals windows*/
  @ViewChild('mdlCausar', { static: true }) mdlCausar: ElementRef;
  @ViewChild('mdlVerIfoPago', { static: true }) mdlVerIfoPago: ElementRef;
  @ViewChild('mdlCausarTabset', { static: false }) mdlCausarTabset: MatTabGroup;
  @ViewChild('pdfContent', { static: false })pdfContent: ElementRef
  /* -------------- */

  loaderGeneral = false;
  fechasfestivas: any;
  tipoContratoConsulta = 'directo';

  source: any = [];
  sourceQuincenales: any = [];
  sourceCausado: any = [];
  sourceCausadoDirectas: any = [];
  sourceCausadoPorServicio: any = [];
  sourcePagados: any = [];
  sourceSinServicios: any = [];
  sourceSinServicioDirectas: any = [];
  sourceSinServicioPorServicio: any = [];
  
  allServersActivas: any;
  myDateRangePickerOptions;
  today = moment().format('YYYY-MM-DD');
  miercoles;
  jueves;
  fechaInicioQuincenal;
  fechaFinalQuincenal;

  /*Variables para casusar*/
  loaderModalCausar = false;
  causarInfo: any;
  abonoArticulos: number = 0;
  arrayProductosPendientes;
  novedadesCausasdas = new Array();
  numNovedades: number = 0;

  //totales
  totalPagoServer:   number = 0;
  totalGananciaServ: number = 0;
  observacionesCausarPago: string = "";
  sourceModalCausar: any = [];

  novedades_directa = {
    trasporte:{valor: 0,cantidad:0},
    diaNoTrabajado:{valor: 0,cantidad:0},
  }
  /*----------------------*/

  // tablaPendietes
  tablaVisualizada = '1';
  @ViewChild('paginator' , { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  tableDataSource: MatTableDataSource<any>;
  columnasTablaPendientes = ['cedula','name','apellido','telefono','email','recargoGeneral', 'recargoTransporte','fechaIngreso'];

  comprobantesPagados: any[];
  cobroVisualizado = {};

/* editar pago servers por servicio */
  new_payment_concept: any = {description: '', assignments: 0,deduction: 0,};
  index_service_selected = 0;
  adding_new_payment_concept = false;
  index_payment_concept_selected = -1;
  editing_payment_concept = false;
  index_general_payment_concept_selected = -1;
  editing_general_payment_concept = false;
  adding_new_general_payment_concept = false;
  
  constructor(
    public renderer: Renderer2,
    public usuarioService: UsuarioService,
    public dialogService: NbDialogService,
    public ServerService: ServerService,
    public pagosServerService: PagosServerService,
    public metricasService: Metricas,
    public datepipe: DatePipe,
    public router: Router,
    public authService: AuthService,
    public servBackendService: ServBackendService,
    public servicioService: ServicioService,
    private codigoReferidoServerService: CodigoReferidoServerService,
    private changeDetectorRef: ChangeDetectorRef

  ) {
    console.log("Hoy",this.today)
  }

  async ngOnInit() {
    await this.getCurrentWeek();
    this.calculateBiweeklyPeriod('first');
  }

  async loadigModule(){
    this.loaderGeneral = true
    this.sourceCausado = await this.loadComprobantesCausados();
    await this.getServers();
    
  }
  public loadComprobantesCausados(){
    return new Promise(async resolve=>{
      console.log(this.jueves,this.miercoles)
      let causados_directas: any = [];
      let causados_porServicio : any = []; 
      if(this.tipoContratoConsulta == 'directo'){
        const queryDirectas = await this.pagosServerService.getPagosServersDirectas(this.fechaInicioQuincenal, this.fechaFinalQuincenal);
        causados_directas = queryDirectas.docs.map(doc => {
          return {
            fullName: `${doc.data().sever.name} ${doc.data().sever.apellido}`,
            recargoTotal: `${doc.data().recargoTransporte} + ${doc.data().recargoGeneral}`,
            ...doc.data()
          };
        });
        this.sourceCausadoDirectas = _.orderBy(causados_directas, ['fechaFin'], ['desc'])
      }else{
        const queryPorServicios = await this.pagosServerService.getPagosServersPorServicio(this.jueves,this.miercoles,'causado')
        causados_porServicio = queryPorServicios.docs.map(doc => {
          return {
            fullName: `${doc.data().sever.name} ${doc.data().sever.apellido}`,
            recargoTotal: `${doc.data().recargoTransporte} + ${doc.data().recargoGeneral}`,
            ...doc.data()
          };
        });
        this.sourceCausadoPorServicio = _.orderBy(causados_porServicio, ['fechaFin'], ['desc'])
      }
      let causadosArray = causados_porServicio.concat(causados_directas);
      this.sourceCausado = _.orderBy(causadosArray, ['fechaFin'], ['desc'])
      console.log( 'tERMINO DE CARGAR EL sourceCausado',this.sourceCausado)
      resolve(this.sourceCausado)
    })
  }

  onCambiarTipoContratoConsulta(){
    if(  this.tipoContratoConsulta == 'directo') this.tipoContratoConsulta = 'por servicio';
    else if(  this.tipoContratoConsulta == 'por servicio') this.tipoContratoConsulta = 'directo';
    this.changeTable(this.tablaVisualizada);
  }

  // Function to get current week, the week start previous thursday and end next wednesday
  getCurrentWeek() {
    let nextWednesday = new Date();
    let previousThursday = new Date();
    let hoy = moment().format("YYYY-MM-DD");
    // get next Wednesday
    nextWednesday.setDate(nextWednesday.getDate() + (3 - 7 - nextWednesday.getDay()) % 7);
    if (nextWednesday.getDay() == 3 && nextWednesday.getHours() > 19 && nextWednesday.getMinutes() > 30) {
      nextWednesday.setDate(nextWednesday.getDate() + (3 + 7 - nextWednesday.getDay()));
    }
    this.miercoles = moment(nextWednesday).format('YYYY-MM-DD');
    // get previous Thursday
    previousThursday.setDate(previousThursday.getDate() + (4 - 7 - previousThursday.getDay()) % 7);
    if (previousThursday.getDay() == 4 && previousThursday.getHours() > 19 && previousThursday.getMinutes() > 30) {
      previousThursday.setDate(previousThursday.getDate() + (4 - 7 - previousThursday.getDay()));
    }

    let juevesValidacion = moment(previousThursday).format("YYYY-MM-DD");
    if(juevesValidacion === hoy){
      previousThursday.setDate(previousThursday.getDate() - 7);
    }

    this.jueves = moment(previousThursday).format("YYYY-MM-DD");
    if(this.jueves > this.miercoles){
      nextWednesday.setDate(nextWednesday.getDate() + 7);
      this.miercoles = this.datepipe.transform(nextWednesday, 'yyyy-MM-dd');
    }
  }
  
  public changeWeek(tipo) {
    if (tipo == 'next') {
      this.jueves = moment(this.jueves).add(7, 'days').format('YYYY-MM-DD');
      this.miercoles = moment(this.miercoles).add(7, 'days').format('YYYY-MM-DD');
    } else if (tipo == 'back') {
      this.jueves = moment(this.jueves).subtract(7, 'days').format('YYYY-MM-DD');
      this.miercoles = moment(this.miercoles).subtract(7, 'days').format('YYYY-MM-DD');
    }
  }

  // Function to get current biweekly period, and forward or backward
  calculateBiweeklyPeriod(type){

    if(type == 'first'){
      console.log( 'Fecha inicial',parseInt(moment().format('D')) , 15)
      if( parseInt(moment().format('D')) < 15 ){
        this.fechaInicioQuincenal = moment().startOf('month').format('YYYY-MM-DD');
        this.fechaFinalQuincenal = moment().format('YYYY-MM-15');
      }else{
        this.fechaInicioQuincenal = moment().format('YYYY-MM') + "-16";
        this.fechaFinalQuincenal = moment().endOf('month').format('YYYY-MM-DD');
      }
    }else{
      console.log( moment(this.fechaInicioQuincenal).format('D') );
      if(type == 'mas'){
        if(  moment(this.fechaInicioQuincenal).format('D') == '1'){
          this.fechaInicioQuincenal = moment( this.fechaInicioQuincenal ).add(15,'days').format('YYYY-MM-DD');
          this.fechaFinalQuincenal = moment( this.fechaFinalQuincenal ).endOf('month').format('YYYY-MM-DD');
        }else{
          this.fechaInicioQuincenal = moment( this.fechaInicioQuincenal ).add(1,'month').startOf('month').format('YYYY-MM-DD');
          this.fechaFinalQuincenal = moment( this.fechaFinalQuincenal ).add(1,'month').format('YYYY-MM-15');
        }
      }else if(type == 'menos'){
        if(  moment(this.fechaInicioQuincenal).format('D') == '1'){
          this.fechaInicioQuincenal = moment( this.fechaInicioQuincenal ).subtract(1,'month').format('YYYY-MM-16');
          this.fechaFinalQuincenal = moment( this.fechaFinalQuincenal ).subtract(1,'month').endOf('month').format('YYYY-MM-DD');
        }else{
          this.fechaInicioQuincenal = moment( this.fechaInicioQuincenal ).startOf('month').format('YYYY-MM-DD');
          this.fechaFinalQuincenal = moment( this.fechaFinalQuincenal ).format('YYYY-MM-15');
        }
      }
    }
  }

  async getServers() {
    // directo | por servicio
    this.allServersActivas = await this.ServerService.getServersToCause(this.tipoContratoConsulta);
    this.validarServersTraidas(this.allServersActivas)
  }

  async validarServersTraidas(arrayServers){
    if(this.tipoContratoConsulta == 'por servicio'){
      this.source = [];
      this.sourceSinServicioPorServicio = [];
    }
    else if(this.tipoContratoConsulta == 'directo'){
      this.sourceQuincenales = [];
      this.sourceSinServicioDirectas = [];
    }

    const promises = arrayServers.map(async (res)=>{
      const server = res.data();
      let index_causados = this.sourceCausado.findIndex(res=>res.idServer == server.id || res.idSever == server.id )
      let index_pagados = this.sourcePagados.findIndex(res=>res.idServer == server.id)
    
     
      // console.log(`${server.name} ${server.apellido}, ${index_causados} ${index_pagados}`)
 
      if(index_causados == -1 && index_pagados == -1){
        const start_date = server.estado == 'desactivado' ? moment().subtract(90,'days').format('YYYY-MM-DD') : this.tipoContratoConsulta == 'directo' ? this.fechaInicioQuincenal : this.jueves;
        const end_date = this.tipoContratoConsulta == 'directo' ? this.fechaFinalQuincenal : this.miercoles;
        const serviciosPorCausar = (await this.servicioService.getByStateAndServerInDataRange(res.id,start_date,end_date,"Terminado")).size;
        console.log('servicios por causar',serviciosPorCausar);
        if(serviciosPorCausar == 0) {
          this.tipoContratoConsulta == 'directo' ? this.sourceSinServicioDirectas.push(server) : this.sourceSinServicioPorServicio.push(server)
          return true;
        }else{
          server.pagoTotal = 0;
          server.horasTotales  = 0;
          server.gananciaBase  = 0;
          server.gananciasServ = 0;
          server.recargoGeneral  = 0;
          server.recargoTransporte = 0;
          server.numeroServicios = serviciosPorCausar;
          server.gananciaBonoSeguridadSocial = 0;
          server.gananciaBonoPuntualidad_App = 0;
          server.validada = false;
          server.fechaIngreso = moment(server.fecha).format('YYYY-MM-DD');
          server.costoDeTransferencia =  server.tipoCuentaBancaria == 'TPaga' ? 2700 : 2700;
          this.tipoContratoConsulta == 'directo' ? this.sourceQuincenales.push(server) : this.source.push(server);
          return true;
        }
      }
    })
    let a = await Promise.all(promises);
    console.log('paso 2')
    // this.tableDataSource = new MatTableDataSource(this.source);
    // this.tableDataSource.paginator = this.paginator;
    // this.tableDataSource.sort = this.sort;
    this.changeTable(this.tablaVisualizada);
    this.loaderGeneral = false;
    // console.log("Servers para causar", this.tableDataSource.data.length);
    // this.generarIntervaloActual(this.jueves, this.miercoles);
  }

  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.tableDataSource)
  }

  changeTable(option){
    this.tablaVisualizada = option;
    console.log(this.tipoContratoConsulta,option);
    switch (option) {
      case '1':
        this.columnasTablaPendientes = ['name','apellido','numeroServicios','fechaIngreso']
        this.tableDataSource = this.tipoContratoConsulta == 'directo' ? new MatTableDataSource(this.sourceQuincenales) : new MatTableDataSource(this.source);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
        break;
      case '2':
        console.log(this.sourceCausado)
        this.columnasTablaPendientes = ['fullName','totalServicios','pagoNetoServer']
        this.tableDataSource = this.tipoContratoConsulta == 'directo' ?  new MatTableDataSource(this.sourceCausadoDirectas) : new MatTableDataSource(this.sourceCausadoPorServicio); 
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
        break;
      // case '3':
      //   console.log(this.sourcePagados)
      //   this.columnasTablaPendientes = ['fullName','totalServicios','pagoNetoServer',]
      //   this.tableDataSource = new MatTableDataSource(this.sourcePagados);
      //   this.tableDataSource.paginator = this.paginator;
      //   this.tableDataSource.sort = this.sort;
      //   break;
      case '4':
        console.log('directas',this.sourceSinServicioDirectas)
        console.log('por servicio',this.sourceSinServicioPorServicio)
        this.columnasTablaPendientes = ['name','apellido','totalServicios','pagoNetoServer','fechaIngreso']
        this.tableDataSource = this.tipoContratoConsulta == 'directo' ?  new MatTableDataSource(this.sourceSinServicioDirectas) : new MatTableDataSource(this.sourceSinServicioPorServicio); 
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case '5':
        this.columnasTablaPendientes = ['name','apellido','numeroServicios','fechaIngreso']
     
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      default:
        console.log('opcion Invalida');
    }
  }

 

  onCustom(event) {
    switch (this.tablaVisualizada) {
      case '1' || '4':
        if (this.tipoContratoConsulta == 'directo') this.verDirecta(this.mdlCausar,event);
        else this.verPorServicio(this.mdlCausar,event);
        break;
      case '2':
        this.verCausado(this.mdlVerIfoPago,event)
        break;
       
    }
  }

  verCausado(dialog,info) {
    this.cobroVisualizado = info;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  async verPorServicio(dialog,info){
    this.loaderModalCausar = true;
    this.causarInfo = info;
    const fechaInicio =  this.causarInfo.estado == 'desactivado' ? moment().subtract(90,'days').format('YYYY-MM-DD') : this.jueves;
    this.sourceModalCausar = await this.getServiciosParaCausar(fechaInicio,this.miercoles);
    this.causarInfo.servicios = this.sourceModalCausar;
    this.causarInfo.numeroServicios = this.sourceModalCausar.length;
    await this.revisarGananciaServicios();
    const revisandoNovedades = await this.consultarNovedades(this.causarInfo.id);
    await this.revisarComprasServer(this.causarInfo.id);
    this.calculateTotals();
    this.causarInfo.validada = true
    // if(this.causarInfo.validada == false ){

    // }else{
    //   await this.revisarComprasServer(this.causarInfo.id)
    // }
    this.loaderModalCausar = false;
    if(dialog != 'recalcular'){
      this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }
  }

  // let descuetnoPeriodoPrueba = 0;
  revisarGananciaServicios(){
    // console.log('Diferencia en meses fecha de ingreso',moment().diff( moment(this.causarInfo.fecha).format('YYYY-MM-DD'),'month'))
    // if(moment().diff( moment(this.causarInfo.fecha).format('YYYY-MM-DD'),'month') < 3){
    //   descuetnoPeriodoPrueba = 5000;
    // }

    // this.causarInfo.descuetnoPeriodoPrueba = descuetnoPeriodoPrueba;
    this.causarInfo.servicios.map(servicio=>{
      servicio.payment_concepts_list = [];
      servicio.recargo = servicio.recargo ? servicio.recargo : 0;
      servicio.recargoTransporte = servicio.recargoTransporte ? servicio.recargoTransporte : 0;

      const a = servicio.horasDeServicio == 4 ? (servicio.gananciaServer) : (servicio.gananciaServer);/*  - descuetnoPeriodoPrueba */
      servicio.base = servicio.horasDeServicio == 4 ? (servicio.gananciaServer - 10000) : (servicio.gananciaServer - 10000); /* - descuetnoPeriodoPrueba */

      this.causarInfo.pagoTotal    += a;
      this.causarInfo.gananciaBase += servicio.base;
      if(moment().diff( moment(this.causarInfo.fechaIngreso).format('YYYY-MM-DD'),'month') >= 1){
        servicio.bonoSeguridadSocial ? this.causarInfo.gananciaBonoSeguridadSocial += 5000 : console.log('Sin bono seguridadSocial')
      }else if( moment().diff( moment(this.causarInfo.fechaIngreso).format('YYYY-MM-DD'),'month') < 1){
        servicio.bonoPuntualidad_App ? this.causarInfo.gananciaBonoPuntualidad_App += 5000 : console.log('Sin bono puintualidadApp')
        servicio.bonoSeguridadSocial = false;
      }

      this.causarInfo.horasTotales   += parseInt(servicio.horasDeServicio);
      this.causarInfo.gananciasServ  += servicio.gananciaServ;
      this.causarInfo.recargoGeneral += servicio.recargo;
      this.causarInfo.recargoTransporte += servicio.recargoTransporte;
      
      const concept_1 = { description: `Servicio ${servicio.horasDeServicio}H` ,assignments: servicio.base,deduction: 0};
      const concept_2 = { description: `Bono puntualidad`, assignments: servicio.bonoSeguridadSocial == true ? 5000 : 5000 , deduction: 0};
      const concept_3 = { description: `Seguridad social`, assignments: servicio.bonoPuntualidad_App == true ? 0 : 0 , deduction: 0};
      const concept_4 = { description: `Propina`, assignments: servicio.propina == true ? 5000 : 0 , deduction: 0};
      servicio.subTotal = concept_1.assignments + concept_2.assignments + concept_3.assignments + concept_4.assignments;
      servicio.payment_concepts_list.push(concept_1,concept_2,concept_3);
      if(servicio.propina && servicio.propina > 0) servicio.payment_concepts_list.push(concept_4);
      if(servicio.recargo > 0) servicio.payment_concepts_list.push({description: `Recargo` ,assignments:  servicio.recargo , deduction: 0,})
      if(servicio.recargoTransporte > 0) servicio.payment_concepts_list.push({description: `Recargo transporte` ,assignments:  servicio.recargoTransporte , deduction: 0,}) 
    });
    this.causarInfo.general_payment_concepts_list = [
      {
      date: moment().format("YYYY-MM-DD"),
      description: 'Costo transaccion',
      assignments:  0,
      deduction: this.causarInfo.costoDeTransferencia,
      },
      {
        date: moment().format("YYYY-MM-DD"),
        description: 'Abono uniforme',
        assignments:  0,
        deduction: 0,
      },
    ]

    if(this.causarInfo.tipoManejoSeguridadSoscial != undefined && this.causarInfo.tipoManejoSeguridadSoscial == 'pagoCuotas'){
      this.causarInfo.general_payment_concepts_list.push({ 
        date: moment().format("YYYY-MM-DD"),
        description: 'Abono seguridad social',
        assignments:  0,
        deduction: this.causarInfo.tipoSeguradaSocial == 'tipo1' ? 32500 : 41250,
      })
    }
    // moment().diff( moment(this.causarInfo.fechaIngreso).format('YYYY-MM-DD'),'month') >= 1
    // if(this.causarInfo.balance_bonos && moment().diff( moment(this.causarInfo.fechaIngreso).format('YYYY-MM-DD'),'month') >= 1){
    // if(this.causarInfo.balance_bonos){
    //   this.causarInfo.general_payment_concepts_list.push(
    //     {
    //       date: moment().format("YYYY-MM-DD"),
    //       description: 'Bonos seguridad social acumulados',
    //       assignments:  this.causarInfo.balance_bonos.social_security,
    //       deduction: 0,
    //     },
    //     {
    //       date: moment().format("YYYY-MM-DD"),
    //       description: 'Bonos puntualidad acumulados',
    //       assignments:  this.causarInfo.balance_bonos.timeliness,
    //       deduction: 0,
    //     },
    //   )
    // }
    // }
  }
  consultarNovedades(idServer){
    return new Promise(async result =>{
      try{
        this.novedadesCausasdas = [];
        this.causarInfo.valorNovedades = 0;
        this.causarInfo.valorNovedadesApeladas = 0;
        this.causarInfo.valorNovedadesAdicionales = 0;
        const res_novedades = await this.metricasService.getNovedadesVigentesServer(idServer,this.miercoles)
        for(let data of res_novedades.docs){
          const novedad = data.data()
          if(novedad.fechaCreacion >= this.jueves){
            // console.info(novedad)
            if(novedad.categoria == 'Logistica'){
              let valor = 0;
              if(novedad.intencion == "suma") this.causarInfo.valorNovedades += novedad.costo;
              if(novedad.intencion == "resta") this.causarInfo.valorNovedades -=  novedad.costo;
              const info ={
                date: moment(novedad.fechaDelServicio).format("YYYY-MM-DD"),
                description: novedad.tipo,
                assignments: novedad.intencion == "suma" ? novedad.costo : 0,
                deduction: novedad.intencion == "resta" ? novedad.costo : 0,
              }
              this.causarInfo.general_payment_concepts_list.push(info)
              this.numNovedades ++;
            }
            this.novedadesCausasdas.push(novedad)
          }
        }
        result('Listo')
      }catch(error){
        console.error('Ocurrio un error al copnsultar las novedades')
        result('Error novedades')
      }
    })
  }
  revisarComprasServer(idServer){
    console.log("Entro en revisarComprasServer");
    this.arrayProductosPendientes = {};
    this.ServerService.getProductosServer(idServer).then(res=>{
      if(res.data() != undefined){
        this.arrayProductosPendientes = res.data();
        this.arrayProductosPendientes.productos.map(info=>{
          info.saldo_a_pagar = info.saldo;
          this.abonoArticulos = this.abonoArticulos + info.saldo;
        });
        console.log(this.arrayProductosPendientes)
      }
      // this.calcularValoresDeCausacion()
    })
  }
  calculateTotals(){
    this.causarInfo.total = 0;
    this.causarInfo.totalServices = 0;

    for(let i = 0;i < this.causarInfo.servicios.length;i++ ){
      this.causarInfo.total +=  this.causarInfo.servicios[i].subTotal
      this.causarInfo.totalServices += this.causarInfo.servicios[i].subTotal
    }

    for(let i = 1;i < this.causarInfo.general_payment_concepts_list.length;i++){
      let sum = this.causarInfo.general_payment_concepts_list[i].assignments - this.causarInfo.general_payment_concepts_list[i].deduction
      this.causarInfo.total += sum;
    }

    this.causarInfo.costoDeTransferencia = 2700;
    this.causarInfo.general_payment_concepts_list[0].deduction = this.causarInfo.costoDeTransferencia;
    this.causarInfo.total -= this.causarInfo.general_payment_concepts_list[0].deduction
  }

  async verDirecta(dialog,info) {
    this.loaderModalCausar = true;
    this.loaderGeneral = true;
    console.info("Ver directa",info);
    this.cobroVisualizado = info;
    this.causarInfo = info;

    const fechaInicio =  this.causarInfo.estado == 'desactivado' ? moment().subtract(90,'days').format('YYYY-MM-DD') : this.fechaInicioQuincenal;
    this.sourceModalCausar = await this.getServiciosParaCausar(fechaInicio,this.fechaFinalQuincenal);
    this.causarInfo.servicios = this.sourceModalCausar;
    this.causarInfo.numeroServicios = this.sourceModalCausar.length;
    this.causarInfo.valorBonos = 0;
    this.causarInfo.bonos = [];
    const revisandoNovedades = await this.consultarNovedadesServerDirecta(this.causarInfo);
    const resBonos = await this.calcularBonosServerDirecta();
    await this.calcularValorFinalServerDirecta();
    this.loaderModalCausar = false;
    this.loaderGeneral = false;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  getServiciosParaCausar(start_date,end_date){
    return new Promise(async result=> {
      const query = await this.servicioService.getByerverInDataRange(this.causarInfo.id,start_date,end_date,"Terminado");
      const services: Array<any> = query.docs.map(doc=>{
        return {
          ...doc.data()
        }; 
      })

      const serviciosSinCausar = services.filter(service=>service.estadoPagoServer !=='causado' && (service.estado == 'Terminado' || service.estado == 'En curso'))
      return result(serviciosSinCausar)
    })
  }
  consultarNovedadesServerDirecta(server){
    this.novedadesCausasdas = [];
    return new Promise(async result =>{
      try{
        this.numNovedades = 0;
        this.causarInfo.valorNovedades = 0;
        this.causarInfo.valorDiasNoTrabajados = 0;
        this.novedades_directa = {
          trasporte:{valor: 0,cantidad:0},
          diaNoTrabajado:{valor: 0,cantidad:0},
        }
        const date = this.fechaFinalQuincenal;
        const res_novedades = await this.metricasService.getNovedadesVigentesServerDirecta(server.id,date);
        for(let data of res_novedades.docs){
          const novedad = data.data();
          if(novedad.categoria == 'Server directa'){
            this.numNovedades ++;
            if(novedad.tipo == "Bono buen servicio"){
              // this.causarInfo.valorBonos += (novedad.costo);
              // this.causarInfo.bonos.push(novedad)
              continue;
            }
            if(novedad.tipo == "No trabajo este dia") {
              // this.causarInfo.valorNovedades -= novedad.costo
              this.causarInfo.valorDiasNoTrabajados -= novedad.costo
              this.novedades_directa.diaNoTrabajado.cantidad++
              this.novedades_directa.diaNoTrabajado.valor += novedad.costo
            }
            else if(novedad.tipo == "Transporte adicional"){
              this.causarInfo.valorNovedades += (novedad.costo)
              this.novedades_directa.trasporte.cantidad++;
              this.novedades_directa.trasporte.valor += novedad.costo
            }else{
              if(novedad.intencion == "suma")this.causarInfo.valorNovedades +=  novedad.costo;
              if(novedad.intencion == "resta")this.causarInfo.valorNovedades -=  novedad.costo;
            }
          }
          this.novedadesCausasdas.push(novedad);
        }
        result(true)
      }catch(error){
        console.error(error);
        result(false)
      }
    })
  }
  async calcularBonosServerDirecta(){
    /* Bono multi servioicio */
    const conteoFechas: { [key: string]: number } = this.sourceModalCausar.reduce((acc, element) => {
      acc[element.fecha] = element.servicio == "Administrativo" ? (acc[element.fecha] || 0) + 5 : (acc[element.fecha] || 0) + 1;
      return acc
    }, {} as { [key: string]: number });
    
    const fechasConMasDeUno = Object.entries(conteoFechas).filter(([fecha, count]) => count == 2 || count == 6)
      .map(([fecha, count]) =>{
        let res = {date: fecha,type: count}
        return res
      });

    for(let date of fechasConMasDeUno){
      const bonus = {
        costo: date.type == 2 ? 10000 : 3000,
        tipo:  date.type == 2 ? "Doble servicio" : "Doble Servicio, uno administreativo",
        fechaCreacion: date.date
      }
      this.causarInfo.valorBonos += bonus.costo;
      this.causarInfo.bonos.push(bonus);
    }

    /* Bono por clientes referidos */
    const referidos = (await this.codigoReferidoServerService.getByDateInSubcolection(
                        this.causarInfo.id,'redeemed',this.fechaInicioQuincenal,this.fechaFinalQuincenal
                      )).size;
    if(referidos > 0){
      const bonus = {
        costo: 20000 * referidos,
        tipo:  "Bono por clientes referido",
        fechaCreacion: this.today
      }
      this.causarInfo.valorBonos += bonus.costo;
      this.causarInfo.bonos.push(bonus);
    }
    return true;
  }
  
  async calcularValorFinalServerDirecta(){

    console.log('Lista de bonos',this.causarInfo.bonos)

    this.causarInfo.valorBase = this.causarInfo.diponibilidadHoraria == "full time" ? environment.basic_salary : (environment.basic_salary/2);
    this.causarInfo.valorBaseHora = (this.causarInfo.valorBase/184);
    this.causarInfo.valorCostoTransferencia = 2700;
    this.causarInfo.totalServices = 0;
    this.causarInfo.horasTotales = 0;
    this.fechasfestivas =  await this.validateHolyDay();
    console.log(this.fechasfestivas);

    let horasFestivas = this.fechasfestivas.length * 8;


    for(let i = 0;i < this.causarInfo.servicios.length;i++ ){ 
      this.causarInfo.totalServices += this.causarInfo.servicios[i].total;
      this.causarInfo.horasTotales  += parseInt(this.causarInfo.servicios[i].horasDeServicio);
      if(this.fechasfestivas.some(fecha=>fecha == this.causarInfo.servicios[i].fecha)) horasFestivas -= 8;
    }
    this.causarInfo.horasTotales + horasFestivas;
    console.log('horas festivas',horasFestivas);
    this.causarInfo.diasCalculados = 15;
    if(this.causarInfo.fechaInicioContrato > this.fechaInicioQuincenal){
      this.causarInfo.diasCalculados = moment().diff(moment(this.causarInfo.fechaInicioContrato),'days') + 1;
      if(this.today > this.fechaFinalQuincenal)  this.causarInfo.diasCalculados = moment(this.fechaFinalQuincenal).diff(moment(this.causarInfo.fechaInicioContrato),'days') 
    }else{
      this.causarInfo.diasCalculados = moment().diff(moment(this.fechaInicioQuincenal),'days') + 1;
      if(this.today > this.fechaFinalQuincenal)  this.causarInfo.diasCalculados = 15;
    }
    let diasTrabajados =  this.causarInfo.diasCalculados - this.novedades_directa.diaNoTrabajado.cantidad;

    this.causarInfo.valorHorasTrabajadas =  Math.min(this.causarInfo.horasTotales, 92) * this.causarInfo.valorBaseHora;
    this.causarInfo.valorHorasExtraTrabajadas = 0;
    if(this.causarInfo.horasTotales > 92){
      // console.log('valor hora extra',this.causarInfo.valorBaseHora * 1.25);
      console.log(this.causarInfo.horasTotales - 92)
      this.causarInfo.valorHorasExtraTrabajadas = 6915 * (this.causarInfo.horasTotales - 92) /* (this.causarInfo.valorBaseHora * 1.25) */
    }

    // this.causarInfo.valorSalarioDevengado = ((this.causarInfo.valorBase/30) * diasTrabajados); // devengado basado en dias
  


    this.causarInfo.valorSalarioDevengado = this.causarInfo.valorHorasTrabajadas + this.causarInfo.valorHorasExtraTrabajadas;
    this.causarInfo.valorAuxilioDetransporte = ((environment.transport_allowance/30) * diasTrabajados );

    this.causarInfo.cantidadPrestaciones = 2;
    this.causarInfo.valorPrestaciones = ((this.causarInfo.valorSalarioDevengado)/100)*4; // se saca solo con el valor de environment.basic_salary por dias trabajado,
    if(this.causarInfo.tipoDocumento != "Cédula de ciudadania") this.causarInfo.cantidadPrestaciones = 1;

    this.causarInfo.diasTrabajados = diasTrabajados;
    this.causarInfo.auxilioTransporte = (environment.transport_allowance/30) *  this.causarInfo.diasCalculados;

    console.log(this.causarInfo.disponibilidadSenama * 2)
    this.causarInfo.valorDomingos = this.causarInfo.horasTotales < this.causarInfo.disponibilidadSenama
                                  ? (this.causarInfo.valorBase/30)*2
                                  : this.causarInfo.horasTotales < this.causarInfo.disponibilidadSenama * 2
                                    ? (this.causarInfo.valorBase/30) 
                                    : 0;
    this.sumatoriaTotalServerDirecta();
  }

  sumatoriaTotalServerDirecta(){
    this.causarInfo.total = this.causarInfo.valorSalarioDevengado
    + this.causarInfo.auxilioTransporte
    + this.causarInfo.valorNovedades
    + this.causarInfo.valorBonos
    - this.causarInfo.valorDomingos
    - (this.causarInfo.valorPrestaciones * this.causarInfo.cantidadPrestaciones)
    - this.causarInfo.valorCostoTransferencia;
    // +((this.causarInfo.valorBase/30) *  this.causarInfo.diasCalculados )
    // + this.causarInfo.valorDiasNoTrabajados
    ;
    console.log(`dias trabajados ${this.causarInfo.diasTrabajados} \n diasCalculados ${ this.causarInfo.diasCalculados}`);
    console.log(`Por hora se paga: ${ this.causarInfo.valorBaseHora} -- trabajo: ${this.causarInfo.horasTotales} -- ganancia  ${this.causarInfo.valorHorasTrabajadas}`)
    console.log(`Valor horas extras ${this.causarInfo.valorHorasExtraTrabajadas}`)
    console.log(`Saliro devengado = horas + horas extra: ${this.causarInfo.valorSalarioDevengado}`)
    console.log(`valor perdido Domingos ${this.causarInfo.valorDomingos}`)
    console.log("Auxilio transporte",this.causarInfo.auxilioTransporte);
    console.log("Valor novedades",this.causarInfo.valorNovedades);
    console.log("Bonos",this.causarInfo.valorBonos);
    // console.log("valor Solo Dias trabajados",this.causarInfo.valorSalarioDevengado)
    // console.log("Total con valor novedades",(this.causarInfo.valorBase/30) *  this.causarInfo.diasCalculados + this.causarInfo.valorNovedades + (environment.transport_allowance/30)* this.causarInfo.diasCalculados)
    // console.log("Total sin el valor novedades",(this.causarInfo.valorBase/30) * diasTrabajados + this.causarInfo.auxilioTransporte)
    console.log("SALUD Y PENCION",this.causarInfo.valorPrestaciones, "Total",this.causarInfo.valorPrestaciones*this.causarInfo.cantidadPrestaciones);
    console.log("Costo transferencia",this.causarInfo.valorCostoTransferencia);
    this.loaderModalCausar = false;
    this.loaderGeneral = true;
  }

  validateHolyDay(){
    return new Promise(resolve=>{
      let date = this.fechaInicioQuincenal;
      let fechasfestivas = []
      for(let i = 0;date != this.fechaFinalQuincenal;i++){
        date = moment(this.fechaInicioQuincenal).add(i,'days').format('YYYY-MM-DD');
        if(this.festivosColombia.some(fecha=> fecha.fecha == date )) fechasfestivas.push(date);
      }
      resolve(fechasfestivas)
    });
  }

  onOpenNewPaymentConcept(index_service){
    this.adding_new_payment_concept = true; 
    this.index_service_selected = index_service
  }

  onAddPaymentConcept(i){
    const a = {...this.new_payment_concept}
    this.causarInfo.servicios[i].payment_concepts_list.push(a)
    this.adding_new_payment_concept = false;
    this.new_payment_concept = {description: '', assignments: 0,deduction: 0};
    this.onCalculateSubTotals(i);
  }

  onCancelAddPaymentConcept(){
    this.new_payment_concept = {description: '', assignments: 0,deduction: 0};
    this.adding_new_payment_concept = false;
  }

  onOpenEditPaymentConcept(index_service,index_payment_concepts){
    if(!this.editing_payment_concept){
      this.editing_payment_concept = true;
      this.index_payment_concept_selected = index_payment_concepts
      this.index_service_selected = index_service
    }
  }

  onEditPaymentConcept(index_service){
    this.editing_payment_concept = false;
    this.index_payment_concept_selected = -1;
    this.onCalculateSubTotals(index_service);
  }

  onValidatedEditPaymentConcept(index_service,index_payment_concepts){
    if(this.editing_payment_concept && 
      this.index_payment_concept_selected == index_payment_concepts &&
      this.index_service_selected == index_service
    )
      return true;
    else return false;
  }

  /////////////////////
  onOpenNewGeneralPaymentConcept(){
    this.new_payment_concept.date = '';
    this.adding_new_general_payment_concept = true;
  }
  onAddGeneralPaymentConcept(i){
    if(this.new_payment_concept.assignments > 0 || this.new_payment_concept.deduction > 0){
      const a = {...this.new_payment_concept}
      this.causarInfo.general_payment_concepts_list.push(a)
      this.adding_new_general_payment_concept = false;
      this.new_payment_concept = {description: '', assignments: 0,deduction: 0,};
      this.calculateTotals();
    }
  }
  onCancelAddGeneralPaymentConcept(){
    this.new_payment_concept = {description: '', assignments: 0,deduction: 0,};
    this.adding_new_general_payment_concept = false;
  }
  onOpenEditGeneralPaymentConcept(index_general_payment_concepts){
    if(!this.editing_general_payment_concept){
      this.editing_general_payment_concept = true;
      this.index_general_payment_concept_selected = index_general_payment_concepts
    }
  }
  onEditGeneralPaymentConcept(index_service){
    this.editing_general_payment_concept = false;
    this.index_general_payment_concept_selected = -1;
    this.calculateTotals();
  }
  onCalculateSubTotals(index_service){
    this.causarInfo.servicios[index_service].subTotal = 0;
    for(let i = 0;i < this.causarInfo.servicios[index_service].payment_concepts_list.length;i++ ){
      const sum = this.causarInfo.servicios[index_service].payment_concepts_list[i].assignments -  this.causarInfo.servicios[index_service].payment_concepts_list[i].deduction
      this.causarInfo.servicios[index_service].subTotal += sum
    }
    this.calculateTotals();
  }
  

  eliminarNovedad(i){
    Swal.fire({
      title: "Estas seguro(a)?",
      text: "Se eliminara la novedad del sistema",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.value) {
        this.metricasService.deleteNovelty(this.novedadesCausasdas[i].id).then(() => {
          if(this.novedadesCausasdas[i].intencion == 'resta') this.causarInfo.valorNovedades += this.novedadesCausasdas[i].costo;
          else this.causarInfo.valorNovedades -= this.novedadesCausasdas[i].costo;
          this.novedadesCausasdas.splice(i,1);
          if(this.causarInfo.tipoContrato == "directo") this.calcularValorFinalServerDirecta();
          else this.actualizarValorNovedad();
          Swal.fire("Listo", "la novedad fue eliminada", "success");
        }).catch(() => {
          Swal.fire("Error!","No se pudo eliminar la novedad","error");
        });
      }
    });
  }
  actualizarValorNovedad(){
    this.causarInfo.valorNovedades = 0;
    this.novedadesCausasdas.map(novedad=>{
      if(novedad.tipo == "No uso la app" || novedad.tipo == "Uniforme" || novedad.tipo == "Cancelación de servicio" ||  novedad.tipo == "Cancela Server" ||
        novedad.tipo == "Garantía" || novedad.tipo == "Calidad" || novedad.tipo == "Puntualidad" || novedad.tipo == 'Servicio sin seguridad social'){
        this.causarInfo.valorNovedades -= Math.trunc(novedad.costo);
      }else{
        this.causarInfo.valorNovedades += Math.trunc(novedad.costo);
      }
    })
  }

  async causarDirecta(){
    console.info(this.causarInfo);
    const server = (await this.ServerService.detail(this.causarInfo.id)).data();
    let infoParaPago = {
      id: new Date().getTime(),
      estadoComprobante:'causado',
      sever: server,
      email: server.email,
      servicios: await this.validarInfoServiciosACausar(this.sourceModalCausar,'directa'),
      idServer: server.id,
      numNovedades: this.numNovedades,
      totalServicios: this.causarInfo.servicios.length,
      horasTotales: this.causarInfo.horasTotales,                 
      horasDisponible: this.causarInfo.horasDisponible,
      tipoPago: "Por contrato", 
      costoDeTransferencia: this.causarInfo.costoDeTransferencia,
      comprobanteDePago: await this.generatePdfDirecta(),

      fechaInicio: this.fechaInicioQuincenal,
      fechaFin: this.fechaFinalQuincenal,
      pagoNetoServer: this.causarInfo.total,
      diasTrabajados: this.causarInfo.diasTrabajados,
      auxilioTransporte: this.causarInfo.valorAuxilioDetransporte,
      novedades: this.novedadesCausasdas,
      bonos: this.causarInfo.bonos,
      valorBase: this.causarInfo.valorBase/30 *  this.causarInfo.diasCalculados,
      vaslorHorasTrabajadas: this.causarInfo.valorHorasTrabajadas, // nueva
      valorNovedades: this.causarInfo.valorNovedades,
      valorBonos: this.causarInfo.valorBonos,
      cantidadPrestaciones:  this.causarInfo.cantidadPrestaciones,
      valorPrestaciones: this.causarInfo.valorPrestaciones,
      valorCostoTransferencia: this.causarInfo.valorCostoTransferencia,


    // this.causarInfo.valorHorasTrabajadas
    //   - this.causarInfo.valorDomingos
    //   + this.causarInfo.valorHorasExtraTrabajadas
    // //((this.causarInfo.valorBase/30) *  this.causarInfo.diasCalculados )
    //   + ((environment.transport_allowance/30) *  this.causarInfo.diasCalculados)
    //   // + this.causarInfo.valorDiasNoTrabajados
    //   + this.causarInfo.valorNovedades
    //   + this.causarInfo.valorBonos
    //   - (this.causarInfo.valorPrestaciones * this.causarInfo.cantidadPrestaciones)
    //   - this.causarInfo.valorCostoTransferencia
    }
    this.servBackendService.post('api/sendgrid/send-payment-server',infoParaPago).subscribe(res =>{})

    this.pagosServerService.post(infoParaPago).then(async res => {
      console.log("Cobro subido")
      const confirmacionServicios = await this.actualizarCausarServicios(this.causarInfo.servicios, server, infoParaPago.id);
      console.log(confirmacionServicios)
      const confirmacionNovedades = await this.concluirNovedades(infoParaPago.id);
      console.log(confirmacionNovedades)
      this.loaderModalCausar = false;
      this.loaderGeneral = false;
      this.actualizarTablas(infoParaPago)
      Swal.fire("Listo","Se a causado los servicios y novedades correctamente","success")
      const closeButton = document.getElementById('closeModalButton') as HTMLButtonElement
      closeButton.click();
    })
  }

  actualizarTablas(datosCausados){
    if(this.tipoContratoConsulta == 'directo'){
      const info = this.sourceQuincenales.filter(res=>res.id != datosCausados.idServer)
      this.sourceQuincenales = info
      this.sourceCausadoDirectas.push({...datosCausados})
    }else{
      const info = this.source.filter(res=>res.id != datosCausados.idServer)
      this.source = info
      this.sourceCausadoPorServicio.push({...datosCausados})
    }
    this.changeTable(this.tablaVisualizada)
  }

  async causar(){
    // this.loaderModalCausar = true;
    this.mdlCausarTabset.selectedIndex = 1;

    if(moment().format('YYYY-MM-DD') <= this.miercoles){
      Swal.fire('Espera','La semana que intentaste cusar aun no a acabdo','warning')
    }else{

      const server = (await this.ServerService.detail(this.causarInfo.id)).data();
      let infoParaPago = {
        id: new Date().getTime(),
        estadoComprobante:'causado',
        sever: server,
        email: server.email,
        servicios: await this.validarInfoServiciosACausar(this.causarInfo.servicios,'servicios'),
        idServer: server.id,
        fechaFin: this.miercoles,
        fechaInicio: this.jueves,
        saldoCobrado: 0,
        numNovedades: this.numNovedades,
        gananciaBase: this.causarInfo.gananciaBase,
        totalSoloServicios: this.causarInfo.totalServices,
        pagoNetoServer: this.causarInfo.total,
        valorNovedades: this.causarInfo.valorNovedades,
        // valorNovedadesApeladas: this.causarInfo.valorNovedadesApeladas,
        // valorNovedadesAdicionales: this.causarInfo.valorNovedadesAdicionales,
        totalServicios: this.causarInfo.servicios.length,
        gananciaNetaServ: this.causarInfo.gananciasServ,
        horasTotales:    this.causarInfo.horasTotales,
        recargoServicios: this.causarInfo.recargoGeneral,
        recargoTransporteServicios: this.causarInfo.recargoTransporte,
        observacionesPago: this.observacionesCausarPago, 
        tipoPago: "Por servicio", 
        gananciaBonoSeguridadSocial: this.causarInfo.gananciaBonoSeguridadSocial,
        gananciaBonoPuntualidad_App: this.causarInfo.gananciaBonoPuntualidad_App,
        costoDeTransferencia: this.causarInfo.general_payment_concepts_list[0].deduction,
        descuentoSeguridadSocial: this.causarInfo.tipoManejoSeguridadSoscial == 'pagoCuotas' ? this.causarInfo.general_payment_concepts_list[2].deduction : 0,
        descuentoUniforme: this.causarInfo.general_payment_concepts_list[1].deduction,
        conseptosAdicionaes: this.causarInfo.general_payment_concepts_list,
        comprobanteDePago: await this.generatePdf(),
      }
      console.log('Datos a causar',infoParaPago)
      this.servBackendService.post('api/sendgrid/send-payment-server',infoParaPago).subscribe(res =>{
        console.log('Res api/sendgrid/send-payment-server',res);
      })
    
      this.pagosServerService.post(infoParaPago).then(async res => {
        console.log("Cobro subido")
        const confirmacionServicios = await this.actualizarCausarServicios(this.causarInfo.servicios, server, infoParaPago.id);
        console.log(confirmacionServicios)
        const confirmacionNovedades = await this.concluirNovedades(infoParaPago.id);
        console.log(confirmacionNovedades)

        if(moment().diff( moment(this.causarInfo.fechaIngreso).format('YYYY-MM-DD'),'month') >= 1){
          this.ServerService.deleteBalanceBonos(this.causarInfo.id)
        }
        this.actualizarTablas(infoParaPago);
        Swal.fire("Listo","Se a causado los servicios y novedades correctamente","success")
        // this.loaderModalCausar = false;
        const closeButton = document.getElementById('closeModalButton') as HTMLButtonElement
        closeButton.click();
      });

    }
  }
  
  public actualizarCausarServicios(servicios,server,idPago) {
    return new Promise(success => {
      servicios.forEach(async (element, index) => {
        const info_for_update = {id: element.id, estadoPagoServer: 'causado', pagoAsociado: idPago };
        this.servicioService.actualizarServicio(info_for_update).then((res: any) => {});
        server.balance -= element.gananciaServer
        if(index == servicios.length-1){
          this.ServerService.updateServ(server)
        }
      });
      success('Listo, servicios pasaon a causado');
    });
  }

  validarInfoServiciosACausar(servicios,type){
    return new Promise(resolve=>{
      const info = servicios.map(res=>{
        return  {
          id: res.id,
          base:  type == 'servicios' ? res.base: 0,
          fecha: res.fecha, 
          cliente: `${res.client.name} ${res.client.apellido}`,
          horasDeServicio: res.horasDeServicio,
          bonoPuntualidad_App: type == 'servicios' ? res.payment_concepts_list[1].assignments : 0,
          bonoSeguridadSocial: type == 'servicios' ? res.payment_concepts_list[2].assignments: 0,
          recargo: res.recargo,
          recargoTransporte: res.recargoTransporte ? res.recargoTransporte : 0,
          gananciaServer: type == 'servicios' ? (res.base + (res.bonoPuntualidad_App ? 5000 : 0 ) + (res.bonoSeguridadSocial ? 5000 : 0) + res.recargoTransporte + res.recargo) : 0,
        }
      })
      resolve(info)
    })
  }

  concluirNovedades(idPagoServer){
    return new Promise(success => {
      if(this.novedadesCausasdas.length == 0) return success('Listo, novedades concluidas')
      this.novedadesCausasdas.forEach((novedad,index)=>{
        const info: any = {
          id: novedad.id,
          estadoGeneral: 'concluida',
          idPagoServer: novedad.idPagoServer == undefined ? idPagoServer : novedad.novedad.idPagoServer
        }
        if(novedad.estado == 'Apelada' && novedad.estadoApelacion == 'aceptada'){
          info.idPagoServerApelacion = idPagoServer;
        }
        this.metricasService.updateNovedad(info)
        if(index == this.novedadesCausasdas.length-1){
          success('Listo, novedades concluidas')
        }
      })
    })
  }

  actualizarNovedad(novedad){
    this.causarInfo.valorNovedades 
    this.sourceModalCausar.map(element => {
      this.causarInfo.valorNovedades = 0
      if(element.novedad){
        element.novedades.forEach(data => {
          if(data.tipo == "No uso la app" || data.tipo == "Uniforme" || data.tipo == "Cancelación de servicio" || data.tipo == "Garantía" || data.tipo == "Calidad" || data.tipo == "Puntualidad"){
            this.causarInfo.valorNovedades -= data.costo
          }else{
            this.causarInfo.valorNovedades += data.costo
          }
        });
      }
    });
    this.metricasService.updateNovedad(novedad);
  }

  generatePdf(){
    return new Promise (resolve=>{
      const elements = document.querySelectorAll('.button-primary-small');
      elements.forEach((element: HTMLElement) => {
        element.style.display = 'none';
        // this.renderer.setStyle(element, 'display', 'none');
      });
  
      let table = document.getElementById('table-payment')
      console.log(table.classList);
      table.classList.add('table-for-pdf')
  
      let pdfContent = this.pdfContent.nativeElement;
      let pdfContentCopy = pdfContent.cloneNode(true);
  
      pdfContentCopy.style.width = '800px'
      pdfContentCopy.style.height = '1600px'
      pdfContentCopy.style.padding = '10px 80px'
      pdfContentCopy.style.fontSize = '8px'
      
      let pdf = new jsPDF('p','px',[ 800,1600]);
      pdf.html(pdfContentCopy,{
        callback: (doc)=>{
          doc.save("Comprobante_pago.pdf")
          doc.output()
          const pdfBase64 = new Blob([doc.output('blob')], { type: 'application/pdf' });
          const fileName = `serversPayments/${this.causarInfo.id}/${moment().format('YYYY-MM-DD')}`
          this.servicioService.putFile(fileName, pdfBase64).then(async (value) => {
            if (value.state == "success") {
              const url = (await value.ref.getDownloadURL())
              return resolve(url)
            }
          }).catch(err=>console.error('Errro al cargar el archivo',err))
        }
      })
  
      elements.forEach((element: HTMLElement) => {
        this.renderer.setStyle(element, 'display', 'block');
      });
      table.classList.remove('table-for-pdf')
    })
  }

  descartarServicio(i){
    // this.sourceModalCausar.splice(i,1)
    const servicio = this.sourceModalCausar[i];
    this.causarInfo.servicios.splice(i,1)
    const a = (servicio.gananciaServer) - this.causarInfo.descuetnoPeriodoPrueba;
    const base = (servicio.gananciaServer - 10000) - this.causarInfo.descuetnoPeriodoPrueba;
    this.causarInfo.pagoTotal    -= a;
    this.causarInfo.gananciaBase -= base;
    if(this.causarInfo.gananciaBonoSeguridadSocial > 0){
      this.causarInfo.gananciaBonoSeguridadSocial -= 5000;   
    }
    if(this.causarInfo.gananciaBonoPuntualidad_App > 0){
      this.causarInfo.gananciaBonoPuntualidad_App -= 5000;
    }
    this.causarInfo.horasTotales   -= parseInt(servicio.horasDeServicio);
    this.causarInfo.gananciasServ  -= servicio.gananciaServ;
    this.causarInfo.recargoGeneral -= servicio.recargo;
    this.causarInfo.recargoTransporte -= servicio.recargoTransporte;
  }

  async exportExcelPagados() {
    this.loaderGeneral = true;
    let exportExcel = [];
    this.sourcePagados.forEach(info => {
      let tipoDocumento = "CC";
      if (info.sever.tipoDocumento == 'Pasaporte') {
        tipoDocumento = 'PA';
      } else if (info.sever.tipoDocumento == 'Cédula extranjera' || info.sever.tipoDocumento == 'PEP' || info.sever.tipoDocumento == 'PPT') {
        tipoDocumento = 'CE';
      }
      exportExcel.push({
        name: info.sever.name,
        telefono: info.sever.telefono,
        email: info.sever.email,
        balance: info.sever.balance,
        servicio: info.totalServicios,
        numDocumento: info.sever.cedula,
        tipoDocumento: tipoDocumento, // Corrección: asignar tipoDocumento en lugar de info.sever.typoDocumento
        gananciaBase: info.gananciaBase,
        horasTotales: info.horasTotales,
        gananciaBonoSeguridadSocial: info.gananciaBonoSeguridadSocial,
        gananciaBonoPuntualidad_App: info.gananciaBonoPuntualidad_App,
        fechaFin: info.fechaFin,
        fechaInicio: info.fechaInicio,
        pagoServer: info.pagoNetoServer,
        gananciaServ: info.gananciaNetaServ,
        recargos: info.recargoServicios,
        recargoTransporte: info.recargoTransporteServicios,
        descripcionRecargo: info.observacionesPago,
        numNovedes: info.numNovedades,
        valorNovedades: info.valorNovedades,
        valorNovedadesAdicionales: info.valorNovedadesAdicionales, 
        valorNovedadesApeladas: info.valorNovedadesApeladas,
        seguridadSocial: info.sever.estadoSeguridadSocial != undefined ? info.sever.estadoSeguridadSocial : 'sin planilla',
        fechaVencimientoSeguridadSocial: info.sever.fechaVencimientoSeguridadSocial,
        titularCuenta: info.sever.titularCuenta,
        tipoDocuemtoTitular: tipoDocumento,
        documentoTitular: info.sever.numeroTitular,
        tipoCuentaBancaria: info.sever.tipoCuentaBancaria,
        numeroCuenta: info.sever.numeroCuenta,
        emailTpaga: info.sever.correoTpaga != undefined ? info.sever.correoTpaga : 'no tiene',
        celularTpaga: info.sever.celularTpaga != undefined ? info.sever.celularTpaga : 'no tiene',
        cuentaAlterna: info.sever.tipoCuentaBancariaAlternativa != undefined ? info.sever.tipoCuentaBancariaAlternativa : 'no tiene',
        numeroCuentaAlternativa: info.sever.numeroCuentaAlternativa != undefined ? info.sever.numeroCuentaAlternativa : 'no tiene',
        titularCuentaAlternativa: info.sever.titularCuentaAlternativa != undefined ? info.sever.titularCuentaAlternativa : 'no tiene',
        tipoDocumentoAlternativa: info.sever.tipoDocumentoAlternativa != undefined ? info.sever.tipoDocumentoAlternativa : 'no tiene',
        numeroTitularAlternativa: info.sever.numeroTitularAlternativa != undefined ? info.sever.numeroTitularAlternativa : 'no tiene',
      });
    });

    this.pagosServerService.exportAsExcelFile(exportExcel, 'Pagados');
    this.loaderGeneral = false;
  }

  exportCausados(){
    if(this.tipoContratoConsulta == 'directo') this.exportExcelCausadosDirectas()
    else this.exportExcelCausados()
  }

  async exportExcelCausados(){
    this.loaderGeneral = true;
    let sheets = [
      {name: "Por servicios",data:[]}
    ]
    for(let i = 0;i < this.sourceCausadoPorServicio.length; i++){
      const info = this.sourceCausadoPorServicio[i];
      const server: any= (await this.ServerService.detail(info.idServer)).data();
      if(!server) continue;
      let tipoDocumento = server.tipoDocumento;
      let tipoDocumentoAlternativa = server.tipoDocumentoAlternativa;
  
      if (tipoDocumento === 'Cédula de ciudadania') {
        tipoDocumento = 'CC';
      }    
      if (tipoDocumentoAlternativa === 'Cédula de ciudadania') {
        tipoDocumentoAlternativa = 'CC';
      }

      if (server.tipoCuentaBancaria === 'TPaga') {
        if (tipoDocumento === 'Pasaporte') {
          tipoDocumento = 'PA';
        } else if (tipoDocumento === 'Cédula extranjera' || tipoDocumento === 'PEP' || tipoDocumento === 'PPT') {
          tipoDocumento = 'CE';
        }
      }

      if (server.tipoCuentaBancariaAlternativa === 'TPaga') {
        if (tipoDocumentoAlternativa === 'Pasaporte') {
          tipoDocumentoAlternativa = 'PA';
        } else if (tipoDocumentoAlternativa === 'Cédula extranjera' || tipoDocumentoAlternativa === 'PEP' || tipoDocumentoAlternativa === 'PPT') {
          tipoDocumentoAlternativa = 'CE';
        }
      }

      let fecha_ingreso = this.datepipe.transform(server.fecha * 1000, 'yyyy-MM-dd');
      let diasTotales =  moment().diff(fecha_ingreso,"days")
      if(diasTotales < 0){
        fecha_ingreso = moment(server.fecha).format('YYYY-MM-DD');
        diasTotales =  moment().diff(fecha_ingreso,"days")
      }

      let infoExport: any = {
        name: server.name +' '+ server.apellido,
        ciudad: server.cities ? server.cities : '',
        'Fecha de ingreso': fecha_ingreso,
        'Estado': server.estado,
        'Experiencia': diasTotales < 90 ? 'Nueva' : 'Antigua',
        "# Servicios": info.totalServicios,
        "Descuento segurida social": info.descuentoSeguridadSocial ? info.descuentoSeguridadSocial : 0,
        "Descuento uniformes": info.descuentoUniforme ? info.descuentoUniforme : 0,
        'Acumulado bono Seguridad Social': info.acumuladoBonoSeguridadSocial ?  info.acumuladoBonoSeguridadSocial  : 'No aplica',
        'Acumulado bono puntualidad': info.acumuladoBonoPuntualidad_App ?  info.acumuladoBonoPuntualidad_App : 'No aplica',

        "Pago server":   info.pagoNetoServer,

        //Financieros Principal
        "Tipo de cuenta": server.tipoCuentaBancaria,
        "Numero de cuenta": server.numeroCuenta,
        correoTpaga: server.tipoCuentaBancaria == 'TPaga' ? server.correoTpaga : 'No aplica',
        titularCuenta: server.titularCuenta,
        tipoDocuemtoTitular: server.tipoDocumento,
        numeroDocumentoTitular: server.numeroTitular,

        //Financieros alternativa
        tipoCuentaBancariaAlternativa: server.tipoCuentaBancariaAlternativa,
        numeroCuentaAlternativa: server.numeroCuentaAlternativa,
        correoTpagaAlternativa: server.tipoCuentaBancariaAlternativa == 'TPaga' ? server.correoTpagaAlternativa : 'No aplica',
        titularCuentaAlternativa: server.titularCuentaAlternativa,
        tipoDocumentoAlternativa: server.tipoDocumentoAlternativa,
        numeroTitularAlternativa: server.numeroTitularAlternativa,

        gananciaBase: info.gananciaBase,
        horasTotales: info.horasTotales,
        numNovedes:   info.numNovedades,
        valorNovedades: info.valorNovedades,
        gananciaBonoSeguridadSocial: info.gananciaBonoSeguridadSocial,
        gananciaBonoPuntualidad_App: info.gananciaBonoPuntualidad_App,
        recargos: info.recargoServicios,
        recargoTransporte:    info.recargoTransporteServicios,
        costoDeTransferencia: info.costoDeTransferencia,
        // Datos adicionales
        telefono: info.sever.telefono,
        email: info.sever.email,
        numDocumento: info.sever.cedula,
        tipoDocumentoDeRegistro: info.sever.typoDocumento,
        seguridadSocial: info.sever.estadoSeguridadSocial != undefined ? info.sever.estadoSeguridadSocial : 'sin planilla',
        fechaVencimientoSeguridadSocial: info.sever.fechaVencimientoSeguridadSocial,
        fechaFin:     info.fechaFin,
        fechaInicio:  info.fechaInicio,
        gananciaServ: info.gananciaNetaServ,
        // balance: info.sever.balance,
      }
      sheets[0].data.push(infoExport); 
    }
    this.pagosServerService.exportAsExcelFile(sheets, 'Causados')
    this.loaderGeneral = false;
  }
  async exportExcelCausadosDirectas(){
    this.loaderGeneral = true;
    let sheets = [
      {name: "Directas",data:[]}
    ]

    const query = await this.pagosServerService.getPagosServersDirectas(this.fechaInicioQuincenal,this.fechaFinalQuincenal)

    for(let i = 0;i < query.docs.length; i++){
      const info = query.docs[i].data();
      const server: any= (await this.ServerService.detail(info.idServer)).data();
      if(!server) continue;

      let tipoDocumento = server.tipoDocumento;
      let tipoDocumentoAlternativa = server.tipoDocumentoAlternativa;
  
      if (tipoDocumento === 'Cédula de ciudadania') {
        tipoDocumento = 'CC';
      } 
      if (tipoDocumentoAlternativa === 'Cédula de ciudadania') {
        tipoDocumentoAlternativa = 'CC';
      }
      if (server.tipoCuentaBancaria === 'TPaga') {
        if (tipoDocumento === 'Pasaporte') {
          tipoDocumento = 'PA';
        } else if (tipoDocumento === 'Cédula extranjera' || tipoDocumento === 'PEP' || tipoDocumento === 'PPT') {
          tipoDocumento = 'CE';
        }
      }
      if (server.tipoCuentaBancariaAlternativa === 'TPaga') {
        if (tipoDocumentoAlternativa === 'Pasaporte') {
          tipoDocumentoAlternativa = 'PA';
        } else if (tipoDocumentoAlternativa === 'Cédula extranjera' || tipoDocumentoAlternativa === 'PEP' || tipoDocumentoAlternativa === 'PPT') {
          tipoDocumentoAlternativa = 'CE';
        }
      }
      
      let fecha_ingreso = this.datepipe.transform(server.fecha * 1000, 'yyyy-MM-dd');
      let diasTotales =  moment().diff(fecha_ingreso,"days")
      if(diasTotales < 0){
        fecha_ingreso = moment(server.fecha).format('YYYY-MM-DD');
        diasTotales =  moment().diff(fecha_ingreso,"days")
      }
      let infoExport: any = {
        name: server.name +' '+ server.apellido,
        ciudad: server.cities ? server.cities : '',
        'Fecha de ingreso': fecha_ingreso,
        'Estado': server.estado,
        'Inicio de contrato': server.fechaInicioContrato ? server.fechaInicioContrato : 'Falta',
        'Contrato': server.tipoContrato != undefined ? server.tipoContrato : "Falta",
        'Horaio': server.diponibilidadHoraria != undefined ? server.diponibilidadHoraria : "Falta",
        'Experiencia': diasTotales < 90 ? 'Nueva' : 'Antigua',
        "Pago server": info.pagoNetoServer,
        'Periodo pago': `${info.fechaInicio} - ${info.fechaFin}`,
        
        "# Servicios": info.totalServicios ? info.totalServicios : '',
        '$ Servicios': info.totalServices ? info.totalServices : '',
        'horas trabajadas': info.horasTotales ? info.horasTotales : '',
        'horas a completar': info.horasDisponible ? info.horasDisponible : '',
        '# Novedades': info.numNovedades ? info.numNovedades : '',
        '$ Novedades': info.valorNovedades ? info.valorNovedades : '',

        // '# Nov. transporte': info.novedades ? info.novedades.trasporte.cantidad ? info.novedades.trasporte.cantidad : '' : '',
        // '$ Nov. transporte': info.novedades ? info.novedades.trasporte.valor ? info.novedades.trasporte.valor : '' : '',
        // '# Nov. No trabajo': info.novedades ? info.novedades.diaNoTrabajado.cantidad ? info.novedades.diaNoTrabajado.cantidad : '' : '',
        // '$ Nov. No trabajo': info.novedades ? info.novedades.diaNoTrabajado.valor ? info.novedades.diaNoTrabajado.valor : '' : '',

        recargos: info.recargoServicios,
        costoDeTransferencia: info.costoDeTransferencia,
        recargoTransporte:    info.recargoTransporteServicios,
        // Datos adicionales
        telefono: info.sever.telefono,
        email: info.sever.email,
        numDocumento: info.sever.cedula,
        tipoDocumentoDeRegistro: info.sever.typoDocumento,
        seguridadSocial: info.sever.estadoSeguridadSocial != undefined ? info.sever.estadoSeguridadSocial : 'sin planilla',
        fechaVencimientoSeguridadSocial: info.sever.fechaVencimientoSeguridadSocial,
        gananciaServ: info.gananciaNetaServ,

        //Financieros Principal
        "Tipo de cuenta": server.tipoCuentaBancaria,
        "Numero de cuenta": server.numeroCuenta,
        correoTpaga: server.tipoCuentaBancaria == 'TPaga' ? server.correoTpaga : 'No aplica',
        titularCuenta: server.titularCuenta,
        tipoDocuemtoTitular: server.tipoDocumento,
        numeroDocumentoTitular: server.numeroTitular,

        //Financieros alternativa
        tipoCuentaBancariaAlternativa: server.tipoCuentaBancariaAlternativa,
        numeroCuentaAlternativa: server.numeroCuentaAlternativa,
        correoTpagaAlternativa: server.tipoCuentaBancariaAlternativa == 'TPaga' ? server.correoTpagaAlternativa : 'No aplica',
        titularCuentaAlternativa: server.titularCuentaAlternativa,
        tipoDocumentoAlternativa: server.tipoDocumentoAlternativa,
        numeroTitularAlternativa: server.numeroTitularAlternativa,
        // balance: info.sever.balance,
      }

      sheets[0].data.push(infoExport);
    }
    this.pagosServerService.exportAsExcelFile(sheets, 'Causados')
    this.loaderGeneral = false;
  }
  async generatePdfDirecta(){
    return new Promise (resolve=>{
      try{
        console.info(this.sourceModalCausar)
        pdfFonts.pdfMake.vfs["AirbnbCereal_W_Bd"] = AirbnbCereal_W_Bd;
        pdfFonts.pdfMake.vfs["airbnb_b64"] = airbnb;
        pdfFonts.pdfMake.vfs["jazmin_alt"] = JazminAltBoldEncoded;
        pdfMake.fonts = {
          airbnb_b64: {
            normal: "airbnb_b64",
            bold: "AirbnbCereal_W_Bd",
            italics: "airbnb_b64",
            bolditalics: "airbnb_b64",
          },
          jazmin_alt: {
            normal: 'jazmin_alt',
            bold: "jazmin_alt",
            italics: "jazmin_alt",
            bolditalics: "jazmin_alt",
          },
        }

        let info_body_table: any = [[ {text: 'Resumen del pago', colSpan: 3, alignment: 'center', style: 'tabla_headers'},{},{} ]];
        info_body_table.push([ {text: 'Fecha', style: 'tabla_headers'},  {text: 'Descripción', style: 'tabla_headers'}, {text: 'Valor', style: 'tabla_headers'}])

        if (this.novedadesCausasdas.length > 0) {
          for (let novelty of this.novedadesCausasdas) {
            if(novelty.tipo == 'No trabajo este dia') {
              info_body_table.push([
                { text: `${novelty.fechaCreacion}`, style: "tabla_items"},
                { text: `${novelty.tipo}`, style: "tabla_items"},
                { text: `${novelty.intencion == 'resta' ? '-' : '+'} ${Math.round(novelty.costo).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, style: "tabla_items"}
              ])
            }
          }
        }
        info_body_table.push([ {},  {text: 'Totalor devengado',	fillColor: '#e7e7e7', style: 'tabla_total'}, {text: `${Math.round(this.causarInfo.valorSalarioDevengado).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, fillColor: '#e7e7e7', style: 'tabla_total'}])
        info_body_table.push([ {},  {text: 'Auxilio transporte',	fillColor: '#e7e7e7', style: 'tabla_total'}, {text: `${Math.round(this.causarInfo.valorAuxilioDetransporte).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`,fillColor: '#e7e7e7', style: 'tabla_total'}])
        
        if (this.novedadesCausasdas.length > 0) {
          info_body_table.push([ {text: 'Novedades', colSpan: 3, alignment: 'left', style: 'tabla_headers'},{},{} ]);
          for (let novelty of this.novedadesCausasdas) {
            if(novelty.tipo != 'No trabajo este dia') {
                info_body_table.push([
                { text: `${novelty.fechaCreacion}`, style: "tabla_items"},
                { text: `${novelty.tipo}`, style: "tabla_items"},
                { text: `${novelty.intencion == 'resta' ? '-' : '+'} ${Math.round(novelty.costo).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, style: "tabla_items"}
              ])
            }
          }
        }
        
        if(this.causarInfo.bonos.length > 0){
          for (let bonus of this.causarInfo.bonos) {
            info_body_table.push([
              { text: `${bonus.fechaCreacion}`, style: "tabla_items"},
              { text: `${bonus.tipo}`, style: "tabla_items"},
              { text: `${bonus.intencion == 'resta' ? '-' : ''} ${Math.round(bonus.costo).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, style: "tabla_items"}
            ]);
          }
        }
        info_body_table.push([ {}, {text: 'Total novedades', fillColor: '#e7e7e7', style: 'tabla_total'}, {text: `${Math.round(this.causarInfo.valorNovedades + this.causarInfo.valorBonos).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, fillColor: '#e7e7e7', style: 'tabla_total'} ]);
        
        info_body_table.push([{text: 'Deducciones', colSpan: 3, style: 'tabla_headers'},{},{} ]);

        info_body_table.push([ {}, { text: `Aportes a salud`, style: "tabla_items"},     { text: `- ${Math.round(this.causarInfo.valorPrestaciones).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, style: "tabla_items"}]);
        info_body_table.push([ {}, { text: `Aportes a pensión`, style: "tabla_items"},   { text: `- ${this.causarInfo.cantidadPrestaciones == 1 ? 0 : Math.round(this.causarInfo.valorPrestaciones).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, style: "tabla_items"}]);
        info_body_table.push([ {}, { text: `Costo transferencia`, style: "tabla_items"}, { text: `- ${Math.round(this.causarInfo.valorCostoTransferencia).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, style: "tabla_items"}]);
        info_body_table.push([ {}, {text: 'Total deducciones',	fillColor: '#e7e7e7', style: 'tabla_total'},
          {
            text: `- ${Math.round(this.causarInfo.valorPrestaciones * this.causarInfo.cantidadPrestaciones + this.causarInfo.valorCostoTransferencia).toLocaleString('es-CO', { style: 'decimal', maximumFractionDigits: 0 })}`, 
            fillColor: '#e7e7e7',
            style: 'tabla_total'
          }
        ])
        info_body_table.push([ {text:'.',color:'#fff'}, '', '']);
      
        info_body_table.push([ {}, { text: `Pago total`,fillColor: '#e7e7e7', style: "tabla_total"},
          { text: `${Math.round(this.causarInfo.total).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}`, 
            fillColor: '#e7e7e7',
            style: "tabla_total"
          }
        ]);

        const documentDefinition: any = {
          pageSize: {
            width: 1416,
            height: 1624,
          },
        
          content: [
            {
              absolutePosition: { x: 32, y: 32 },
              text: [
                { text: 'SERV SAS \n', style:'header'},
                { text: `NIT: 901169432-3 \n`, style:'header'},
              ]
            },
            {
                absolutePosition: { x: 32, y: 124 },
                text: [
                  { text: 'DESPRENDIBLE DE PAGO', style:'main_header'},
                ]
            },
            {
              absolutePosition: { x: 1416/2, y: 224 },
              columns:[
                {
                  width: '50%',
                  columnGap: 1,
                  stack: [
                    { text: `Fecha emicion: `, style:'sub_header_item'},
                    { text: 'Nombre:', style:'sub_header_item'},
                    { text: 'Identificación:', style:'sub_header_item'},
                    { text: `Periodo de pago: `, style:'sub_header_item'},
                    { text: `Base de la quincena: `, style:'sub_header_item'},
                    { text: `Dias trabajados: `, style:'sub_header_item'},
                  ]
                },
                {
                  width: '50%',
                  columnGap: 1,
                  stack: [
                    { text: `${this.today} `, style:'sub_header_item_value'},
                    { text: `${this.causarInfo.name} ${this.causarInfo.apellido}`, style:'sub_header_item_value'},
                    { text: `${this.causarInfo.cedula}`, style:'sub_header_item_value'},
                    { text: `${this.fechaInicioQuincenal} \ ${this.fechaFinalQuincenal}`, style:'sub_header_item_value'},
                    { text: `${environment.basic_salary/2}`, style:'sub_header_item_value'},
                    { text: `${this.causarInfo.diasTrabajados}`, style:'sub_header_item_value'},
                  ]
                },
              ]
                
            },
            // 4 Tabla
            {
              absolutePosition: { x: 50, y: 500  },
              table: {
                widths: ['*', '*', '*'],
                body: info_body_table
              }
            },
          ],
          defaultStyle: {
            font: 'airbnb_b64'
          },
          images: {
            background: "https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fbackground_pdf_profile_server.jpg?alt=media&token=413f1d43-f448-43cd-b8ed-58ae83e403e4",
          },
          styles: {
            ///////////////////////////
            header:{
              alignment: 'center',
              bold: true,
              fontSize: 24,
              characterSpacing: 6,
            },
            main_header:{
              alignment: 'center',
              bold: true,
              fontSize: 30,
              characterSpacing: 6,
            },
            sub_header_item:{
              alignment: 'left',
              bold: true,
              fontSize: 24,
              margin: [0,0,0,10]
            },
            sub_header_item_value:{
              alignment: 'right',
              bold: false,
              fontSize: 22,
              margin: [0,0,0,10]
            },  
            tabla_headers:{
              alignment: 'left',
              bold: true,
              fontSize: 22,
              characterSpacing: 6,
              margin: [10,4,0,4]
            },
            tabla_items:{
              alignment: 'left',
              fontSize: 18,
              margin: [10,4,0,4]
            },
  
            tabla_total:{
              alignment: 'left',
              bold: true,
              fontSize: 18,
              margin: [10,4,0,4]
            },

          },
        };

        console.log(documentDefinition);
        pdfMake.createPdf(documentDefinition);
        pdfMake.createPdf(documentDefinition).open();
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBlob((blob) => {
          const filePath = `serversPayments/${this.causarInfo.id}/${moment().format('YYYY-MM-DD')}.pdf`;
          this.servicioService.putFile(filePath, blob).then(async (value) => {
            if (value.state == "success") {
              const url = (await value.ref.getDownloadURL())
              return resolve(url)
            }
          });
        })
      }catch(error){
        console.error('Ocurrio un eeror al generra el perfil',error)
      }
    })
  }

  async onGenerateExportServers(){
    let dates = {start_date: this.fechaInicioQuincenal, end_date:this.fechaFinalQuincenal};
    console.log(dates);
    this.servBackendService.post('api/serv/get_metrics_direct_server', dates).subscribe(res => {
      console.log('Res api/serv/get_metrics_direct_server', res);
      this.ServerService.exportAsExcelFile(res.data, `indicadores_servers_directas_${this.fechaInicioQuincenal}_${this.fechaFinalQuincenal}`);
    })
  }
}
