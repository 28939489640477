import { Injectable } from "@angular/core";
import { AngularFireAuth } from "angularfire2/auth";
import { HttpModule, Http, Headers } from "@angular/http";
import { AngularFireDatabase } from "angularfire2/database";
import { map, switchMap } from "rxjs/operators";
import * as admin from "firebase-admin";
import * as firebase from "firebase/app";
import { Observable } from "rxjs/Observable";
import { AngularFirestore } from "angularfire2/firestore";
import { User } from "../models/User.model";
import { of } from "rxjs";
import { ServerInterface } from "../models/interface/Server.interface";
import Swal, { SweetAlertType } from "sweetalert2";
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask, } from '@angular/fire/storage'

@Injectable({
  providedIn: "root",
})
export class AuthService {
  firstState = true;
  user$: Observable<User>;
  baseURL: String = "http://serv-admin.herokuapp.com/";
  headers: Headers;
  private fireStoreRef: AngularFireStorageReference;
  public user: any;
  public opcion: any;
  public authToken: any;
  idBuquedaNotificacion: any = "falta";

  constructor(
    private afAuth: AngularFireAuth,
    private afD: AngularFireDatabase,
    private afS: AngularFirestore,
    private http: Http,
    private angularFireStorage: AngularFireStorage,
    private httpClient: HttpClient,
    public httpModule: HttpModule
  ) {


    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          //return this.afS.doc<User>(`users/${user.uid}`).valueChanges();
          return this.afD.list<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
  getAllHttp(): Observable<any> {
    return this.httpClient.get('*')
  }

  loginEmailUser(email, password) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        throw "por favor verifique su correo electrónico y contraseña";
      });
  }

  logoutUser() {
    return this.afAuth.auth.signOut() && this.storeDeleteData();
  }

  async validateNewUser(telefono,documento){
    return new Promise(async resolve=>{
      const query_1 = await firebase.firestore().collection('users').where('telefono','==',telefono).get();
      const query_2 = await firebase.firestore().collection('users').where('documento','==',documento).get();
      console.log(query_1.size,query_2.size)
      if(query_1.size == 0 && query_2.size == 0) return resolve(true);
      else return resolve(false);

    })
  }

  registerUser(datosCliente) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(datosCliente.email, datosCliente.clave)
      .then((userData) => {
        this.guardarUsuario(datosCliente, userData.user);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          type: 'error',
          text: error,
          showConfirmButton: true
        })
      });
  }

  private guardarUsuario(user, account) {
    user.id = account.uid;
    user.clave = null;
    // return this.afD.database.ref("users/" + user.id)
    return firebase.firestore().doc('users/' + user.id).set(user).then((res) => {
      const a = document.getElementById('cerrarModal') as HTMLButtonElement
      a.click()
      Swal.fire("Exito", "El cliente fue creado con exito", "success");
    });
  }


  /* ------------------------UsuariosAdmin------------------------- */
  registrarUsuarioAdmin(datos: User) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(datos.email, datos.clave)
      .then((userData) => {
        this.guardarUsuarioAdmin(datos, userData.user);
      })
      .catch((error) =>
        console.log("Esta credencial ya existe en nuestro sistema!", error)
      );
  }


  private guardarUsuarioAdmin(user, account) {
    user.id = account.uid;
    user.clave = "null";
    return firebase
      .firestore()
      .doc("usersAdmin/" + user.id)
      .set(user);
  }

  public moverUsuarosAdmin(user) {
    return firebase
      .firestore()
      .doc("usersAdmin/" + user.id)
      .set(user);
  }

  public getUsuarioAdmin() {
    return firebase.firestore().collection("usersAdmin").get();
  }
  public getUsuarioAdminUnicaPropiedad(tipo, info) {
    return firebase.firestore().collection("usersAdmin").where(tipo, '==', info)
      .get();
  }

  public getUsuarioAdminId(id) {
    return firebase
      .firestore()
      .collection("usersAdmin")
      .where("id", "==", id)
      .get();
  }

  tokenUsuarios(usuario: any) {
    console.log("usuario antes de guardarlo", usuario);
    return firebase
      .firestore()
      .doc("usersAdmin/" + usuario.id)
      .set(usuario)
      .then((res) => {
        console.log("usuario actualizado con token");
      })
      .catch((err) => {
        console.log("ocurrio un error al actualizar el usuario", err);
      });
  }

  updateUsersAdmin(info){
    return firebase.firestore().doc(`usersAdmin/${info.id}`).update(info)
  }
  /* --------------------------------- */

  public storeGetUserData(property) {
    return JSON.parse(localStorage.getItem(property));
  }

  public storeGetData(property) {
    return localStorage.getItem(property);
  }

  public storeDeleteData() {
    return localStorage.clear();
  }

  public storeSetData(property: string, value: any) {
    localStorage.setItem(property, value);
  }

  public storeUserData(token, user) {
    console.log("usuario qeu guarda en storeSetData", user);
    this.storeSetData("id_token", token);
    this.storeSetData("usuario", JSON.stringify(user));
    this.storeSetData("location", user.idLocation || "4fZC8McSlOvpPfIZnG7w");
    // id de locacion de bogota '4fZC8McSlOvpPfIZnG7w'
    this.authToken = token;
    this.user = user;
  }

  registerServer(server: any,) {
    return this.afAuth.auth.createUserWithEmailAndPassword(server.email, server.clave)
  }

  // guardarServer(server: ServerInterface, account) {
  //   server.id = account.uid;
  //   server.contrasena = server.clave;
  //   server.clave = null;
  //   const userRef = this.afD.database.ref("server/" + server.id);
  //   return userRef.set(server);
  // }
  guardarServer(server: ServerInterface, account) {
    server.id = account.uid;
    server.contrasena = server.clave;
    server.clave = null;
    return firebase.firestore().doc(`servers/${server.id}`).set(Object.assign({}, server))
  }

  // DeleteServerAuth(object: String, data: any) {
  //   return this.http
  //     .post(this.baseURL + "" + object, data, { headers: this.headers })
  //     .pipe(map((res) => res.json()));
  // }
  // deleteServer(id) {
  //   return this.afD.object("server/" + id).remove();
  // }

  public createServerAspirante(server, credentials) {
    let newCredentials = {
      email: server.email,
      password: server.clave,
    };
    console.log("Server para guardar-----", server);

    const userRef = this.afD.database.ref("server/" + server.id);
    return userRef.set(server).then(() => {
      this.eliminarAspirante(server.id);
      this.changeEmailUser(credentials, newCredentials);
    });
  }

  changeEmailUser(credentials, newCredentials) {
    console.log(credentials, newCredentials);
    this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((res) => {
        res.user
          .updateEmail(newCredentials.email)
          .then(() => {
            console.log("EmailCambiado");
          })
          .catch((err) => {
            console.log("ocurrio un error al actualizar el email", err);
          });
        res.user
          .updatePassword(newCredentials.password)
          .then(() => {
            console.log("PaswwordCambiado");
          })
          .catch((err) => {
            console.log("ocurrio un error al actualizar la clave", err);
          });
        console.log(res.user);
      })
      .catch((err) => {
        console.log("ocurrio un error al buscar las credenciales", err);
      });
  }
  eliminarAspirante(id) {
    firebase.firestore().collection("aspirantes").where("idEstuduiante", "==", id).get()
    .then((res) => {
      let estudiante = res.docs[0].data();
      firebase.firestore().doc("aspirantes/" + estudiante.id).delete()
        .then(() => {
          console.log("Aspirante eliminado");
        })
        .catch((err) => {
          console.log("ocurrio un error al borrar el aspirante", err);
        });
    });
  }

  sendPasswordResetEmail(email){
    return this.afAuth.auth.sendPasswordResetEmail(email)
  }

}
