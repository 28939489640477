import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class PlanService {

  public location: string = '';
  public planRef = firebase.firestore().collection('plan');
  public facurasPlan = firebase.firestore().collection('pagosUsuarios');
  public planRecurrenteRef = firebase.firestore().collection('plan-recurrente');

  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
  ) { }

  public getLocation() {
    // id de locacion de bogota '4fZC8McSlOvpPfIZnG7w'
    this.location = localStorage.getItem('location');
  }

  /**
   * Function to update all documents in collection of Cloud Firestore 
   */
  public updatedAll(plan){
    console.log(plan.id)
    firebase.firestore().doc('plan/' + plan.id).update(plan).then(()=>{
      console.log('Plan Actualizado')
    }).catch(err=>{
      console.log('Ocurrio un error en updatedAll()',err);
      })
  }
  setPlan(plan){
    firebase.firestore().doc('plan/' + plan.id).set(plan)
  }

  public async getPlanByFecha() {
    this.getLocation();
    return this.planRef.where('idLocation', '==', this.location).get();
  }
  public getAllPlan(){
    return this.planRef.get()
  }


  public getAllPlanesClientes(){
    let planes = [];
      this.planRef
     .get().then(resp=>{
      resp.docs.forEach(data =>{
        planes.push(data.data())
        if(planes.length === resp.docs.length){
          console.log(planes)
          this.planes(planes)
        }
      })
    })
  }
  planes(planes){
    return planes
  }
  getParaReagendar(){
    return this.planRef.where("estado",'==','Por vencer').get()
  }
  getPlanById(){
    return this.planRef.where("id",'==','fptti7KVGYwzBCE6u5U6').get()
  }
  public getPlanByServer(idServer) {
    this.getLocation();
    return this.planRef
      .where('idLocation', '==', this.location)
      .where('idServers', '==', idServer)
      .get();
  } 
  public getPlanesByServerId(idServer){
    return this.planRef.where('idServers', '==', idServer).get();
  }

  public getPlanbyId(id) {
    return firebase.firestore().doc('plan/' + id).get()
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getPlanRangoFecha(startDate, endDate){
    return  this.planRef
    .where('fechaCreacion', '>=', startDate)
    .where('fechaCreacion', '<=', endDate)
    .get()
  }
  getPlanEstado(){
    return  this.planRef
    .where('estado', '==', "Activo")
    .get()
  }

  getPlanesConPosventaFecha(fchInicio,fchFin){
    return this.planRef
    .where("fechaPostventa",">=", fchInicio)
    .where("fechaPostventa","<=", fchFin)
    .where("estadoPostventa","==", "postventa pendiente")
    .get()
  }
  getPlanesPosventa(){
    return this.planRef
    .where("estadoPostventa","==", "postventa pendiente")
    .get()
  }
  getPlanesConPosventaAsignadaFecha(fchInicio,fchFin,responsable){
    return this.planRef
    .where("fechaPostventa",">=", fchInicio)
    .where("fechaPostventa","<=", fchFin)
    .where('responsablePostventa','==',responsable)
    .where("estadoPostventa","==", "postventa pendiente")
    .get()
  }
  getPlanesConPosventaAsignada(responsable){
    return this.planRef
    .where('responsablePostventa','==',responsable)
    .where("estadoPostventa","==", "postventa pendiente")
    .get()
  }

  public actualizarEstadoPostventa(idPlan,info){
    return firebase.firestore().doc(`plan/${idPlan}`).update({ estadoPostventa: info });
  }

  public eliminarPlan(plan) {
    console.log(plan.id);
    return this.planRef.doc(plan.id).delete()
  }

  public eliminarPlanRecurrente(id) {
    return this.planRecurrenteRef.doc(id).delete()
  }

  public getProximasFacturasPlan(idPlan){
    let fecha = moment().endOf('month').format('YYYY-MM-DD')
    return this.facurasPlan.where('idPlanOServicio','==',idPlan).where('fechaInicio','>',fecha).get()
  }

  public getAllPlanesRecurentes(){
    return this.planRecurrenteRef.get()
  }
  

}