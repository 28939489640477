import * as _ from "lodash";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { LocalDataSource } from "ng2-smart-table";
import { ServerService } from "src/app/services/server.service";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, } from "@angular/core";
import { ServicioService } from "src/app/services/servicio.service";
import * as moment from "moment";
import { AuthService } from "src/app/services/auth.service";
import { Helper } from "src/app/utility/helper";
import { Avatar, ColorMonitoring, HorasDeServicio, socialSecurity } from "../../utility/dataInfo";
import { setServe } from "src/app/utility/settings.table";
import { JSONServerLocalDataSource } from "src/app/models/interface/LocalDataSouce/ServersStatus";
import { Metricas } from "src/app/services/metricas.service";
import { event } from "jquery";
import * as firebase from "firebase";
import { ServBackendService } from "src/app/services/serv-backend.service";
import { AngularFireStorage } from "angularfire2/storage";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { NbDialogService,NbDialogRef  } from "@nebular/theme";
import { NotificationsService } from "src/app/services/notifications.service";


@Component({
  selector: "app-server",
  templateUrl: "./servers.component.html",
  styleUrls: ["./servers.component.scss"],
})
export class ServersComponent implements OnInit {

  @ViewChild("mdlSendNotification", { static: true }) mdlSendNotification: ElementRef;
  @ViewChild("mdlCreateBannersServers", { static: true }) mdlCreateBannersServers: ElementRef;
  dialogRef: NbDialogRef<any>;

  public generalLoader: boolean = true;
  public aspirantes = [];


  public allServersExcel: any = [];
  public serversActivasExcel: any = [];
  public serversDirectasExport: any = {};

  allServers: any = [];
  serversInactivas = [];
  usuarioEnSesion: any = {
    rol: '',
  }

  /* Variables para seguridad social */
  public fechaHoy: any = 1;
  public vencidos: Array<String> = [];
  public venciendose: Array<String> = [];
  /* -------------------------------------*/

  public array_dias = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo",
  ];

  public tableLocalDataSource: JSONServerLocalDataSource = {
    sourceActive: new LocalDataSource([]),
    lengthActive: 0,
    sourceInactive: new LocalDataSource([]),
    lengthInactive: 0,
  };

  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: 'activar', title: '<i data-toggle="tooltip" data-placement="bottom" title="Editar serve" class="nb-compose"></i> ' },
        // { name: 'delete', title: '<i data-toggle="tooltip" data-placement="bottom" title="Eliminar serve" class="nb-trash"></i> ' },
        { name: 'detalle-inactiva', title: '<i data-toggle="tooltip" data-placement="bottom" title="Ver serve" class="nb-search"></i>' }
      ],
    },
    columns: {
      ...setServe.columns,
      direccionResidencia: { title: "Direccion" },
      horasDisponible: { title: "Horas disponible" },
      porcentajeOcupacionSemana: { title: "porcentajeOcupacionSemana" },
      fechaActualizacion: { title: "Fecha de acualización" },
      faechaActiualizacionHorario: { title: 'Gestion semana' },
      estadoSeguridadSocial: {
        type: "html",
        title: "Seguridad Social",
        valuePrepareFunction: (cell) => {
          let i = cell == 'Aprobada' ? 1 
            : cell == 'Nueva' ? 2 
            : cell == 'Por consultar' ? 9 : 3;
          return `<div class="btn btn-light btn-sm ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
    },
  };

  settingsAspirantes = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        // { name: 'detalle', title: '<i class="nb-search"></i>' },
        { name: "reiniciarAspirante", title: '<i class="fa fa-reply custom" aria-hidden="true"></i>' },
      ],
    },
    columns: {
      email: { width: "50%", title: "Email" },
      fecha: {
        title: "Fecha", type: 'string',
        ValuePrepareFunction: (res) => {
          return moment(res).format('YYYY-MM-DD');
        }
      },
      estado: { title: "Estado" }

    },
    rowClassFunction: (row) => {
      if (row.data.estado == "Por vencer") {
        return "colorNaranja";
      } else {
        return "";
      }
    },
  };

  arrayBanners = [];
  public settingsBanners = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      filter: false,
      custom: [],
    },
    columns: {
      titulo: { title: "Titulo" },
      fechaCreacion: { title: "Fecha creacion" },
      fechaVencimiento: { title: "Fecha vencimiento" },
      link: { title: 'Link',
        valuePrepareFunction: (cell) => {
          return cell != null ? `<a href="${cell}">Ver link</a>` : 'NaN'
        }
       },
      contenido: { title: 'Contenido' },
    },
  };

  /*_________Tabla servers activas_______*/
  snapshot_servers = [];
  public settings_servers_for_availability = {
    actions: {
      ...setServe.actions,
    },
    columns: {
      nombre_completo: { title: "Nombre" },
      cities: { title: "Ciudad" },
      department: { title: "Departamento" },
      zona: { title: "Zona" },
      direccionResidencia: { title: "Direccion" },
      cedula: { title: "Documento" },
      telefono: { title: "Telefono" },
      // typoDocumento: { title: "Tipo documento" },
      fechaIngreso: {
        type: "html",
        title: 'Fecha ingreso',
        valuePrepareFunction: (cell) => {
          let dias = Math.abs(moment().diff(cell, 'days'))
          return `<p class="text-nowrap"> ${cell} <br> (${dias} Dias) </p>`;
        }
      },
      tipoContrato: {title: 'Contrato',

        valuePrepareFunction: (cell,row) => {
          return `${cell} - ${row.diponibilidadHoraria}`;
        },
      },
      estadoSeguridadSocial: {
        type: "html",
        title: "Seguridad Social",
        valuePrepareFunction: (cell) => {
          let i = cell == 'Aprobada' ? 1
          : cell == 'Nueva' ? 2
          : cell == 'Por consultar' ? 9 : 3;
          return `<div class="btn btn-light btn-sm ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
      porcentajeOcupacionSemana: { title: "Ocupacion general"},
      dias_libre: { title: 'Dias disponibles para plan'},
      porcentajeOcupacionPlanes: {
        type: "html",
        title: "Ocupacion basada en planes",
        valuePrepareFunction: (cell) => {
          return `<p> ${cell} </p>`
        }
      },
      planesRecurrentes: { title: 'Plan Asig.' },
      hoy: { title: "Hoy" },
      manana: { title: "Mañana" },
      habilidades: {
        title: 'Habilidades',
        valuePrepareFunction: (cell) => {
          let skill_string = cell.join(' ')
          return skill_string
        }
      },
      serviciosTerminados: { title: 'Servi. Term..' },
      novedadesPuntualidad: {width:'10px',title: 'Nov. Cance..', },
    },
  };
  /*_____________________________________ */

  /*_____moadales_____ */
  mdlVerServer = false;
  serverVisualizada: any;
  serverParaEditar: any;
  mdlAgregarServer = false;
  mdlActualizarServer = false;
  tipoModalServer = "crear";
  /*___________________*/

  infoPlanesRecurrentes: any = {};
  infoNovedades: any = {};
  infoDisponibilidad: any = {};
  infoDisponibilidadBloqueos: any = {};
  servers_for_availability = [];
  date_selected: any = '';
  tool_tip_export_ocupacion = false;
  notification: any = {title: '',body:'',deviceId:''};
  banner = {titulo: '',contenido:'',deviceId:'',fechaVencimiento: '',fechaCreacion: '', link: '',};

  constructor(
    public router: Router,
    public helper: Helper,
    public datePipe: DatePipe,
    public metricasService: Metricas,
    public authService: AuthService,
    public ServerService: ServerService,
    public ServicioService: ServicioService,
    private changeDetectorRef: ChangeDetectorRef,
    private servBackendService: ServBackendService,
    private angularFireStorage: AngularFireStorage,
    private nbDialogService: NbDialogService,
    private notificationsService: NotificationsService
  ) {
    this.usuarioEnSesion = this.authService.storeGetUserData('usuario')
  }

  async ngOnInit() {

    this.getNovedadesServers();
    this.getAllServers();
    this.getAspirantes();

    await this.cargarDatos();
    this.generTablaParaDisponibilidadServers();
  }

  cargarDatos() {
    const promise_1 = this.getProximosServicios();
    const promise_2 = this.getPlanesRecurrentesServers();
    const promise_3 = this.getDiasBloqueados();
    return Promise.all([promise_1, promise_2, promise_3]);
  }

  async getProximosServicios() {
    return new Promise(async result => {
      this.infoDisponibilidad = {}
      const hoy = moment().format("YYYY-MM-DD")
      const manana = moment().add(1, "day").format("YYYY-MM-DD")
      const query = await this.ServicioService.getInDateRange(hoy, manana);
      for (let i = 0; i < query.size; i++) {
        const servicio = query.docs[i].data();
        if (!this.infoDisponibilidad[servicio.idServer]) this.infoDisponibilidad[servicio.idServer] = { hoy: "Ocupada", manana: "Ocupada" };
        if (servicio.fecha == hoy) {
          this.infoDisponibilidad[servicio.idServer].hoy =
            `${this.infoDisponibilidad[servicio.idServer].hoy} desde ${servicio.hora},${servicio.horasDeServicio} horas`;
        }
        else if (servicio.fecha == manana) {
          this.infoDisponibilidad[servicio.idServer].manana =
            `${this.infoDisponibilidad[servicio.idServer].manana} desde ${servicio.hora},${servicio.horasDeServicio} horas`;
        }
      }
      result(null);
    })
  }

  getPlanesRecurrentesServers() {
    return new Promise(async result => {
      this.infoPlanesRecurrentes = {}
      const query = await this.ServicioService.getAllPlanRecurrente();
      for (let i = 0; i < query.size; i++) {
        const data = query.docs[i].data();
        if (!this.infoPlanesRecurrentes[data.idServerAsignada]) this.infoPlanesRecurrentes[data.idServerAsignada] = {horas: 0, numero_planes: 0, dias_string: '', dias: [] }
        this.infoPlanesRecurrentes[data.idServerAsignada].numero_planes++;
        this.infoPlanesRecurrentes[data.idServerAsignada].dias = this.infoPlanesRecurrentes[data.idServerAsignada].dias.concat(data.diasAgendado)
        this.infoPlanesRecurrentes[data.idServerAsignada].horas += parseInt(data.horasDeServicio) * data.diasAgendado.length;
        this.infoPlanesRecurrentes[data.idServerAsignada].ocupacion = (this.infoPlanesRecurrentes[data.idServerAsignada].horas/ 48) * 100;
        this.infoPlanesRecurrentes[data.idServerAsignada].dias_string = await this.validarDias(this.infoPlanesRecurrentes[data.idServerAsignada].dias)
      }
      result(null)
    })
  }

  validarDias(dias) {
    return new Promise(result => {
      let dias_libre = this.array_dias.filter(res => !dias.includes(res))
      let dias_string = dias_libre.join(' / ')
      return result(dias_string)
    })
  }
  getDiasBloqueados() {
    this.ServerService.getAllDisponibilidad().then(res => {
      const hoy = moment().format("YYYY-MM-DD")
      const manana = moment().add(1, "day").format("YYYY-MM-DD")


      for (let i = 0; i < res.size; i++) {
        const blocked_dates = res.docs[i].data().fechas;
        // Elimina fechas bloqueadas duplicadas
        // let uniqueArray = blocked_dates.filter((obj, index, self) =>
        //   index === self.findIndex((o) => o.fecha === obj.fecha)
        // );
        // res.docs[i].ref.update({fechas:uniqueArray})

        this.infoDisponibilidadBloqueos[res.docs[i].id] = { all: blocked_dates , hoy: "", manana: "" };
        if (blocked_dates.some(res => res.fecha == hoy)) {

          this.infoDisponibilidadBloqueos[res.docs[i].id].hoy = 'Bloqueada'
        }
        if (blocked_dates.some(res => res.fecha == manana)) {
     
          this.infoDisponibilidadBloqueos[res.docs[i].id].manana = 'Bloqueada'
        }
      }
    })
  }

  public aspiranteServer: any = [];
  public serverCongeladas: any = [];
  getAllServers() {
    this.ServerService.all().then((res) => {
      this.allServersExcel = [];
      this.serversActivasExcel = [];
      const serverActive: Array<any> = [];
      const serverInactive: Array<any> = [];
      const aspiranteServer: Array<any> = [];
      const serverCongeladas: Array<any> = [];
      res.docs.forEach(async (data: any, index) => {
        const server = data.data();

        if (!server.foto) server.foto = Avatar;
        // if (server.fechaIngreso > moment().subtract(15,'days').format('YYYY-MM-DD')) {
        //   server.estadoSeguridadSocial = 'Nueva';
        // } 
        // else if (server.fechaIngreso < moment().subtract(15,'days').format('YYYY-MM-DD') && server.tipoManejoSeguridadSoscial == undefined) {
        //   server.estadoSeguridadSocial = 'Por consultar'
        // } else 
        
        if (server.fechaVencimientoSeguridadSocial > moment().format("YYYY-MM-DD")) {
          server.estadoSeguridadSocial = 'Aprobada'
        } else if (server.fechaVencimientoSeguridadSocial < moment().format("YYYY-MM-DD")) {
          server.estadoSeguridadSocial = 'vencida'
        } else if (server.estadoSeguridadSocial == undefined || server.estadoSeguridadSocial == '') {
          server.estadoSeguridadSocial = 'Sin planilla' 
        }

        if (this.helper.notUndefined(server.fechaVencimientoSeguridadSocial)) {
          const temp = new Date();
          this.fechaHoy = this.datePipe.transform(temp, "yyyy-MM-dd");
          const temp2 = new Date(server.fechaVencimientoSeguridadSocial);
          temp2.setDate(temp2.getDate() - 9);
          const temp3 = this.datePipe.transform(temp2, "yyyy-MM-dd");

          if (server.fechaVencimientoSeguridadSocial <= this.fechaHoy) {
            this.vencidos.push(server.name);
            server.seguridadSocial = 1;
          } else if (
            temp3 <= this.fechaHoy &&
            this.fechaHoy <= server.fechaVencimientoSeguridadSocial
          ) {
            this.venciendose.push(server.name);
            server.seguridadSocial = 2;
          }
        } else {
          server.seguridadSocial = 3;
        }
        let fechaFormateada = this.datePipe.transform(server.fecha * 1000, 'yyyy-MM-dd');
        let diasTotales = moment().diff(fechaFormateada, "days")
        if (diasTotales < 0) {
          fechaFormateada = moment(server.fecha).format('YYYY-MM-DD');
          diasTotales = moment().diff(fechaFormateada, "days")
        }

        const serverExport: any = {
          Nombre: server.name,
          Apellido: server.apellido,
          "Tipo de Documento": server.typoDocumento,
          "Numero de documento": server.cedula,
          Departamento: server.department,
          Direccion: server.direccionResidencia,
          "Fecha de ingreso": fechaFormateada,
          "Dias desde ingreso": diasTotales,
          Email: server.email,
          Telefono: server.telefono,
          "Porcentaje de ocupacion": server.porcentajeOcupacionSemana,
          "Fecha de nacimiento": this.helper.dateDefaultNotHour(server.fechaNacimiento),
          Estado: server.estado,
          tipoCuentaBancaria: server.tipoCuentaBancaria,
          numeroCuenta: server.numeroCuenta,
          "Contrato": server.tipoContrato != undefined ? server.tipoContrato : "Falta",
          "Horario": server.diponibilidadHoraria != undefined ? server.diponibilidadHoraria : "Falta",
          "Eps": server.eps,
          "Arl": server.arl,
          "Fondo de pencion": server.fondoDePensiones,
          "Caja de compensacion": server.cajaDeCompensacion,
          'Fecha desactivacion': server.fechaDesactivacion != undefined ? server.fechaDesactivacion : "",
          id: server.id
        };
        

        this.allServersExcel.push(serverExport);
        if (server.estado == "desactivado" || server.estado == "Desactivado") {
          serverInactive.push(server);
          if(server.tipoContrato == 'directo')this.serversInactivas.push(server)
        } else if (server.estado == "activo" || server.estado == "Activo") {
          this.allServers.push(server)
          this.serversActivasExcel.push(serverExport);
          serverActive.push(server);
        } else if (server.estado == 'congelado') {
          serverCongeladas.push(server);
        } else if (server.estado == 'aspirante') {
          aspiranteServer.push(server);
        }
        if (index == res.size - 1) {
          this.aspiranteServer = aspiranteServer;
          this.serverCongeladas = serverCongeladas;
          this.tableLocalDataSource = {
            sourceActive:new LocalDataSource(serverActive), /* this.helper.setLocalDataSource(serverActive, "name")*/
            lengthActive: serverActive.length,
            sourceInactive:new LocalDataSource(serverInactive), /* this.helper.setLocalDataSource(serverInactive,"name")*/
            lengthInactive: serverInactive.length,
          };
          setTimeout(() => {
            this.generalLoader = false;
          }, 2000);
        }
      });
    });
  }

  async onCustom(event) {
    console.info('Server', event.data);

    switch (event.action) {
      case "edit":
        this.serverParaEditar = (await this.snapshot_servers.filter(res => res.id == event.data.id)[0]).data()
        this.openModalActualizarServer();
        break;
      case "activar":
        this.serverParaEditar = event.data;
        this.openModalActualizarServer();
        break;
      case "delete":
        this.eliminarServer(event.data);
        break;
      case "detalle":
        this.serverVisualizada = (await this.snapshot_servers.filter(res => res.id == event.data.id)[0]).data()
        this.openModalVerServer();
        break;
      case "detalle-inactiva":
        this.serverVisualizada = event.data;
        this.openModalVerServer();
        break;
    }
  }

  openModalActualizarServer() {
    this.tipoModalServer = "editar"
    this.mdlActualizarServer = false;
    setTimeout(() => {
      this.mdlActualizarServer = true;
    }, 500);
  }

  openModalCrearServer() {
    this.tipoModalServer = "crear";
    this.mdlAgregarServer = false;
    setTimeout(() => {
      this.mdlAgregarServer = true;
    }, 500);
  }

  openModalVerServer() {
    this.mdlVerServer = false;
    setTimeout(() => {
      this.mdlVerServer = true;
    }, 500);
    // this.router.navigate(["dashboard/ver-server", id_server]);
  }

  eliminarServer(data) {
    Swal.fire({
      title: 'Estas seguro(a)?',
      text: "Esa server sere eliminada de nuestro sistema!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
    
      if (result.value) {
        this.ServerService.delete(data.id).then(() => {
          Swal.fire('Listo', "El usuario a sido eliminado", 'success')
          this.getAllServers();
        })
      }
    })
  }

  export() {
    this.ServerService.exportAsExcelFile(this.serversActivasExcel, "Servers");
  }
  async export_all() {
    this.generalLoader = true;
    await Promise.all(
      this.allServersExcel.map(async (res) => {
        const lastService = await this.ServicioService.getLastServerService(res.id);
        res.fechaUltimoServicio = lastService.docs.length > 0 ?  lastService.docs[0].data().fecha : null;
      })
    );
    this.generalLoader = false;
    this.ServerService.exportAsExcelFile(this.allServersExcel, "Servers");
  }

  async getAspirantes() {
    let array_aspirantes = []
    const querry = await this.ServerService.getAspirantes();
    for (let i = 0; i < querry.size; i++) {
      array_aspirantes.push(querry.docs[i].data());
    }
    this.aspirantes = array_aspirantes;
  }

  async reiniciarAspirante(event) {
    let info = {
      faseRegistro: 1,
      estado: 'Solicitud recibida'
    }
    // const b = await this.ServerService.updateEstudiante(event.data.id, info);
    const a = await this.ServerService.updateAspirante(event.data.id, info);
    Swal.fire('Listo', 'El formulario del aspirante fue reiniciado, indcale que cierre la app e ingrese nuevamente')
  }

  generTablaParaDisponibilidadServers() {
    console.log(moment().subtract(7,'days'))
    let dias_string = this.array_dias.join(' ')
    let servers = []
    const query = this.ServerService.actives_rt() //onSnapshot
    // const query = this.ServerService.Actives()
    query.onSnapshot(async snapshot => {
      this.snapshot_servers = snapshot.docs;
      const disponibilidadPromises = [];
      for (let a = 0; a < snapshot.docChanges().length; a++) {
        const res = snapshot.docChanges()[a]
        if (res.type == 'added') {
          let server = res.doc.data();
          if(server.tipoManejoSeguridadSoscial == "ninguna"){
            server.estadoSeguridadSocial = 'Sin planilla';
          }
          else if(server.tipoManejoSeguridadSoscial == "externa"){
            if (server.estadoSeguridadSocial == 'aprobada' && server.fechaVencimientoSeguridadSocial > moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Aprobada';
            } else if (server.fechaVencimientoSeguridadSocial < moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Vencida';
            }
          }
          else{

            if (server.fechaIngreso >= moment().subtract(8,'days').format('YYYY-MM-DD') && server.tipoManejoSeguridadSoscial == undefined) {
              server.estadoSeguridadSocial = 'Nueva';
            } else if (server.fechaIngreso < moment().subtract(8,'days').format('YYYY-MM-DD') && server.tipoManejoSeguridadSoscial == undefined) {
              server.estadoSeguridadSocial = 'Por consultar';
            } 
            else if(server.estadoSeguridadSocial == 'Por pagar'){
              server.estadoSeguridadSocial == 'Por pagar';
            }
            else if (Math.abs(moment(server.fechaIngreso).diff( moment().subtract(30,'days'),'days')) < 30 ) {
              server.estadoSeguridadSocial = 'En proceso';
            } else if (Math.abs(moment(server.fechaIngreso).diff( moment().subtract(30,'days'),'days')) > 30 && server.estadoSeguridadSocial == '') {
              server.estadoSeguridadSocial = 'Por pagar';
            } else if (server.estadoSeguridadSocial == 'aprobada' && server.fechaVencimientoSeguridadSocial > moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Aprobada';
            } else if (server.fechaVencimientoSeguridadSocial < moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Vencida';
            } else if (server.estadoSeguridadSocial == undefined || server.estadoSeguridadSocial == '') {
              server.estadoSeguridadSocial = 'Sin planilla'
            }
          }
          server.nombre_completo = `${server.name} ${server.apellido}`
          server.planesRecurrentes = this.infoPlanesRecurrentes[res.doc.id] ? this.infoPlanesRecurrentes[res.doc.id].numero_planes : 0;
          server.dias_libre = this.infoPlanesRecurrentes[res.doc.id] ? this.infoPlanesRecurrentes[res.doc.id].dias_string : dias_string;
          server.porcentajeOcupacionPlanes = this.infoPlanesRecurrentes[res.doc.id] ? `${this.infoPlanesRecurrentes[res.doc.id].horas}H <br> ${Math.trunc((this.infoPlanesRecurrentes[res.doc.id].horas / server.disponibilidadSenama) * 100)} %` : '0 %';
          server.novedadesPuntualidad = this.infoNovedades[res.doc.id] ? this.infoNovedades[res.doc.id] : 0;
          server.hoy = this.infoDisponibilidadBloqueos[res.doc.id] && this.infoDisponibilidadBloqueos[res.doc.id].hoy != ""
            ? `${this.infoDisponibilidadBloqueos[res.doc.id].hoy}`
            : this.infoDisponibilidad[res.doc.id]
              ? this.infoDisponibilidad[res.doc.id].hoy
              : 'Libre';
          server.manana = this.infoDisponibilidadBloqueos[res.doc.id] && this.infoDisponibilidadBloqueos[res.doc.id].manana !== ""
            ? `${this.infoDisponibilidadBloqueos[res.doc.id].manana}`
            : this.infoDisponibilidad[res.doc.id]
              ? this.infoDisponibilidad[res.doc.id].manana
              : 'Libre';

          // const servicios: any = await this.getPoximoServicioServer(server.id);
          // server.hoy = servicios.hoy
          // server.manana = servicios.manana
          // disponibilidadPromises.push(this.getPoximoServicioServer(server.id));
          servers.push(server);
        }else if(res.type == 'modified'){
          let server = res.doc.data();
          if(server.tipoManejoSeguridadSoscial == "ninguna"){
            server.estadoSeguridadSocial = 'Sin planilla';
          }
          else if(server.tipoManejoSeguridadSoscial == "externa"){
            if (server.estadoSeguridadSocial == 'aprobada' && server.fechaVencimientoSeguridadSocial > moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Aprobada';
            } else if (server.fechaVencimientoSeguridadSocial < moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Vencida';
            }
          }
          else{
            if (server.fechaIngreso >= moment().subtract(8,'days').format('YYYY-MM-DD') && server.tipoManejoSeguridadSoscial == undefined) {
              server.estadoSeguridadSocial = 'Nueva';
            } else if (server.fechaIngreso < moment().subtract(8,'days').format('YYYY-MM-DD') && server.tipoManejoSeguridadSoscial == undefined) {
              server.estadoSeguridadSocial = 'Por consultar';
            }
            else if(server.estadoSeguridadSocial == 'Por pagar'){
              server.estadoSeguridadSocial == 'Por pagar';
            }
            else if (Math.abs(moment(server.fechaIngreso).diff( moment().subtract(30,'days'),'days')) < 30 ) {
              server.estadoSeguridadSocial = 'En proceso';
            } else if (Math.abs(moment(server.fechaIngreso).diff( moment().subtract(30,'days'),'days')) > 30 && server.estadoSeguridadSocial == '') {
              server.estadoSeguridadSocial = 'Por pagar';
            } else if (server.estadoSeguridadSocial == 'aprobada' && server.fechaVencimientoSeguridadSocial > moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Aprobada';
            } else if (server.fechaVencimientoSeguridadSocial < moment().format("YYYY-MM-DD")) {
              server.estadoSeguridadSocial = 'Vencida';
            } else if (server.estadoSeguridadSocial == undefined || server.estadoSeguridadSocial == '') {
              server.estadoSeguridadSocial = 'Sin planilla'
            }
          }
          server.nombre_completo = `${server.name} ${server.apellido}`
          server.planesRecurrentes = this.infoPlanesRecurrentes[res.doc.id] ? this.infoPlanesRecurrentes[res.doc.id].numero_planes : 0;
          server.dias_libre = this.infoPlanesRecurrentes[res.doc.id] ? this.infoPlanesRecurrentes[res.doc.id].dias_string : dias_string;
          server.porcentajeOcupacionPlanes = this.infoPlanesRecurrentes[res.doc.id] ? `${this.infoPlanesRecurrentes[res.doc.id].horas}H <br> ${Math.trunc((this.infoPlanesRecurrentes[res.doc.id].horas / server.disponibilidadSenama) * 100)}  %` : '0 %';
          server.novedadesPuntualidad = this.infoNovedades[res.doc.id] ? this.infoNovedades[res.doc.id] : 0;
          server.hoy = this.infoDisponibilidadBloqueos[res.doc.id] && this.infoDisponibilidadBloqueos[res.doc.id].hoy != ""
            ? `${this.infoDisponibilidadBloqueos[res.doc.id].hoy}`
            : this.infoDisponibilidad[res.doc.id]
              ? this.infoDisponibilidad[res.doc.id].hoy
              : 'Libre';
          server.manana = this.infoDisponibilidadBloqueos[res.doc.id] && this.infoDisponibilidadBloqueos[res.doc.id].manana !== ""
            ? `${this.infoDisponibilidadBloqueos[res.doc.id].manana}`
            : this.infoDisponibilidad[res.doc.id]
              ? this.infoDisponibilidad[res.doc.id].manana
              : 'Libre';

          servers[res.oldIndex] = res.doc.data();
        }
      }

      // const disponibilidadResults = await Promise.all(disponibilidadPromises);
      // for (let i = 0; i < snapshot.docChanges().length; i++) {
      //   const res = snapshot.docChanges()[i];
      //   if (res.type === 'added') {
      //     servers[i].hoy = disponibilidadResults[i].hoy;
      //     servers[i].manana = disponibilidadResults[i].manana;
      //   }
      // }
      this.servers_for_availability = servers;
    })
  }

  async getPoximoServicioServer(id) {

    let disponibilidad = {
      hoy: 'Libre',
      manana: 'Libre',
    }
    const hoy = moment().format("YYYY-MM-DD")
    const manana = moment().add(1, "day").format("YYYY-MM-DD")
    const query = await this.ServicioService.getByServerInDateRange(id, hoy, manana);
    for (let i = 0; i < query.size; i++) {
      const servicio = query.docs[i].data();
      if (servicio.fecha == hoy) disponibilidad.hoy = `Ocupada ${servicio.horasDeServicio} horas`;
      if (servicio.fecha == manana) disponibilidad.manana = `Ocupada ${servicio.horasDeServicio} horas`
    }
    return disponibilidad
  }

  // return new Promise(async result =>{
  //   let disponibilidad = {
  //     hoy: 'Libre',
  //     manana: 'Libre',
  //   }
  //   const hoy =  moment().format("YYYY-MM-DD")
  //   const manana = moment().add(1,"day").format("YYYY-MM-DD")
  //   const query = await this.ServicioService.getServiciosServerRangoDeFecha(id,hoy, manana);
  //   for(let i = 0;i < query.size;i++){
  //     const servicio = query.docs[i].data();
  //     if(servicio.fecha == hoy ) disponibilidad.hoy = 'Ocupada'
  //     if(servicio.fecha == hoy && servicio.horasDeServicio <= 4) disponibilidad.hoy = 'Ocupada 4 horas'
  //     if(servicio.fecha == manana ) disponibilidad.manana = 'Ocupada'
  //     if(servicio.fecha == manana && servicio.horasDeServicio <= 4) disponibilidad.manana = 'Ocupada 4 horas'
  //   }

  //   return result(disponibilidad)
  // })



  async getNovedadesServers() {
    this.infoNovedades = {};
    const start_date = moment().subtract(45, 'days').format('YYYY-MM-DD')
    const end_date = moment().format('YYYY-MM-DD')
    const query = await this.metricasService.getNovedades_tipo_ragoFechaCreacion(start_date, end_date)
    console.info('Novedades cancelacion', query.size)
    for (let i = 0; i < query.size; i++) {
      const data = query.docs[i].data()
      if (!this.infoPlanesRecurrentes[data.serverId]) this.infoNovedades[data.serverId] = 0;
      this.infoNovedades[data.serverId]++;
    }
  }

  database = firebase.firestore();
  async medirServiciosTerminados() {

    console.log(this.servers_for_availability.length)
    for (let i = 0; i < this.servers_for_availability.length; i++) {
      const res = (await this.database.collection('servicios/').where('idServer', '==', this.servers_for_availability[i].id).where('estado', '==', 'Terminado').get()).size;
      console.log(res);

      this.database.collection('servers/').doc(`${this.servers_for_availability[i].id}`).update({ serviciosTerminados: res });
    }
  }

  actualizarHabilidades() {
    this.servers_for_availability.forEach(server => {
      let new_skills = server.habilidades.map(skill => {
        if (skill.subCategoria == "Planchado de ropa") return "Planchado"
        if (skill.subCategoria == "Cuidado de mascotas") return "Ciudado mascotas"
        if (skill.subCategoria == "Cuidado de adultos mayores") return "Cuidado adultos mayores"
        if (skill.subCategoria == "Cuidado de niños") return "Cuidado niños"
        if (skill.subCategoria == "Aseo y limpieza") return 'Aseo empresa'
        if (skill.subCategoria == "Empleada doméstica") return 'Aseo hogar'
        if (skill.subCategoria == "Termonebulización") return 'Desinfeccion'
        if (skill.subCategoria == "Alquiler de purificador de aire" || skill.subCategoria == "Limpieza de colchone" ||
          skill.subCategoria == "Limpieza de tapetes" || skill.subCategoria == "Limpieza de muebles" ||
          skill.subCategoria == "Alquiler de hidrolavadora" || skill.subCategoria == "Alquiler de aspiradora" ||
          skill.subCategoria == "Limpieza de tapetes" || skill.subCategoria == "Limpieza de muebles") return 'Maquinas'
        return 'no';
      }).filter(value => value != 'no')

      console.log(server.habilidades, new_skills)

      this.ServerService.updateServ({ id: server.id, habilidades: new_skills })
    })
  }
  /* Ocupacion para el exportable */
  async calcularOcupacion(){
    if(this.date_selected != ''){
      this.tool_tip_export_ocupacion = false;
      this.generalLoader = true;
      const startDate = this.date_selected;
      const endDate = moment(this.date_selected).add(6,'day').format('YYYY-MM-DD');
      const dateArray: string[] = [];
      let currentDate = startDate;
  
      while (currentDate <= endDate) {
        dateArray.push(currentDate); // Puedes ajustar el formato según tus necesidades
        currentDate = moment(currentDate).add(1,'day').format("YYYY-MM-DD");
      }

      let servers = this.allServers.map(res=>{
        const info = {name: `${res.name} ${res.apellido}`, department: res.department, '4': "", '5': "", '6': "", '0': "", '1': "", '2': "", '3': "",id: res.id}
        for(let date of dateArray){
          const day = moment(date).format('d')
          if (this.infoDisponibilidadBloqueos[res.id] && this.infoDisponibilidadBloqueos[res.id].all.some(res => res.fecha == date)) {
            info[day] = "bloqueada"
          }
        }
        return info
      })
  
      const query = await this.ServicioService.getByStateInDateRange("Terminado","2023-11-23",'2023-11-29')
      for(let i = 0;i<query.size;i++){
        const service = query.docs[i].data();
        const day = moment(service.fecha).format('d')
        const index = servers.findIndex(res=> res.id == service.idServer)
        if(index !== -1){
          servers[index][day] = `${servers[index][day]}${service.horasDeServicio}`
        }
      }
  
      let days = ["4","5","6","0","1","2","3"]
      let export_data = servers.map(res=>{
  
        const info = {"Nombre": res.name, "Departamento": res.department, "J": res[days[0]] , 'V': res[days[1]], 'S': res[days[2]], 'D': res[days[3]], 'L': res[days[4]], 'Ma': res[days[5]], 'Mi': res[days[6]]}
        return info
      })
      this.ServerService.exportAsExcelFile(export_data, `Servers_${startDate}_${endDate}_`);
      this.generalLoader = false;
    }else{
      Swal.fire('Espera','Selecciona la fecha de inicio, recuerda que se contaran 7 dias apartir de la fecha seleccionada para el rango','warning')
    }
  }

  

  onOpenSendNotification(){
    this.notification = {title: '',body:'',deviceId:''}
    let modal: any;
    modal = this.mdlSendNotification;
    this.dialogRef = this.nbDialogService.open(modal, { 
      context: "this is some additional data passed to dialog",
      hasBackdrop: true,
      closeOnBackdropClick: false
    });
  }
  onSendNotification(){
    if(this.notification.title != '' && this.notification.body != ''){
      this.generalLoader = true;
      this.servBackendService.post('api/serv/send_servers_push_notification',this.notification).subscribe(res => {
        this.generalLoader = false
        this.dialogRef.close();
        this.notification.responsable = this.usuarioEnSesion.displayName;
        this.notification.fechaCreacion = moment().format('YYYY-MM-DD');
        this.notification.deviceId = 'servers';
        this.notificationsService.add(this.notification)
        Swal.fire('Listo','La notoficacion fue enviada','info')
      })
    }
  }
  onOpenCreateBannersServers(){
    this.notification = {title: '',body:'',deviceId:''}
    let modal: any;
    modal = this.mdlCreateBannersServers;
    this.dialogRef = this.nbDialogService.open(modal, { 
      context: "this is some additional data passed to dialog",
      hasBackdrop: true,
      closeOnBackdropClick: false
    });
  }

  onSendBannersServers(){
    if(this.banner.fechaVencimiento != '' && this.banner.contenido != '' && this.banner.titulo != ''){
      this.generalLoader = true;
      if(this.banner.link == '') this.banner.link = null;
      this.banner.fechaCreacion = moment().format('YYYY-MM-DD');
      this.banner.fechaVencimiento = moment(this.banner.fechaVencimiento).format('YYYY-MM-DD');
      firebase.firestore().collection('banners_appServers').add(this.banner).then(()=>{
        Swal.fire('Listo','El anuncio fue publicado','info')
        this.banner.link = '';
        this.banner.titulo = '';
        this.banner.contenido = '';
        this.banner.fechaVencimiento = '';
        this.generalLoader = false;
      })
    }
  }

  async exportarHistorialServersInactivas(){
    this.generalLoader = true;
    // const servers = await this.tableLocalDataSource.sourceInactive. getElements();
    console.log(this.serversInactivas)
    let servers_promises = this.serversInactivas.map(async server=>{
      let valores = {
        nombre: `${server.name} ${server.apellido}`,
        horasTrabajadas: 0,
        serviciosRealizados: 0
      }
      const query = await this.ServicioService.getServiciosServerTerminado(server.id)
      valores.serviciosRealizados = query.size;
      let services_promises = query.docs.map(data=>{
        valores.horasTrabajadas = valores.horasTrabajadas + parseInt(data.data().horasDeServicio);
        return true;
      });
      const res_pro = await Promise.all(services_promises);
      return valores
    })
    const resultPromise = await Promise.all(servers_promises);
    console.log(resultPromise);
    this.ServicioService.exportAsExcelFile(resultPromise, "Servicio");
    this.generalLoader = false;
  }

  async getBanners(){
    const query = await firebase.firestore().collection('banners_appServers').get();
    this.arrayBanners = query.docs.map(res=>{
      return {...res.data()};
    })
  }
}
