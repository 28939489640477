import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { festivosColombia } from 'src/app/utility/festivosColombia';
import { ServerService } from "src/app/services/server.service";
import { ServicioService } from "src/app/services/servicio.service";
import { PagosServerService } from "src/app/services/pagos-server.service";
import { LocalDataSource } from "ng2-smart-table";
import * as moment from "moment";
import * as _ from "lodash";
import { setServicesServer } from "../../utility/settings.table";
import { PlanService } from "src/app/services/plan.service";
import { RRule, RRuleSet, Weekday } from 'rrule';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { NbDialogService } from "@nebular/theme";
import { ViewChild, ElementRef } from "@angular/core";
import { airbnb } from "../../../assets/fonts/airbnb.ttf.Base64.encoded";
import { italianno } from "../../../assets/fonts/italiano.ttf.Base64.encoded";
import { AirbnbCereal_W_Bd } from "../../../assets/fonts/AirbnbCereal_W_Bd.base64";
import { JazminAltBoldEncoded } from "../../../assets/fonts/JazminAlt-Bold.encoded";
import Swal from "sweetalert2";
import { Subject } from "rxjs";

import { CalendarView,CalendarMonthViewBeforeRenderEvent} from "angular-calendar";
import { habilidadesWithIcon } from '../../utility/dataInfo';

// iconos habilidades

const colors: any = {
  plan: {
    primary: "var(--primary)",
    secondary: "var(--primary)",
  },
  individual: {
    primary: "#20c997",
    secondary: "#20c997",
  },
  bloqueado: { primary: "#c20303", secondary: "#973121"},
  secondary:{ primary: '#ff8900', secondary: '#FAE3E3' },
  blue_2:{ primary: '#00dbff', secondary: '#00dbff' }
};

import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";
import * as firebase from "firebase";
import { StorageService } from "src/app/services/storage.service";
import { posventaService } from "src/app/services/posventa.service";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-ver-server",
  templateUrl: "./ver-server.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./ver-server.component.scss"],
})
export class VerServerComponent implements OnInit {

  @Input() server: any; // public server: any | null = null;
  @ViewChild("mdlverServer", { static: true }) mdlverServer: ElementRef;

  id_server = '';
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  stars: number = 0;
  campos: string[] = [];

  public servicios: Array<Object> = [];
  public source: LocalDataSource;
  public planesMensualesActivos: any = [];
  // public source: LocalDataSource;
  public sourceNew: LocalDataSource;
  public calendarEvents: any = [];

  public archivosSeguridadSocial: any = [];
  public arcivosSubido = "sinArchivo";
  
  settingsTH = setServicesServer;

  public fechaCert: any;
  public fechaFin: any;

  //archivos
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploadState: Observable<string>;

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
    },
    columns: {
      totalServicios: { title: "# Servicios" },
      horasDeServicio: { title: "duracion de Servicios" },
      usuario: { title: "Usuario" },
      estado: { title: "Estado" },
      estadoPago: { title: "Estado Pago" },
      formaDePago: { title: "Forma de Pago" },
      recurrente: { title: "Recurrente" },
      numeroFactura: { title: "Numero de factura" },
      fechaVencimiento: { title: "Fecha de vencimiento" },
    },
  };
  settingsPlanRec = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      clienteName: { title: "Cliente" },
      horas: { title: "duracion de Servicios" },
      horaInicio: { title: "Hora de inicio" },
      numServicios: { title: "numero de servicios" },
      diasAgendado: {
        title: "Dias del plan",
        type: "htlm",
        valuePrepareFunction: (cell) => {
          return cell;
        },
      },
    },
  };

  selectedDays: any = [];
  public serviciosDiarios: any = [];
  public verServcioscalendario = false;

  public sourceCalendario: LocalDataSource;
  settingsCalendario = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        // { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      nombreCliente: { title: "Cliente" },
      fecha: { title: "Fecha del servicio" },
      hora: { title: "hora" },
      horasDeServicio: { title: "Horas de servicio" },
      tipoServicio: { title: "Tipo de servicio" },
      precio: { title: "Precio" },
      estado: { title: "Estado" },
    },
  };

  public horasAgendadosMesActual: number = 0;
  public serviciosAgendadosMesActual: number = 0;

  sourcePagosServer: any;
  settingsPagos = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      /* custom: [
        { name: 'detalle', title: '<i class="nb-search"></i>' },
        { name: 'causar', title: '<i class="nb-compose"></i>' }
      ], */
    },
    columns: {
      fechaInicio: { title: "Fecha inicio" },
      fechaFin: { title: "Fecha fin" },
      totalServicios: { title: "Total servicios" },
      pagoNetoServer: { title: "Pago server" },
    },
  };

  public habilidades = habilidadesWithIcon

  public hoy = moment();
  public diasServicio: any;
  public dataProductosServer: any;
  idPostventa: any;
  blockedDates = [];
  unlock_active_day_button = false;

  viewEditSeguridadSocial = false;
  viewAddFile = false;
  fileSelectedForUpload: File | null = null;
  file_to_look: any;
  sourceFilesServer: any | null = null;
  settingsFiles = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'look_file', title: '<i class="nb-search"></i>' },
      ], 
    },
    columns: {
      name: { title: "Nombre" },
      date_created: { title: "Fecha de subida " },
    },
  };

  newFile = {
    name: '',
    url: '',
    file_path: '',
    date_created: moment().format('YYYY-MM-DD'),
  }


  generalLoader = false;
  viewEditContrato: boolean;

  arrayCalificaciones = [];
  settingsCalificaciones = {
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    columns: {
      asesor: { title: "Asesor" },
      calificacion: { title: "Calificacion" },
      calidad: { title: "Calidad" },
      puntualidad: { title: "Puntualidad" },
      actitud: { title: "Actitud" },
      fecha:{ title: "Fecha calificacion" },
      fechaServicio: { title: "Fecha servicio" },
      cliente: { title: "Cliente" },
      estado: { title: "Estado" },
      comentario: { title: "Comentario" },
      comentarioPrivado: { title: "Comentario privado" },
    },
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private planService: PlanService,
    private storage: AngularFireStorage,
    private ServerService: ServerService,
    private ServicioService: ServicioService,
    private dialogService: NbDialogService,
    private storageService: StorageService,
    private posventaService: posventaService,
    private pagosServerService: PagosServerService,
  ) {}

  async ngOnInit() {
    this.id_server = this.server.id
    console.log(this.server)
    this.openDialogSave(this.mdlverServer);
    this.cdRef.detectChanges();

    this.getServices('')
    this.getPlanesMensuales(this.id_server);
    this.getPlanesRecurrentesAsociados(this.id_server);

    // this.getPostventas(this.id_server);
    // this.getProductos();

  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  async getServices(type_query){
    this.servicios = [];
    let events_calendar = []
    const date_month_ago = moment().subtract(30,'days').format('YYYY-MM-DD')
    let query = this.ServicioService.getByServerFromADate(this.id_server,date_month_ago)
    if(type_query == 'all') query =  this.ServicioService.getServiciosServer(this.id_server);
    query.then(async res=>{
      console.log('Consiultando servicios',res.size);
      for(let i = 0;i<res.size;i++){
        const service = res.docs[i].data();
        this.servicios.push(service);
        if(service.estado != 'Cancelado' && service.estado != 'Congelado'){
          const event = {
            start: moment(service.fecha),
            end: moment(service.fecha),
            color:
              service.tipoServicio == "Plan Mensual"
                ? colors.plan
                : service.tipoServicio == "Plan mensual"
                  ? colors.plan
                  : colors.individual,
            title: service.tipoServicio,
            id: service.id,
            obj: service,
          };
          events_calendar.push(event);
        }
      }
      this.servicios = _.orderBy(this.servicios, ["fecha"], ["desc"]);
      this.source = new LocalDataSource(this.servicios);
      const events_diasBloquedos = await this.getDiasbloqueados();
      const events_horarioNoDisponible = await this.validarHorarioCalendar();
      this.calendarEvents = events_calendar.concat(events_diasBloquedos).concat(events_horarioNoDisponible);
    })
  }
  arrayFestivos = festivosColombia;
  isHolyDayCalendar(day: any) {
    const date = day.date;
    if (this.arrayFestivos.some(res=> res.fecha == moment(date).format("YYYY-MM-DD"))) {
      return 'holiday-cal-number';
    }else{
      return 'cal-day-number';
    }
  }

  validarHorarioCalendar() {
    return new Promise(resolve=>{
  
      let events = [];
      const daysOfWeek: { [key: number]: Weekday } = {
        0: RRule.SU,
        1: RRule.MO,
        2: RRule.TU,
        3: RRule.WE,
        4: RRule.TH,
        5: RRule.FR,
        6: RRule.SA
      };
      const selectedDays = this.server.horario.filter(res=> res.start_time == "")
      console.log(selectedDays)
      if(selectedDays.length > 0){
        const rule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: selectedDays.map(day => daysOfWeek[day.day]),
          dtstart: new Date(),
          until: new Date(moment().add(3,'months').format('YYYY-MM-DD'))
        });
        events = rule.all().map(date => ({
          start: new Date(date),
          title: 'Horario no disponible',
          obj: {fecha: moment(date).format('YYYY-MM-DD'), tipoServicio: "Horario no disponible" },
          color: colors.secondary
        }));
      }
      resolve(events);
    })
  }

  public planesRecurrentes: any = [];
  public getPlanesRecurrentesAsociados(id) {
    let planesRec: any = [];
    this.ServicioService.getPlanRecurrenteIdServer(id).then((res) => {
      res.docs.forEach((data) => {
        let planRec = data.data();
        planesRec.push(planRec);
        this.planesRecurrentes = planesRec;
      });
    });
  }
  public getDiasbloqueados() {
    return new Promise(resolve=>{
      let events = [];
      this.ServerService.getDisponibilidadUnaVes(this.id_server).then(async (value) => {
        if (value.exists) {
          this.blockedDates = value.data().fechas;
          for(let i = 0;i<this.blockedDates.length;i++){
            let date = moment(this.blockedDates[i].fecha);
            const evento = {
              start: date,
              end: date,
              color: colors.bloqueado,
              title: "Bloqueado",
              date: this.blockedDates[i].fecha,
              obj: {tipoServicio: "Dia bloqueado", horasDeServcio: 8 },
            };
            events.push(evento)
          }
        }
      return resolve(events);
      });
    })
  }

  public getPlanesMensuales(id) {
    this.planService.getPlanByServer(id).then(async (value) => {
      if (value.docs.length > 0) {
        const planes = await value.docs.map(res=>{return res.data()})
        this.planesMensualesActivos = _.orderBy(planes, ["fechaCreacion"], ["desc"]);
      } else {
        this.planesMensualesActivos = [];
      }
    });
  }

  public calculateAge() {
    const age = Math.abs(moment().diff(moment(this.server.fechaNacimiento),'years'))
    return age
  }

  async getAllFiles(){
    this.generalLoader = true;
    let files = []
    const query = (await this.ServerService.getAllFilesServer(this.id_server));
    for(let i = 0;i< query.items.length;i++){
      let newFile = {
        name: query.items[i].name,
        url: (await query.items[i].getDownloadURL()),
        file_path: '',
        date_created: (await query.items[i].getMetadata()).timeCreated,
      }
      console.log(newFile);
      files.push(newFile)
    }
    this.sourceFilesServer = files;
    this.generalLoader = false;
  }

  async traesPagosServer() {
    console.log("Traer pagos...");
    let  info_query = [];
    const query = await this.pagosServerService.getComprobantePorIdServer(this.id_server)
    console.log(query.size)
    for(let i = 0;i<query.size;i++){
      info_query.push(query.docs[i].data());
    }
    console.log(info_query)
    this.sourcePagosServer = _.orderBy(info_query,["fechaFin"],["desc"])
  }

  getProductos() {
    this.ServerService.getProductosServers(this.id_server).then((res) => {
      if (res.docs.length > 0) {
        res.docs.forEach((data) => {
          let info = data.data();
          this.dataProductosServer = info.productos;
        });
      }
    });
  }

  onSelectFileForUpload(file){
    this.fileSelectedForUpload = file.target.files[0];
    console.log(this.fileSelectedForUpload);
  }

  async uploadFileSegurudadSocial() {
    return new Promise(resolve =>{
      if(this.fileSelectedForUpload != null){
        const filePath = `Servers/${this.id_server}/SeguridadSocial`;
        firebase.storage().ref(filePath).put(this.fileSelectedForUpload).then(async res=>{
          const url= await firebase.storage().ref(filePath).getDownloadURL()
          resolve(url);
        })
      }else{
        resolve("")
      }
    })
  }

  async onSaveSeguridadSocial() {
    this.generalLoader = true;
    this.server.UrlSeguridadSocial = await this.uploadFileSegurudadSocial();
    console.log(this.server.UrlSeguridadSocial);
    if (this.server.seguridasSocialHistorial != undefined) {
      this.archivosSeguridadSocial = this.server.seguridasSocialHistorial;
      this.archivosSeguridadSocial.push(this.server.UrlSeguridadSocial);
      this.server.seguridasSocialHistorial = this.archivosSeguridadSocial;
    } else {
      this.archivosSeguridadSocial.push(this.server.UrlSeguridadSocial);
      this.server.seguridasSocialHistorial = this.archivosSeguridadSocial;
    }

      if ( this.server.UrlSeguridadSocial != undefined && this.server.UrlSeguridadSocial != "") {
        this.server.estadoSeguridadSocial = 'aprobada'
        this.server.fechaDeAprobacionSeguridadSocial = moment().format('YYYY-MM-DD');
        this.server.fechaVencimientoSeguridadSocial = moment().add(1,'month').format('YYYY-MM-DD');
        this.ServerService.updateServ(this.server);
        Swal.fire({
          title: "Se ha actualizado correctamente la seguridad social del SERV",
          type: "success",
        });
        this.fileSelectedForUpload = null;
        this.viewEditSeguridadSocial = false;
        this.generalLoader = false;
      } else {
        this.generalLoader = false;
        Swal.fire({
          title: "Espera, selecciona un archivo promero",
          type: "warning",
        });
      }
  }

  async onSaveContrato() {
    this.generalLoader = true;
    this.newFile.name = 'contrato';
    await this.onUploadFile();
    this.server.fechaInicioContrato = moment().format('YYYY-MM-DD');
    this.server.fechaBonoBuenServicio =  moment().format('YYYY-MM-DD');
    this.ServerService.updateServ(this.server);
    Swal.fire({
      title: "Se ha actualizado correctamente la seguridad social del SERV",
      type: "success",
    });
    this.fileSelectedForUpload = null;
    this.viewEditContrato = false;
    this.generalLoader = false;
  }

  viewContrato(){
    const path = `Servers/${this.server.id}/contrato`
    this.storageService.getUrl(path).then(url=>{
      this.lookFile(url);
    })
  }

  onUploadFile(){
    if(this.newFile.name != '' &&  this.fileSelectedForUpload != null){
      this.generalLoader = true;
      this.newFile.file_path = this.newFile.name.toLowerCase().replace(/\s+/g, '_');
      console.log( this.newFile.file_path);
      const filePath = `Servers/${this.id_server}/${this.newFile.file_path}`;
      firebase.storage().ref(filePath).put(this.fileSelectedForUpload).then(async res=>{
        Swal.fire('Listo','El archivo fue subido correctamente','success');
        this.generalLoader = false;
      })
    }else{
      Swal.fire("Espera","Elige un archivo e indica su nombre para poder subirlo",'warning');
    }
  }

  // async saveFile(){
  //   try{

  //     if(this.sourceFilesServer == null){
  //       const query = await this.ServerService.getFilesServer(this.id_server)
  //       console.log(query)
  //       const files = query.data().files;
  //       files.push(this.newFile);
  //       await query.ref.update({files: files});
  //     }else{
  //       this.sourceFilesServer.push(this.newFile);
  //       await this.ServerService.updateFilesServer(this.id_server,this.sourceFilesServer)
  //     }

  //     Swal.fire('Listo','El archivo fue subido correctamente','success');
  //     this.viewAddFile = false
  //     this.newFile.name = '';
  //     this.fileSelectedForUpload = null;
  //   }catch(error){
  //     console.log('Erro al guardar el archivo',error)
  //     Swal.fire('Error','Ubo un erro al guradar el archivo','warning');
  //   }
  //   this.generalLoader = false;
  // }

  guardarArchivos() {
    let updateServer: any = this.server;
    console.log("los datos que esta guardadno", updateServer);
    this.ServerService.updateServ(updateServer);
    Swal.fire({
      title: "Se ha agregado el archivo correctamente!",
      type: "success",
    });
  }

  onActionsTable(event){
    console.log(event)
    switch (event.action) {
      case 'look_file': {
        this.lookFile(event.data.url);
        break;
      }
    }
  }

  lookFile(url) {
    this.file_to_look = url;
    window.open(url, '_blank');
  }

  /* ///////// CALNDARIO ///////////// */
  refresh: Subject<any> = new Subject();
  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      if (day.date.getTime() == this.selectedDays) {
        day.cssClass = "cal-day-selected";
      }
    });
  }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    this.selectedDays = date.getTime();
  
    let servicioSDelDia: any = [];
    let contador = 0;
    if(events.length == 0){
      this.unlock_active_day_button = false;
      this.serviciosDiarios = []
    }else{
      events.forEach((element) => {
        element.obj.nombreCliente = element.obj.client ? `${element.obj.client.name} ${element.obj.client.apellido}` : 'Día bloqueado';
        console.log(element)
        if(element.title == "Bloqueado") this.unlock_active_day_button = true;
        else this.unlock_active_day_button = false;
        servicioSDelDia.push(element.obj);
        contador++;
        if (contador == events.length) {
          setTimeout(() => {
            this.serviciosDiarios = servicioSDelDia;
            this.verServcioscalendario = true;
            console.log(this.serviciosDiarios);
          }, 100);
        }
      });
    }
    this.refreshView();
  }

  async blockDay(){
    console.log(this.selectedDays)
    const date = this.selectedDays;
    this.blockedDates.push({
      fecha: moment(date).format('YYYY-MM-DD'),
    });

    console.log(this.blockedDates)
  
    const evento = {
      start: moment(date),
      end: moment(date),
      color: colors.bloqueado,
      title: "Bloqueado",
      obj: [],
    };
    await this.ServerService.actualizarDiasBloqueados(this.id_server,this.blockedDates)
    Swal.fire("Éxito", "Dia bloqueado", "success");
    this.calendarEvents.push(evento);
    this.unlock_active_day_button = true;
    this.refreshView();
  }
  async unlockDay(){
    console.log(this.selectedDays)
    const date = this.selectedDays;
    const dates = this.blockedDates.filter(res=> res.fecha !== moment(date).format('YYYY-MM-DD'));
    const index_event = this.calendarEvents.findIndex(res=>res.date == moment(date).format('YYYY-MM-DD'))
    this.calendarEvents.splice( index_event,1)
    this.blockedDates = dates;
    await this.ServerService.actualizarDiasBloqueados(this.id_server,dates)
    Swal.fire("Éxito", "Dia desbloqueado", "success");
    this.unlock_active_day_button = false;
    this.refreshView();
  }
  
  refreshView(): void {
    this.refresh.next();
  }

/* Generar PDFs */

  public fechaCer() {

    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const daysNames = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const interval = setInterval(() => {
      const local = new Date();
      const day = local.getDay();
      const num = local.getDate();
      const month = local.getMonth();
      const year = local.getFullYear();

      this.fechaCert = `${daysNames[day]} ${num} ${monthNames[month]} ${year}`;

      if (month > 6) {
        this.fechaFin = ` ${num} ${monthNames[month - 6]} ${year + 1}`;
      } else {
        this.fechaFin = ` ${num} ${monthNames[month + 6]} ${year}`;
      }
    }, 1000);
  }

  async openContrato() {
    const b = await this.fechaCer()
    console.log("este es el usuario", this.server);

    pdfFonts.pdfMake.vfs["italiano_b64"] = italianno;

    pdfMake.fonts = {
      Italianno: {
        normal: "italiano_b64",
        bold: "italiano_b64",
        italics: "italiano_b64",
        bolditalics: "italiano_b64",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
    };

    const documentDefinition = {
      pageSize: {
        width: 1100,
        height: 1400,
        // width: 550,
        // height: 700,
      },
      background: [
        {
          image: "fondo",
          width: 1100,
          height: 1400,
        },
      ],
      content: [
        {
          text: "Bogotá D.C " + this.fechaCert,
          style: "fecha",
        },
        {
          text: "SEÑORES SERV SAS",
          style: "texto",
        },
        {
          text: "RE: APORTES AL SISTEMA DE SEGURIDAD SOCIAL COMO INDEPENDIENTE.",
          style: "texto2",
        },
        {
          text:
            "Yo " +
            this.server.name +
            " " +
            this.server.apellido +
            " identificada con cédula numero de  " +
            this.server.cedula +
            " expedida en  " +
            this.server.cities +
            " en calidad de trabajadora independiente solicitó a SERV SAS abrir a mi favor una cuenta de ahorro programado para el pago de mi seguridad social de conformidad con lo establecido en las leyes 789 de 2002 y 828 de 2003, y demás normas aplicables. ",
          style: "texto3",
        },
        {
          text: "En consecuencia, solicito y autorizo a SERV SAS, a deducir de los pagos causados en el  contrato de asociación por participación la suma de _______ con una periodicidad de semanal. ",
          style: "texto3",
        },
        {
          text: "Atentamente",
          style: "Atentamente",
        },
        {
          text: this.server.name + " " + this.server.apellido,
          style: "firma",
        },
        {
          text: "__________________________________________",
          style: "linea",
        },
        {
          text: "Nombre: " + this.server.name + " " + this.server.apellido,
          style: "nombre",
        },
        {
          text: "C.C: " + this.server.cedula,
          style: "cedula",
        },
      ],
      images: {
        fondo: "https://i.ibb.co/87CB9NS/fondo-Aporte-Segurida.png",
      },
      styles: {
        defaultStyle: {
          font: "Roboto",
          fontSize: 12,
        },
        fecha: {
          fontSize: 12,
          color: "#575757",
          bold: false,
          margin: [0, 75, 0, 70],
        },
        texto: {
          fontSize: 12,
          color: "#575757",
          bold: false,
          margin: [0, 0, 0, 40],
        },
        texto2: {
          fontSize: 12,
          color: "#000",
          bold: true,
          margin: [0, 0, 0, 30],
        },
        texto3: {
          fontSize: 12,
          color: "#575757",
          bold: false,
          margin: [0, 0, 0, 10],
          alignment: "justify",
        },
        Atentamente: {
          fontSize: 12,
          color: "#575757",
          bold: false,
          margin: [0, 45, 0, 20],
        },
        cedula: {
          fontSize: 15,
          bold: true,
          margin: [0, 0, 0, 0],
          color: "#000",
        },
        firma: {
          fontSize: 30,
          margin: [0, 0, 0, 2],
          color: "#000",
          font: "Italianno",
          bold: true,
        },
        nombre: {
          fontSize: 15,
          bold: true,
          margin: [0, 0, 0, 5],
          color: "#000",
        },
        apellido: {
          fontSize: 30,
          color: "#16304F",
          bold: true,
          margin: [0, 0, 0, 0],
          alignment: "center",
        },
        curso: {
          fontSize: 25,
          color: "#16254F",
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: "center",
        },
        linea: {
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 15],
          color: "#000",
        },
      },
    };

    pdfMake.createPdf(documentDefinition);
    pdfMake.createPdf(documentDefinition).open();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      console.log(blob);
      const b = blob;
      console.log(b, "y lo otro", documentDefinition);
    });
  }

  async OpenTerminos() {
    console.log("este es el usuario", this.server);
    pdfFonts.pdfMake.vfs["italiano_b64"] = italianno;

    pdfMake.fonts = {
      Italianno: {
        normal: "italiano_b64",
        bold: "italiano_b64",
        italics: "italiano_b64",
        bolditalics: "italiano_b64",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
    };

    const documentDefinition = {
      pageSize: {
        width: 500,
        height: 670,
        pages: 6, //8
      },
      background: [
        {
          image: "fondo",
          width: 500,
          height: 670,
        },
      ],
      content: [
        {
          text: "CONTRATO DE ASOCIACIÓN POR PARTICIPACIÓN",
          style: "titulo",
        },
        {
          style: "texto",

          text: [
            "Los suscritos a saber, de una parte, ",
            { text: "SERV SAS", style: "negrilla" },
            ", sociedad domiciliada en Bogotá D.C., con NIT 901169432-3 representada legalmente por ",
            { text: " MICHAEL HEREIRA SALCEDO", style: "negrilla" },
            ", identificado con la con la cédula de ciudadanía Nº 1’020.804.347 expedida en ",
            { text: "Bogotá D.C", style: "negrilla" },
            " En adelante",
            { text: "LA EMPRESA", style: "negrilla" },
            "y, de otra parte, " +
            this.server.name +
            " " +
            this.server.apellido +
            " identificado con la cédula de ciudadanía N. " +
            this.server.cedula +
            ", expedida en " +
            this.server.cities +
            " domiciliado en la ciudad de Bogotá, en adelante ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER", style: "negrilla" },
            " acuerdan celebrar el presente",
            {
              text: "CONTRATO DE ASOCIACIÓN POR PARTICIPACIÓN",
              style: "negrilla",
            },
            " de conformidad con las siguientes consideraciones y cláusulas.",
          ],
        },
        {
          text: "I. CONSIDERACIONES Y ANTECEDENTES ",
          style: "subtitulo",
        },
        {
          style: "texto",
          text: [
            "Las consideraciones y antecedentes que rodean la celebración del ",
            { text: "CONTRATO", style: "negrilla" },
            " son las que a continuación se detallan. Ellas revelan de manera expresa los móviles o motivos determinantes que han inducido a las ",
            {
              text: "PARTES (LA EMPRESA Y EL PROFESIONAL DE LIMPIEZA- SERVER)",
              style: "negrilla",
            },
            " a contratar y su manifestación explícita tiene como finalidad que sean tenidas en cuenta, siempre que se trate de aplicar o interpretar alguna cláusula, regla o estipulación del ",
            { text: "CONTRATO", style: "negrilla" },
            " o de solucionar alguna diferencia entre las ",
            { text: "PARTES", style: "negrilla" },
            " o de fijar o determinar derechos u obligaciones por razón de las relaciones que el presente documento regula entre ellas:",
          ],
        },
        {
          style: "texto",
          text: [
            "A.",
            { text: "SERV SAS", style: "negrilla" },
            " es una sociedad comercial cuyo objeto principal consiste en prestar servicios profesionales de aseo integral para cualquier tipo de inmueble residencial y/o comercial, a través de personal seleccionado, entrenado y contratado directa y exclusivamente por ",
            { text: "LA EMPRESA", style: "negrilla" },
          ],
        },
        {
          style: "texto",
          text: [
            "B. Para la ejecución de su objeto, ",
            { text: "SERV SAS", style: "negrilla" },
            "celebra órdenes de servicios con empresas, clientes y/o personas naturales para servicios en su residencia u oficinas previa aceptación de los términos y condiciones de ",
            {
              text: "SERV SAS (En adelante: LOS CLIENTES),",
              style: "negrilla",
            },
            " con el fin de garantizar los derechos y obligaciones de cada parte dentro de la relación comercial, así como las respectivas garantías de cumplimiento y demás aspectos propios de cada negociación.",
          ],
        },
        {
          style: "texto",
          text: [
            "D. Dicha relación comercial, se ampara en la modalidad de asociación por participación, donde las partes aúnan esfuerzos técnicos, físicos, tecnológicos, económicos y logísticos para realizar un servicio remunerado y en consecuencia, las partes acuerdan un porcentaje de la utilidad de dicho servicio.",
          ],
        },
        {
          style: "texto2",
          text: [
            "\n\n\n\nE. ",
            {
              text: "El PROFESIONAL DE LIMPIEZA- SERVER," + this.server.name,
              style: "negrilla",
            },
            " es una persona natural, de profesión aseador quien se dedica a la prestación de servicios profesionales relacionados con el área de la limpieza. ",
            { text: "El PROFESIONAL DE LIMPIEZA-SERVER", style: "negrilla" },
            " acepta a ",
            { text: "SERV SAS", style: "negrilla" },
            " como su socio y representante y concede la potestad para utilizar su imagen para fines promocionales",
          ],
        },
        {
          style: "texto",
          text: [
            "F. ",
            { text: "El PROFESIONAL DE LIMPIEZA- SERVER,", style: "negrilla" },
            " acepta no prestar el mismo tipo de servicios, objeto del presente contrato, ni dentro ni fuera del país, a otras empresas que ofrezcan los mismos servicios y productos que ofrece",
            { text: " LA EMPRESA.", style: "negrilla" },
          ],
        },
        {
          style: "texto",
          text: [
            "G.",
            { text: "El PROFESIONAL DE LIMPIEZA- SERVER,", style: "negrilla" },
            "acepta los términos y condiciones de la asociación por participación las cuales se describen a continuación: ",
          ],
        },
        {
          style: "texto",
          type: "lower-alpha",
          ol: [
            "Solicitud de asociación a la EMPRESA",
            "Verificación de antecedentes",
            "Certificación de experiencia",
            "Adquisición por su cuenta del KITS de trabajo",
            [
              "Aceptación expresa de la sociedad por participación de acuerdo con su fuerza de trabajo independiente y disposición para ejecutar los servicios remunerados que comercializa",
              { text: "LA EMPRESA", style: "negrilla" },
            ],
          ],
        },
        {
          text: "II. CLÁUSULAS CONTRACTUALES: ",
          style: "subtitulo",
        },
        {
          style: "texto",
          text: [
            "Con base en los anteriores antecedentes y consideraciones las ",
            { text: "PARTES", style: "negrilla" },
            " suscriben el ",
            { text: "CONTRATO", style: "negrilla" },
            " de asociación por participación, que se regirá por las siguientes cláusulas y las disposiciones legales sobre la materia: ",
          ],
        },
        {
          style: "texto3",
          text: [
            {
              text: "CLÁUSULA PRIMERA. OBJETO. EL PROFESIONAL DE LIMPIEZA- SERVER",
              style: "negrilla",
            },
            " de manera independiente –es decir, sin que exista subordinación – y utilizando sus propios         medios, prestará sus servicios profesionales y ejecutará las siguientes actividades contratadas por el cliente: ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "1. ASEO INTEGRAL DE LOS DIFERENTES AMBIENTES DE LOS INMUEBLES: El PROFESIONAL DE LIMPIEZA- SERVER",
              style: "negrilla",
            },
            " realizará: aseo de camas (cambio de lencería, tendido), limpieza de pisos( barrido, trapeado, aspirada), limpieza de polvo, lavado profundo de baños, limpieza profunda de cocina( lavado de loza, limpieza de electrodomésticos y elementos de cocina), limpieza de superficies ( cuando los mismo no impliquen trabajo en alturas); Las tareas se realizarán haciendo uso de los insumos e instrumentos de aseo entregados por el",
            { text: "CLIENTE", style: "negrilla" },
            "( ya sean propios o adquiridos por medio de la ",
            { text: "PLATAFORMA", style: "negrilla" },
            ") y teniendo en cuenta las recomendaciones y los cuidados que el",
            { text: "CLIENTE", style: "negrilla" },
            " indique al inicio del servicio (sin que signifique subordinación laboral). ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "\n\n\n\n2. LAVADO DE ROPA: El PROFESIONAL DE LIMPIEZA- SERVER",
              style: "negrilla",
            },
            "realizará: lavado de ropa a mano o en lavadora / secadora (cuando se cuenten con las mismas, siempre y cuando sea un Servicio adquirido y autorizado por el CLIENTE), haciendo uso de los elementos de aseo (jabón, suavizante, etc.), que para el efecto ponga a disposición el CLIENTE (ya sean propios o adquiridos por medio de la PLATAFORMA) y en virtud de lo señalado por el mismo al inicio del servicio.",
          ],
        },
        {
          style: "texto",
          text: [
            "3.",
            {
              text: "PLANCHADO DE ROPA: El PROFESIONAL DE LIMPIEZA-SERVER",
              style: "negrilla",
            },
            "realizará: planchado de ropa, haciendo uso del instrumento (plancha regular o plancha de vapor) que posea el ",
            { text: "CLIENTE", style: "negrilla" },
            " previa adquisición y autorización del mismo;",
            { text: "El CLIENTE", style: "negrilla" },
            "deberá informar al ",
            { text: "PROFESIONAL DE LIMPIEZA – SERVER", style: "negrilla" },
            " los cuidados especiales que deban tenerse con las prendas y con los instrumentos que deban usarse para la misma, adicionalmente el ",
            { text: "CLIENTE", style: "negrilla" },
            " deberá contar con una plataforma idónea (mesa de planchar o semejantes), para la realización de la tarea, de lo contrario el",
            { text: "PROFESIONAL DE LIMPIEZA-SERVER", style: "negrilla" },
            " estará autorizado para negarse a prestar el servicio.",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA SEGUNDA. DURACIÓN Y TERMINACIÓN. ",
              style: "negrilla",
            },
            "El presente contrato inicia su ejecución el " +
            this.fechaCert +
            " y se celebra por un plazo de Número de meses 6 MESES, transcurridos entre el día " +
            this.fechaFin +
            " de terminación. Podrá darse por terminado por las causales establecidas en el Código Civil, El Código Sustantivo del Trabajo, El ",
            {
              text: "Manual de servicios de la empresa SERV SAS",
              style: "negrilla",
            },
            " y especialmente, por las siguientes: ",
          ],
        },
        {
          style: "texto",
          type: "ordered",
          ol: [
            "Por mutuo acuerdo. ",
            "Por terminación anticipada del servicio por parte de los CLIENTES a LA EMPRESA.",
            "Por vencimiento del plazo. ",
            "En cualquier caso, LA EMPRESA. podrá terminar de manera unilateral el presente contrato en el momento que así lo disponga, sin incurrir en ningún tipo de sanción.",
            "De manera unilateral, cuando una de  Las Partes incumpla una o varias de las obligaciones contenidas en este CONTRATO o en la ley.",
            "Las demás establecidas en la ley.",
          ],
        },
        {
          style: "texto",
          text: [
            "Podrán también ampliar su duración mediante ",
            { text: "Otrosí", style: "negrilla" },
            "suscrito por las partes el cual formará parte del presente contrato.",
          ],
        },
        {
          text: "CLÁUSULA TERCERA. APORTE DE LAS PARTES:",
          style: "subtitulo",
        },
        {
          style: "texto",
          text: [
            { text: "A) POR LA EMPRESA:", style: "negrilla" },
            "a) el control administrativo y contable del negocio b) poner a disposición todas las herramientas tecnológicas y logístico para el desarrollo del negocio c) celebrar los contratos, acuerdos y órdenes de servicios con terceros d) desarrollar las actividades de promoción y comercialización de los servicios que ofrece la EMPRESA. ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "\n\n\n\nB) POR EL PROFESIONAL DE LIMPIEZA – SERVER: ",
              style: "negrilla",
            },
            "Ejecutar los servicios relacionados con",
            { text: "EL NEGOCIO,", style: "negrilla" },
            "en lo sucesivo",
            { text: "“LOS SERVICIOS” ", style: "negrilla" },
            " de acuerdo con los lineamientos, condiciones y obligaciones establecidas en este Contrato y en los Anexos que firmados por",
            { text: "LAS PARTES,", style: "negrilla" },
            " formarán parte integral de éste; utilizando la",
            { text: "PLATAFORMA", style: "negrilla" },
            "puesta a disposición por",
            { text: "LA EMPRESA", style: "negrilla" },
          ],
        },
        {
          style: "texto",
          text: [
            { text: "CLÁUSULA CUARTA PARTICIPACIÓN: ", style: "negrilla" },
            " Por el objeto del presente contrato, ",
            { text: "LA EMPRESA", style: "negrilla" },
            " pagará a ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER ", style: "negrilla" },
            " de la siguiente forma: Por concepto de pagos por participación de acuerdo con la programación individual e independiente de servicios semanales programadas por ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER,", style: "negrilla" },
            " la suma total del valor de la",
            { text: "HORA EJECUTADA", style: "negrilla" },
            " según sea el caso establecido en el parágrafo de la presente cláusula, liquidadas quincenalmente de acuerdo con las horas ejecutadas, teniendo en cuenta, la respectiva Bitácora de servicios presentada, de acuerdo con la programación y cumplimiento de objetivos. Las horas ejecutadas no podrán superar un total de OCHENTA (80) horas quincenales. ",
            { text: "Parágrafo: SERV SAS", style: "negrilla" },
            " no garantiza un mínimo de servicios y en consecuencia no establece un monto mínimo de pagos por concepto de participación quincenal, ya que expresamente se ha acordado con el ",
            { text: "PROFESIONAL DE LIMPIEZA- SERVER", style: "negrilla" },
            " cuáles son los parámetros del pago por hora ejecutada. En tal sentido,",
            { text: "El PROFESIONAL DE LIMPIEZA- SERVER", style: "negrilla" },
            " acepta que no es un empleado de ",
            { text: "SERV SAS,", style: "negrilla" },
            " aun así, el ",
            { text: "PROFESIONAL DE LIMPIEZA -SERVER", style: "negrilla" },
            " utilizará a ",
            { text: "SERV SAS", style: "negrilla" },
            " para representar, promover y comercializar sus servicios.  ",
            { text: "Parágrafo primero: SERV SAS", style: "negrilla" },
            " no le cargará al ",
            { text: "PROFESIONAL DE LIMPIEZA -SERVER", style: "negrilla" },
            "ningún costo por distribución, comercialización, publicidad, etc. para desarrollar su promoción. ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA QUINTA. FORMA DE PAGO: LAS PARTES",
              style: "negrilla",
            },
            " acuerdan que las utilidades serán pagadas a ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER ", style: "negrilla" },
            " semanalmente, los jueves de cada semana, mediante Aplicación Nequi ",
            { text: "Parágrafo:", style: "negrilla" },
            "  Debido a que el ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER ", style: "negrilla" },
            " ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA SEXTA- OBLIGACIONES DE EL PROFESIONAL DE LIMPIEZA-SERVER.",
              style: "negrilla",
            },
            " En desarrollo del presente contrato, las siguientes son obligaciones especiales a su cargo: 1- Obrar con seriedad y diligencia en los asuntos que",
            { text: "LA EMPRESA", style: "negrilla" },
            " le haya encomendado. 2- Mantener una comunicación permanente con ",
            { text: "LA EMPRESA", style: "nerilla" },
            " e informarle detallada y oportunamente sobre la ejecución de los servicios encomendados. 3- Cumplir con el manual de servicios y la política de términos y condiciones de ",
            { text: "SERV S.A.S.", style: "negrilla" },
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA SÉPTIMA. OBLIGACIONES DEL LA EMPRESA",
              style: "negrilla",
            },
            "En desarrollo del presente contrato, las siguientes son obligaciones especiales a cargo de ",
            { text: "LA EMPRESA: 1-", style: "negrilla" },
            " Suministrar oportunamente la información veraz solicitada por ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER", style: "negrilla" },
            " y que esté en capacidad de obtener. 2- Confirmar al ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER", style: "negrilla" },
            " las órdenes de servicios para la ejecución de la labor encomendada. 3- Pagar el porcentaje por concepto de participación de la forma indicada en la cláusula tercera de este contrato. ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "\n\n\n\nCLÁUSULA OCTAVA – INEXISTENCIA PERSONERÍA JURÍDICA:",
              style: "negrilla",
            },
            " De conformidad a lo establecido en el artículo 509 del Código de Comercio “la participación no constituirá una persona jurídica y por tanto carece de nombre, patrimonio social y domicilio. Su formación, modificación, disolución y liquidación podrán ser establecidas con los libros, correspondencia, testigos o cualquier otra prueba legal”. En consecuencia ",
            { text: "LA EMPRESA", style: "negrilla" },
            " actuará en nombre propio en las relaciones comerciales que se originen a propósito del presente contrato. ",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "CLÁUSULA NOVENA: INDEPENDENCIA: ", style: "negrilla" },
            "Si cualquier cláusula, término, convenio, condición, o disposición del presente contrato o la aplicación de este, fuese considerada como inválida o inejecutable, el resto de este contrato o su aplicación, no se verá afectado por lo anterior y cada término, convenio, condición o disposición del presente contrato será válida y será ejecutada hasta la extensión más completa permitida por la ley.",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "CLÁUSULA DÉCIMA: NO RENUNCIA:", style: "negrilla" },
            " La inactividad, falta de queja o acción por alguna de ",
            { text: "LA EMPRESA", style: "negrilla" },
            " en relación con el incumplimiento o cualquier otro procedimiento derivado del presente contrato, no se estimará como una renuncia a sus derechos por permanecer inactiva o no efectúe los procedimientos establecidos en el contrato",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DÉCIMA PRIMERA- LEGISLACIÓN APLICABLE:",
              style: "negrilla",
            },
            " En todo lo no previsto por",
            { text: "LAS PARTES", style: "negrilla" },
            " en el presente contrato, ambas se someten a lo establecido por El Código de Comercio, el Código Sustantivo del Trabajo, el Código Civil y demás leyes correspondientes que resulten aplicables. ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DUODÉCIMA- INFORMACIÓN CONFIDENCIAL: ",
              style: "negrilla",
            },
            "Las partes acuerdan que todos los documentos, datos, registros, informes, notas, estudios, mensajes de datos generados, enviados, recibidos o comunicados por medios electrónicos, y en general toda la información que entregue ",
            { text: "EL LA EMPRESA y/o LOS CLIENTES ", style: "negrilla" },
            "a",
            { text: " EL PROFESIONAL DE LIMPIEZA-SEVER", style: "negrilla" },
            " para el desarrollo de este contrato, o aquella información a que tenga acceso ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER ", style: "negrilla" },
            " con ocasión del contrato o los resultados del mismo, se considera información de carácter ",
            { text: "CONFIDENCIAL", style: "negrilla" },
            " motivo por el cual ",
            { text: "EL PROFESIONAL DE LIMPIEZA- SERVER ", style: "negrilla" },
            " no podrá divulgar esta información a terceros, total o parcialmente, ni utilizarla de manera alguna para propósitos distintos a los consignados en este contrato, salvo que haya una autorización previa y escrita por alguno de los representantes legales de EL ",
            { text: "LA EMPRESA.", style: "negrila" },
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DÉCIMA TERCERA- CESIÓN Y SUBCONTRATACIÓN: EL PROFESIONAL DE LIMPIEZA- SERVER  ",
              style: "negrilla",
            },
            "se obliga a cumplir directamente las obligaciones que contrae por este contrato y no podrá ceder o subcontratar –en todo o en parte – sin la autorización previa y escrita de ",
            { text: "LA EMPRESA.", style: "negrilla" },
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "\n\n\n\n\nCLÁUSULA DÉCIMA CUARTA- INDEPENDENCIA Y AUSENCIA DE RELACIÓN LABORAL: ",
              style: "negrilla",
            },
            " El presente contrato será ejecutado por ",
            { text: " EL PROFESIONAL DE LIMPIEZA-SEVER", style: "negrilla" },
            " con absoluta autonomía e independencia y en desarrollo de este no se generará vínculo laboral alguno entre EL ",
            { text: "LA EMPRESA", style: "negrilla" },
            " y",
            { text: " EL PROFESIONAL DE LIMPIEZA-SEVER", style: "negrilla" },
            ", así como respecto de las demás personas que intervengan en el desarrollo de este contrato, no existe ningún vínculo laboral. En consecuencia,",
            { text: " EL PROFESIONAL DE LIMPIEZA-SEVER", style: "negrilla" },
            " asume toda responsabilidad que le corresponda como trabajador independiente en los aportes a la Seguridad social.",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DÉCIMA QUINTA -CONTROL A LA EVASIÓN AL SISTEMA DE SEGURIDAD SOCIAL Y PAGO PARAFISCALES: ",
              style: "negrilla",
            },
            " De conformidad con lo establecido en las leyes 789 de 2002 y 828 de 2003, y demás normas aplicables,  ",
            { text: "El PROFESIONAL DE LIMPIEZA-SERVER", style: "negrilla" },
            "  deberá cumplir con sus obligaciones frente al sistema de seguridad social integral y parafiscal. Deberá afiliarse de forma inmediata a una administradora de riesgos profesionales y presentar los soportes de dicha afiliación.",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DÉCIMA SEXTA-MODIFICACIÓN Y/O AMPLIACIÓN DEL CONTRATO. CUALQUIER",
              style: "negrilla",
            },
            " modificación y/o ampliación del presente contrato, se acordará entre las partes mediante un escrito anexo u otro sí que formará parte de este.",
          ],
        },
        {
          style: "texto3",
          text: [
            {
              text: "CLÁUSULA DÉCIMA SÉPTIMA- INDEMNIDAD DE LA EMPRESA: El PROFESIONAL DE LA LIMPIEZA-SERVER ",
              style: "negrilla",
            },
            " se obliga con él ",
            { text: "LA EMPRESA", style: "negrilla" },
            " a mantenerlo libre de cualquier daño o perjuicio originado en reclamaciones de terceros y que se deriven de sus actuaciones realizadas durante la ejecución del contrato. ",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DÉCIMA OCTAVA – AUTORIZACIÓN DE TRATAMIENTO DE DATOS EL PROFESIONAL DE LA LIMPIEZA-SERVER ",
              style: "negrilla",
            },
            " en ejercicio de mi derecho a la libertad y autodeterminación informática, ",
            { text: "AUTORIZO", style: "negrilla" },
            " de manera expresa, concreta, suficiente, voluntaria e informada a ",
            { text: "SERV SAS", style: "negrilla" },
            " sociedad domiciliada en Bogotá D.C. con NIT 901169432-3 o a quien represente sus derechos u ostente en el futuro a cualquier título la calidad de acreedor a: consultar, tratar, procesar, operar, verificar, transmitir, transferir, usar, poner en circulación, divulgar, reportar y solicitar toda la información que se refiere a nuestro comportamiento crediticio, financiero, comercial y de servicios de los cuales somos sus titulares, referida al nacimiento, ejecución y extinción de obligaciones dinerarias (independientemente de la naturaleza del contrato que les dé origen) a nuestro comportamiento e historial crediticio, incluida la información positiva y negativa de mis hábitos de pago y aquella que se refiera a la información personal necesaria para el estudio, análisis y eventual otorgamiento de un crédito o celebración de un contrato, para que dicha información sea concernida y reportada en cualquier ",
            { text: "CENTRAL DEL RIESGOS o BASE DE DATOS,", style: "negrilla" },
            " tales como ",
            {
              text: "CENTRAL DE INFORMACIÓN DE ENTIDADES FINANCIERAS DE COLOMBIA ",
              style: "negrilla",
            },
            "o ",
            { text: "PROCRÉDITO", style: "negrilla" },
            " o a quien represente sus derechos u opere dichas entidades. La permanencia de mi información en las bases de datos será determinada por el ordenamiento jurídico aplicable, en especial por las normas legales y la jurisprudencia, los cuales contienen mis derechos y obligaciones, que por ser públicos, conozco plenamente. Así mismo manifiesto que conozco el reglamento de las ",
            { text: "CENTRALES DE RIESGOS.", style: "negrilla" },
            " En caso ",
          ],
        },
        {
          text: "\n\n\n\nde que en el futuro, el autorizado en este documento efectúe una venta de cartera o una cesión de cualquier título de las obligaciones a mi cargo a favor de un tercero, los efectos de la presente autorización se extenderán a éste, en los mismos términos y condiciones. Ley 1581 de 2012.",
          style: "texto",
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA DÉCIMA NOVENA- CONTROVERSIAS:",
              style: "negrilla",
            },
            " Toda diferencia que surja entre el ",
            { text: "PROFESIONAL DE LIMPIEZA-SERVER", style: "negrilla" },
            " y ",
            { text: "EL LA EMPRESA", style: "negrilla" },
            " por la interpretación del presente contrato, su ejecución, su cumplimiento, su terminación o las consecuencias futuras del mismo, no pudiendo arreglar amigablemente entre las partes, será sometida a la decisión de uno de los Centros de Conciliación y Arbitraje de la ciudad de Bogotá y se someterá a sus Estatutos. El fallo pronunciado por el Conciliador y/o los árbitros será dictado en derecho y los gastos que ocasionaren correrán por cuenta de la parte vencida.",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA VIGÉSIMA- DIRECCIONES DE NOTIFICACIÓN.",
              style: "negrilla",
            },
            " Todas las notificaciones y comunicaciones que una ",
            { text: "PARTE", style: "negrilla" },
            " haya de hacer a la otra de acuerdo con ",
            { text: "EL CONTRATO", style: "negrilla" },
            " sus prestaciones y ejecución, se harán por escrito y se entenderán recibidas si se entregan personalmente con constancia de recibo, o si son enviadas por correo certificado, o correo electrónico dirigidos a las siguientes direcciones:",
          ],
        },
        {
          style: "texto",
          text: [
            { text: "LA EMPRESA: ", style: "negrilla" },
            "SERV SAS \nDirección: CRA 13 A # 34 - 57 PISO 2 \n Teléfono: 3157863023 \n Correo Electrónico: servicioalcliente@servpremium.com",
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: this.server.name + " " + this.server.apellido,
              style: "negrilla",
            },
            " \nDirección: " +
            this.server.direccionResidencia +
            " \n Teléfono: " +
            this.server.telefono +
            " \n Correo Electrónico: " +
            this.server.email,
          ],
        },
        {
          style: "texto",
          text: [
            {
              text: "CLÁUSULA VIGÉSIMA PRIMERA- ACUERDO ÚNICO:",
              style: "negrilla",
            },
            " Las consideraciones y cláusulas contenidas en el presente contrato deja sin efecto cualquier acuerdo verbal o escrito convenido con anterioridad. Por lo que el presente contrato refleja las condiciones y términos en que ",
            { text: "LAS PARTES", style: "negrilla" },
            " desean obligarse.",
          ],
        },
        {
          style: "texto",
          text: [
            "YO " +
            this.server.name +
            " " +
            this.server.apellido +
            " de manera expresa e informada certifico que conozco las condiciones y cláusulas del presente contrato, que es mi deseo y voluntad aunar esfuerzos técnicos, tecnológicos, físicos, económicos y logísticos para ejecutar los servicios que ofrece la ",
            { text: "EMPRESA", style: "negrilla" },
            " y obtener por ello una participación del valor del servicio ejecutado. Entiendo que las utilidades y ganancias serán conforme a los porcentajes establecidos para cada servicio pagado y que esta relación comercial podrá terminarse de manera unilateral por algunas de las partes.",
          ],
        },
        {
          text:
            "\n\n\n\nEn señal de asentimiento, las partes suscriben el presente documento en dos ejemplares del mismo tenor en la ciudad de Bogotá el " +
            this.fechaCert,
          style: "texto",
        },
        {
          aligment: "justify",
          columns: [
            [
              {
                image: "firma",
              },
              {
                text: "__________________________________________",
                style: "linea",
              },
              {
                text: "Nombre: \n MICHAEL HEREIRA SALCEDO",
                style: "nombre",
              },
              {
                text: "REPRESENTANTE LEGAL SERV SAS",
                style: "nombre",
              },
              {
                text: "C.C:1020804347",
                style: "cedula",
              },
            ],
            [
              {
                // text: this.server.name + " " + this.server.apellido,
                text: '',
                style: "firma",
              },
              {
                text: "__________________________________________",
                style: "linea",
              },
              {
                text:
                  "Nombre: \n " + this.server.name + " " + this.server.apellido,
                style: "nombre",
              },
              {
                text: "Contratista",
                style: "nombre",
              },
              {
                text: "C.C:" + this.server.cedula,
                style: "cedula",
              },
            ],
          ],
        },
      ],
      images: {
        fondo: "https://i.ibb.co/3r3zzT7/fondo-Contrato.png",
        firma: "https://i.ibb.co/whsqcrg/Firma-Mike.png",
      },
      styles: {
        titulo: {
          fontSize: 14,
          color: "#000",
          bold: true,
          margin: [0, 60, 0, 20],
          alignment: "center",
        },

        subtitulo: {
          fontSize: 14,
          color: "#000",
          bold: true,
          margin: [0, 0, 0, 20],
        },
        texto: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 0, 0, 20],
          alignment: "justify",
        },
        texto2: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 10, 0, 20],
          alignment: "justify",
        },
        texto3: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 0, 0, 5],
          alignment: "justify",
        },
        negrilla: {
          fontSize: 10,
          color: "#000",
          bold: true,
        },
        Atentamente: {
          fontSize: 10,
          color: "#575757",
          bold: false,
          margin: [0, 70, 0, 20],
        },
        cedula: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 0],
          color: "#000",
        },
        firma: {
          fontSize: 30,
          margin: [0, 0, 0, 2],
          color: "#000",
          font: "Italianno",
          bold: true,
        },
        nombre: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 5],
          color: "#000",
        },
        apellido: {
          fontSize: 30,
          color: "#16304F",
          bold: true,
          margin: [0, 0, 0, 0],
          alignment: "center",
        },
        curso: {
          fontSize: 25,
          color: "#16254F",
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: "center",
        },
        linea: {
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 15],
          color: "#000",
        },
      },
    };

    pdfMake.createPdf(documentDefinition);
    pdfMake.createPdf(documentDefinition).open();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      console.log(blob);
      const b = blob;
      console.log(b, "y lo otro", documentDefinition);
    });
  }

  validaPdf() {
    console.log('estos son los servicios de la validacion', this.server);
    // this.ServicioService.getServiciosServerTerminado(this.server.id).then(value => {
    //   let cont = value.docs.length;
    //   this.server.serviciosTerminados = cont;
    //   console.log("estos son el numero de servicos Terminados", this.server.serviciosTerminados)
    // });


    if (this.server.foto == undefined || this.server.foto == "") {
      this.campos.push("foto,");
    }

    if (this.server.name == undefined || this.server.name == "") {
      this.campos.push("nombre,");
    }

    if (this.server.experiencia == undefined || this.server.experiencia.length == 0) {
      this.campos.push("experiencia,");
    }

    if (this.server.estudios == undefined || this.server.estudios.length == 0) {
      this.campos.push("estudios,");
    }

    if (this.server.apellido == undefined || this.server.apellido == "") {
      this.campos.push("apellido,");
    }

    if (this.server.department == undefined || this.server.department == "") {
      this.campos.push("departamento,");
    }

    if (this.server.cities == undefined || this.server.cities == "") {
      this.campos.push("ciudad ,");
    }

    if (this.server.frase == undefined || this.server.frase == "") {
      this.campos.push("frase,");
    }

    if (this.campos.length == 0 || this.campos == undefined) {
      this.openHojaVida();
      Swal.fire("! Felicidades ¡", "Generaste tu PDF con exito", "success");
    } else {
      Swal.fire(
        "¡ Lo sentimos !,Son ncesarios los siguentes campos para poder general el pdf",
        "'" + this.campos + "'",
        "warning"
      );
    }
    console.log("este es el arreglo de campos", this.campos);
  }
  
  writeRotatedText = async function(text,height,maxFontSize) {
    return new Promise(async resolve =>{
      let fontSize = maxFontSize;
      let fontFamily = 'airbnb';
      if(maxFontSize == 120){
        fontSize = await this.calculateFontSize(text,height,maxFontSize)
        fontFamily = 'Jazmin Alt'
      }
      // const fontSize = maxFontSize;
      let ctx, canvas = document.createElement('canvas');
      canvas.width = (fontSize) + (fontSize/3);
      canvas.height = height;
      ctx = canvas.getContext('2d');
      // ${fontFamily}
      ctx.font = `600 ${fontSize}px '${fontFamily}'`;
      console.log(ctx)
      ctx.save();
      ctx.translate(fontSize, height); 
      ctx.rotate(-0.5 * Math.PI);
      ctx.fillStyle = '#fff'; 
      const textWidth = ctx.measureText(text).width;
      const x = (canvas.height - textWidth) / 2;
      // console.log(canvas.width, textWidth);
      ctx.fillText(text, x , 0);
      ctx.restore();
      resolve(canvas.toDataURL());
    })
  };

  calculateFontSize(text,width,maxFontSize){
    return new Promise(async resolve => {
      const textLengh = text.length;
      const extraCaracter = textLengh - 15;
      if(extraCaracter <= 0) return resolve(120);
      const a = await this.logisticFunction(extraCaracter)
      let fontSize = maxFontSize - a;
      fontSize = Math.min((fontSize), maxFontSize);
      return resolve(fontSize)
    })
  }
  logisticFunction(x) {
    return  Math.sqrt(x / 42) * 100
  }



  async generatePdfProfile(){
    try{
      const serviciosTerminados = this.server.serviciosTerminados.toString();
      console.log(serviciosTerminados);
      pdfFonts.pdfMake.vfs["AirbnbCereal_W_Bd"] = AirbnbCereal_W_Bd;
      pdfFonts.pdfMake.vfs["airbnb_b64"] = airbnb;
      pdfFonts.pdfMake.vfs["jazmin_alt"] = JazminAltBoldEncoded;
      pdfMake.fonts = {
        airbnb_b64: {
          normal: "airbnb_b64",
          bold: "AirbnbCereal_W_Bd",
          italics: "airbnb_b64",
          bolditalics: "airbnb_b64",
        },
        jazmin_alt: {
          normal: 'jazmin_alt',
          bold: "jazmin_alt",
          italics: "jazmin_alt",
          bolditalics: "jazmin_alt",
        },
      }
      const documentDefinition: any = {
        pageSize: {
          width: 1416,
          height: 2004,
        },
        background: [
          {
            image: "background",
            width: 1416,
            height: 2004,
          },
        ],
        // ${this.server.name} ${this.server.apellido}
        content: [
          {
            image: await this.writeRotatedText(` ${this.server.name} ${this.server.apellido}`,853,120),
            // alignment: 'left',
            absolutePosition: { x: 76, y: 76 },
          },
          {
            image: await this.writeRotatedText(`Servicios generales`,853,40),
            // alignment: 'center',
            absolutePosition: { x: 220, y: 76 },
          },
          //Foto de perfil
          {
            image: 'fotoDePerfil',
            // text:'foto',
            width: 794,
            // height: 794,
            fit:[794,794],
            absolutePosition: { x: 485, y: 100 } //485 100
          },
          // #Servicios Terminados
          // {
          //   image: 'pin',
          //   absolutePosition: { x: 1416-213, y: 70 },
          // },
          // {
          //   absolutePosition: { x: 1183, y: 82  },
          //   //Mantener con el enter para que en el pdf se vea con el salto de linea
          //   text: `Total 
          //         Servicios`,
          //   style: 'textServicios'
          // },
          // {
          //   absolutePosition: { x: 1190, y: 148 },
          //   stack: [
          //     {
          //       text: `${this.server.serviciosTerminados}`,
          //       style: 'textNumServicios',
          //     }
          //   ]
          // },

          {
            absolutePosition: { x: 122, y: 1250 },
            text: [
              //Mantener con el enter para que en el pdf se vea con el salto de linea, ya que \n genera mucho espacio
              { text: 'Nacionalidad: ', style:'info2_bold'},
              { text: ` ${this.server.nacionalidad} \n`, style:'info2'},
              { text: 'Fecha nacimiento: ', style:'info2_bold'},
              { text: ` ${this.server.fechaNacimiento} \n`, style:'info2'},
              { text: 'Identificación: ', style:'info2_bold'},
              { text: ` ${this.server.cedula}`, style:'info2'}    
            ]
          },
          {
            absolutePosition: { x: 795, y: 900 },
            fit:[716,900],
            text: [
              { text: 'Perfil \n', style: 'header' },
              { text: `${this.server.frase} \n`, style: 'body' },
            ]
          },
          //5 Experencia
          {
            absolutePosition: { x: 100, y: 1400 },
            columns: [
              {
                width: '717',
                stack: []
              }
            ],
          },
          // Titulo hbilidades
          {
            // fit:[300,604]
            width: 700,
            absolutePosition: { x: 877, y: 1250 },
            text: "HABILIDADES",
            style: 'secctionTitle',
            alignment: 'center'
          },
          //7 Habilidades
          {
            absolutePosition: { x:  877, y: 1302 },
            columns: [
              {
                width: '33%', // Cambia el ancho a 33% para tener tres columnas
                columnGap: 10, // Añade un espacio entre columnas
                stack: []
              },
              {
                width: '33%', // Cambia el ancho a 33% para tener tres columnas
                columnGap: 10, // Añade un espacio entre columnas
                stack: []
              },
              {
                width: '33%', // Cambia el ancho a 33% para tener tres columnas
                columnGap: 10, // Añade un espacio entre columnas
                stack: []
              }
            ],
          },
          // Decorador server verificaca
          {
            image: 'verified',
            absolutePosition: { x: 473, y: 29 },
          },
          {
            absolutePosition: { x: 814, y: 1223 },
            stack: [
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 732, // Altura de la línea vertical
                    lineWidth: 2, // Grosor de la línea
                    lineColor: '#D9D9D9' // Color de la línea (opcional)
                  }
                ]
              }
            ]
          },
          // Fecha de ingreso
          {
            absolutePosition: { x: 109, y: 1093  },
            stack: [
              {
                style: 'info_bold',
                bold: true,
                text: [
                  // {image: 'icon_world_svg'}
                  'Domicilio verificado',
                ]
              },
              // {
              //   style: 'info',
              //   text: [
                  
              //     {text: 'Fecha de ingreso: ',bold: true, style: 'info_bold'},
              //     `${this.server.fechaIngreso}`,
              //   ]
              // }
            ]
          },
        ],
        defaultStyle: {
          font: 'airbnb_b64'
        },
        images: {
          fotoDePerfil: `${this.server.foto}`, 
          background: "https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fbackground_pdf_profile_server.jpg?alt=media&token=413f1d43-f448-43cd-b8ed-58ae83e403e4",
          pin:        'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fpin_pdf_profile_server.png?alt=media&token=b60a57de-337b-4260-96a5-6f55a9207c31',
          verified: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fverified_profile_server.png?alt=media&token=dc8eb2db-01bb-4079-9511-c5d0671b4ec6',
          //icons
          icon_AseoHogar: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_hogar.png?alt=media&token=9e24a248-4318-4b3f-8d8a-f2236e92a6b4',
          icon_Cocina: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_cocina.png?alt=media&token=c854793f-d762-499f-8e7d-2b675b397085',
          icon_Planchado: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_plancha.png?alt=media&token=4ef8463d-d528-42a7-bfc9-4e1a870e857e',
          icon_AseoEmpresa: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_empresas.png?alt=media&token=605f85cf-6aa4-470d-89c0-ebd25f4c4077',
          icon_Desinfecion: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_exterminador.png?alt=media&token=2decd671-27ca-4721-9d4a-5311514359b6',
          icon_Maquinas: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_aspiradora.png?alt=media&token=c153db48-fadb-4f53-ab90-97cb5d02e470',
          icon_Todero: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_pintor.png?alt=media&token=9fa769e6-9ae7-467f-b2d5-c8ad53df81ab',
          icon_Fotografo: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_fotografo.png?alt=media&token=b100f8d8-4253-4c5a-9b93-f94758b0f837',
          icon_Ingles: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_idioma.png?alt=media&token=b636a868-f092-468c-84df-bb796e41eedc',
          icon_Conductor: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_conductor.png?alt=media&token=03d893a0-454a-4c40-ae5c-a8c9f9786711',
          icon_GuiaTuristico: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_guiaTuristico.png?alt=media&token=1c884b09-3e6f-46b4-a58e-e24a7b986e34',
          icon_CuidadoNiños: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_cuidadoNinos.png?alt=media&token=1442e177-47a1-47d8-b263-a60fe9d20803',
          icon_CiudadoMascotas: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_cuidadoMascotas.png?alt=media&token=a99d9d2e-f6ee-40c1-935c-287f39707aaa',
          icon_CuidadoAdultosMayores: 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Ficon_anciano.png?alt=media&token=6822b679-389c-4b49-b392-3e6827d636ab',
        },
        styles: {
          info:{
            // bold: false,
            fontSize: 20,
            color: "#fff",
          },
          info_bold:{
            // bold: true,
            fontSize: 20,
            color: "#fff",
          },
          info2:{
            bold: false,
            fontSize: 20,
            color: "#1C6FF3",
          },
          info2_bold:{
            bold: true,
            fontSize: 20,
            color: "#1C6FF3",
          },
          header:{
            fontSize: 50
          },
          body:{
            fontSize: 20,
            color: '#878787'
          },
          textServicios:{
            fontSize: 20,
            color: '#1C6FF3',
            alignment: 'center'
          },
          textNumServicios:{
            font: 'jazmin_alt',
            fontSize: 50,
            color: '#1C6FF3',
            alignment: 'center'
          },
          secctionTitle:{
            alignment: 'center',
            bold: true,
            fontSize: 30,
            characterSpacing: 15,
            margin: [0,0,0,10]
          },
          itemTitle:{
            bold: true,
            fontSize: 22,
          },
          itemSubtitle:{
            fontSize: 22,
            color: '#878787',
          },
          textDescription:{
            fontSize: 21,
            color: '#878787',
            margin: [145,0,0,10]
          },
          skill_iactive:{
            margin: [0,4,30,0],
            fontSize: 18,
            color: '#878787',
            alignment: 'center',
          },
          skill_active:{
            margin: [0,4,30,0],
            fontSize: 18,
            color: '#1c6ff3',
            alignment: 'center',
          }
        },
      };
      let arrayExperiencia = [];
      console.log( documentDefinition.content[5]);
      if (this.server.estudios && this.server.estudios.length > 0) {
        arrayExperiencia.push(
          {text: 'EDUCACÍON\n', style: 'secctionTitle'},
        )
        for (let info of this.server.estudios) {
          // let fecha = info.fecha.replace("hasta", "-");
          const posicionHasta = info.fecha.indexOf("hasta");
          const primeraParte = info.fecha.substring(0, posicionHasta).trim();
          const segundaParte = info.fecha.substring(posicionHasta + "hasta".length).trim();
          const primeraParteFinal = primeraParte.slice(0, -6);
          const segundaParteFinal = segundaParte.slice(0, -6);
          let fecha = primeraParteFinal + " - " + segundaParteFinal;
          arrayExperiencia.push(
            {
              text:[ 
                { text: `${fecha}   `, style: "itemSubtitle"},
                { text: `${info.institucion}\n`, style: "itemTitle",},
              ]
          },
          { text: `${info.titulo}\n`, style: "textDescription"},
            
            // { text: `${fecha}     `, style: "itemSubtitle" },
            // { text: `${info.institucion}  \n`, style: "itemTitle" },
            // { text: `${info.titulo}  \n`, style: "textDescription", alignment: 'right' },
          )
        }
      } 
      if (this.server.experiencia && this.server.experiencia.length > 0) {
        arrayExperiencia.push(
          {text: 'EXPERIENCIA\n', style: 'secctionTitle'}
        );
        for (let info of this.server.experiencia) {
          const posicionHasta = info.fecha.indexOf("hasta");
          const primeraParte = info.fecha.substring(0, posicionHasta).trim();
          const segundaParte = info.fecha.substring(posicionHasta + "hasta".length).trim();
          const primeraParteFinal = primeraParte.slice(0, -6);
          const segundaParteFinal = segundaParte.slice(0, -6);
          let fecha = primeraParteFinal + " - " + segundaParteFinal;
          arrayExperiencia.push(
            {
              text:[ 
                { text: `${fecha}   `, style: "itemSubtitle"},
                { text: `${info.empresa}\n`, style: "itemTitle" },
              ]
            },
            { text: `${info.funciones} \n`, style: "textDescription"},
          )
        }
      } 
      documentDefinition.content[5].columns[0].stack = arrayExperiencia;
  
      let columnIndex = 0; // Para rastrear el índice de la columna actual
  
      for (let info of this.habilidades) {
        const columnStack = documentDefinition.content[7].columns[columnIndex].stack;
      
        if (this.server.habilidades.some(res => res == info.name)) {
          columnStack.push(
            {stack:[
              { image: `${info.icon}`, style: "skill_active", margin: [0,1,0,0] },
              { text: `${info.name.toUpperCase()}`, style: "skill_active" }
            ]}
            );
        } else {
          columnStack.push(
            {stack:[
              { image: `${info.icon}`, style: "skill_iactive", margin: [0,1,0,0] },
              { text: `${info.name.toUpperCase()}`, style: "skill_iactive" }
            ]}
            );
        }
      
        // Incrementar el índice de la columna actual
        columnIndex = (columnIndex + 1) % 3; // Se asegura de que el índice esté entre 0 y 2
      }
      console.log(documentDefinition);
      pdfMake.createPdf(documentDefinition);
      pdfMake.createPdf(documentDefinition).open();
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    }catch(error){
      console.error('Ocurrio un eeror al generra el perfil',error)
    }
  }

  async openHojaVida() {
    console.log("esta es la serve:", this.server);

    let fotoPerfil: "";
    pdfFonts.pdfMake.vfs["airbnb_b64"] = airbnb;
    pdfMake.fonts = {
      Italianno: {
        normal: "airbnb_b64",
        bold: "airbnb_b64",
        italics: "airbnb_b64",
        bolditalics: "airbnb_b64",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
    };

    // const xhr = new XMLHttpRequest();
    // xhr.responseType = "blob";
    // xhr.onload = (event) => {
    //   const blob = xhr.response;
    // };
    // xhr.open("GET", this.server.foto);
    // xhr.send();
    fotoPerfil = this.server.foto;

    const documentDefinition = {
      pageSize: {
        width: 612,
        height: 792,
      },
      background: [
        {
          image: "background",
          width: 612,
          height: 792,
        },
      ],
      content: [
        {
          style: "tablaHeader",
          table: {
            widths: [70, 250],
            body: [
              [
                {
                  image: "fotoDePerfil",
                  width: 74,
                  height: 72,
                  style: "foto",
                  fillColor: "#ffffff",
                  border: [false, false, false, false],
                },
                {
                  style: "profile",
                  border: [false, false, false, false],
                  text: [
                    {
                      text:
                        this.server.name + " " + this.server.apellido + "\n",
                      style: "server",
                    },
                    {
                      text:
                        this.server.department +
                        " " +
                        this.server.cities +
                        "\n",
                      style: "ciudad",
                    },
                    {
                      text: "'" + this.server.frase + "'\n",
                      widt: 200,
                      style: "frase",
                    },
                  ],
                },
              ],
              [],
              [],
            ],
          },
        },
        {
          style: "table",
          columns: [
            {
              style: "columnRight",
              table: {
                widths: [200],
                body: [
                  [ 
                    {
                      image: "azulClaro",
                      width: 1,
                      height: 1,
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "Habilidades y conocimientos\n",
                      style: "titlegrey",
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              width: 370,
              style: "rowtwo",
              text: [
                { text: "Experiencia Laboral\n", style: "title" },
                {
                  text:
                    "____________________________________________________________" +
                    "\n\n",
                  style: "linea",
                },
              ],
            },
          ],
        },
      ],
      images: {
        background: "https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fbackground_pdf_profile_server.jpg?alt=media&token=413f1d43-f448-43cd-b8ed-58ae83e403e4",
        circle: "https://i.ibb.co/JByVPbs/Group-611.png",
        azulClaro: "https://i.ibb.co/zNT3M72/Rectangle-269.png",
        starCero: "https://i.ibb.co/Z1fDbjk/starFour.png",
        starOne: "https://i.ibb.co/SrdMcc7/starCero.png",
        datewo: "https://i.ibb.co/cxztyJ2/starTwo.png",
        starThree: "https://i.ibb.co/NVJKzBV/star-Three.png",
        starFour: "https://i.ibb.co/Z1fDbjk/starFour.png",
        starFive: "https://i.ibb.co/Swh3jdS/starFive.png",
        isnigEmpresa: "https://i.ibb.co/QrX9tgm/Frame-633.png",
        isnigHogar: "https://i.ibb.co/876xzrb/Frame-632.png",
        isnigTodero: "https://i.ibb.co/NjCpS7j/Frame-635.png",
        isnigMaquinaria: "https://i.ibb.co/SBgybnG/Frame-636.png",
        vacio: "https://i.ibb.co/BC8kjv2/VACIO.png",
        fotoDePerfil: `${fotoPerfil}.png`,
      },
      styles: {
        defaultStyle: {
          font: "airbnb_b64",
          fontSize: 12,
        },
        foto: {
          margin: [-3, 0, 0, 10],
        },
        server: {
          fontSize: 25,
          color: "#16304F",
          alignment: "left",
          bold: true,
        },
        profile: {
          margin: [10, 0, 0, 0],
        },
        ciudad: {
          fontSize: 14,
          color: "#444444",
          alignment: "left",
          bold: true,
        },
        date: {
          color: "#616161",
          fontSize: 12,
        },
        services: {
          color: "#000000",
          fontSize: 12,
          margin: [120, 0, 0, 0],
        },
        titlegrey: {
          color: "#444444",
          fontSize: 16,
          bold: true,
        },
        duracion: {
          color: "#16304F",
          fontSize: 10,
          bold: true,
        },
        table: {
          margin: [-30, 0, 0, 0],
        },
        columnRight: {
          margin: [0, -180, 0, 0],
        },
        rowtwo: {
          margin: [20, 20, 0, 0],
        },
        star: {
          margin: [0, 0, 0, 0],
        },
        frase: {
          color: "#4386C6",
          fontSize: 10,
          margin: [0, 20, 0, 0],
        },
        title: {
          fontSize: 20,
          color: "#16304F",
          margin: [-30, 0, 0, 0],
          bold: true,
        },
        text: {
          color: "#0A223E",
          fontSize: 14,
          margin: [-30, 20, 0, 0],
          bold: true,
        },
        text_negrilla: {
          color: "#444444",
          fontSize: 12,
          bold: true,
        },
        textGrey: {
          color: "#A3A3A3",
          fontSize: 12,
          margin: [-30, 0, 0, 0],
        },
        textGreyTwo: {
          color: "#444444",
          fontSize: 12,
          margin: [-30, 0, 0, 0],
        },
        linea: {
          color: "#0A223E",
          margin: [0, 0, 0, 0],
        },
        tablaHeader: {
          margin: [200, 0, 0, 20],
        },
      },
    };

    if (this.server.experiencia.length > 0) {
      for (let exp of this.server.experiencia) {
        documentDefinition.content[1].columns[1].text.push(
          { text: exp.cargo + "\n", style: "text" },
          { text: exp.fecha + "\n", style: "textGreyTwo" },
          { text: exp.empresa + "\n", style: "textGrey" },
          { text: exp.funciones + "\n\n", style: "textGreyTwo" }
        );
      }
    } 
    else

      documentDefinition.content[1].columns[1].text.push(
        { text: "Educación y Formación" + "\n", style: "title" },
        {
          text:
            "____________________________________________________________" +
            "\n\n",
          style: "linea",
        }
      );

    if (this.server.estudios.length > 0) {
      for (let est of this.server.estudios) {
        documentDefinition.content[1].columns[1].text.push(
          { text: est.titulo + "\n", style: "text" },
          { text: est.fecha + "\n", style: "textGreyTwo" },
          { text: est.institucion + "\n", style: "textGrey" },
          {
            text: est.departamento + ", " + est.ciudad + "\n\n",
            style: "textGreyTwo",
          }
        );
      }
    }

    if (this.server.calificacionPromedio == undefined) {
      this.stars = 0;
    } else {
      this.stars = this.server.calificacionPromedio;
    }

    switch (this.stars) {
      case 0:
        documentDefinition.content[0].table.body[1].push({
          image: "starCero",
          width: 40,
          height: 20,
          style: "star",
          fillColor: "#FFFFFF",
          border: [false, false, false, false],
        });
        break;
      case 1:
        documentDefinition.content[0].table.body[1].push({
          image: "starOne",
          width: 40,
          height: 20,
          style: "star",
          fillColor: "#FFFFFF",
          border: [false, false, false, false],
        });
        break;
      case 2:
        documentDefinition.content[0].table.body[1].push({
          image: "starTwo",
          width: 40,
          height: 20,
          style: "star",
          fillColor: "#FFFFFF",
          border: [false, false, false, false],
        });
        break;
      case 3:
        documentDefinition.content[0].table.body[1].push({
          image: "starThree",
          width: 40,
          height: 20,
          style: "star",
          fillColor: "#FFFFFF",
          border: [false, false, false, false],
        });
        break;
      case 4:
        documentDefinition.content[0].table.body[1].push({
          image: "starFour",
          width: 40,
          height: 20,
          style: "star",
          fillColor: "#FFFFFF",
          border: [false, false, false, false],
        });
        break;
      case 5:
        documentDefinition.content[0].table.body[1].push({
          image: "starFive",
          width: 40,
          height: 20,
          style: "star",
          fillColor: "#FFFFFF",
          border: [false, false, false, false],
        });
        break;
    }


    documentDefinition.content[0].table.body[1].push({
      border: [false, false, false, false],
      text: [
        {
          text: this.server.serviciosTerminados + " Servcios Realizados\n",
          style: "services",
        },
      ],
      style: "services",
    });
    console.log('entro en el primer if de servicios');

    documentDefinition.content[0].table.body[2].push(
      {
        style: "duracion",
        border: [false, false, false, false],
        text: [
          {
            text:
              "En Serv desde " + moment(this.server.fecha).format("YYYY-MM-DD"),
            style: "duracion",
            widt: 400,
          },
        ],
      },
      {
        style: "none",
        border: [false, false, false, false],
        text: [
          {
            text: "    ",
            style: "none",
          },
        ],
      }
    );


    documentDefinition.content[1].columns[0].table.body.push([
      {
        image: "isnigHogar",
        width: 152,
        height: 220,
        border: [false, false, false, false],
      },
    ]);
    documentDefinition.content[1].columns[0].table.body.push([
      {
        image: "isnigEmpresa",
        width: 188,
        height: 157,
        border: [false, false, false, false],
      },
    ]);

    // this.server.habilidades.forEach(res=>{
    //   documentDefinition.content[1].columns[0].table.body.push(
    //     [
    //       {
    //         text: `${res.subCategoria}\n`,
    //         style: "titlegrey",
    //         border: [false, false, false, false],
    //       },
    //     ],
    //   );
    // })






    // if(this.server.certificadoEmpresas == true){
    //   documentDefinition.content[1].columns[0].table.body.push([
    //     {
    //       image: "isnigEmpresa",
    //       width: 180,
    //       height: 100,
    //       border: [false, false, false, false],
    //     },
    //   ]);
    // }

    // if(this.server.certificadoEmpresas == true){
    //   documentDefinition.content[1].columns[0].table.body.push([
    //     {
    //       image: "isnigEmpresa",
    //       width: 180,
    //       height: 100,
    //       border: [false, false, false, false],
    //     },
    //   ]);
    // }

    pdfMake.createPdf(documentDefinition);
    pdfMake.createPdf(documentDefinition).open();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    const a = {
      name: "certificado",
      size: 36182,
      type: "application/pdf",
    };
  }

  async getCalificaciones(){
    let calificaiones = [];
    const query = (await this.posventaService.getCalificacioesServer(this.id_server))
    console.log('total calificaciones',query.size);
    query.docs.forEach(res=>{
      
      calificaiones.push(res.data())
    })
    this.arrayCalificaciones = calificaiones;
  }


}
