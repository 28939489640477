import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

/*__________SERVICES_________*/
import { AuthService } from "src/app/services/auth.service";
import { Metricas } from "src/app/services/metricas.service";
import { ServerService } from "src/app/services/server.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";
import { PriceService } from '../../services/prices.service'
/*___________________________*/

/*__________UTILIDADES_________*/
import * as _ from "lodash";
import * as moment from "moment";
import { Novedades } from "src/app/utility/novedades";
import { NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertType } from "sweetalert2";
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { ServBackendService } from 'src/app/services/serv-backend.service';
declare var google;
/*_____________________________*/

@Component({
  selector: 'app-mdls-info-servicio',
  templateUrl: './mdls-info-servicio.component.html',
  styleUrls: ['./mdls-info-servicio.component.scss']
})
export class MdlsInfoServicioComponent implements OnInit {

  // @ViewChild(AgmMap)
  @Input() infoServicioVisualizado: any;
  @Input() listaServers: any = 'falta'

  @ViewChild("mdlServicio", { static: true }) mdlServicio: ElementRef;
  horasDeInicio = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
  estadosServicio = ["Pendiente", "Aceptado", "En camino", "En curso", "Terminado", "Congelado"];
  fireStoreRef: AngularFireStorageReference;
  keyServersAutocomplete: string = "nombreServer";
  remplazo: boolean = false;
  fechaPostVenta: string = "";

  infoClient: any = {};
  usuarioEnSecion: any;
  asignarPostVenta: boolean = false;
  editarRecargoTransporte = false;
  editarDuracion: boolean = false;
  editarFecha: boolean = false;
  editarHoraInicio: boolean = false;
  editarEstado: boolean = false;
  editarDireccion: boolean = false;
  horarioEdidato = false;
  activeUpdateServer = false;
  novedadesServicio = []
  serversAsignables: any[];
  flippedEditServicios = false;
  precios = [];
  /*___________ VARIABLES NOVEDADES DEL SERVICIO__________*/
  arrayCategoriaNovedad: any = Novedades
  arrayTiposNovedad: any = [];
  categoriaNovedad: string = "";
  tipoNovedad: string = "";
  areaNovedad: string = "";
  estadoNovedad: string = "";
  intencionNovedad: string = "";
  descripcionNovedad: string;
  costoNovedad: number = 0;
  archivoNovedad: string;
  map: any;
  gpsDestination: { lat: any; lng: any; };
  loaderGeneral: boolean = false;

  serverSeleccionada: any = {};
  activeListOptionServers = false;
  filteredOptions = [];
  /**********************************************************************/
  constructor(
    private priceService: PriceService,
    private metricasService: Metricas,
    private authService: AuthService,
    private serverService: ServerService,
    private usuarioService: UsuarioService,
    private servicioService: ServicioService,
    private nbDialogService: NbDialogService,
    private angularFireStorage: AngularFireStorage,
    private servBackendService: ServBackendService,
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
  }

  ngOnInit() {
    console.log("Servio Visualizado", this.infoServicioVisualizado)
    console.log("Lista server", this.listaServers)
    this.traerNovedadesAsociadas()
    this.getClient();
    // @ts-ignore 
    this.nbDialogService.open(this.mdlServicio, { context: 'this is some additional data passed to dialog' });
    this.loadMap();
  }

  async getClient() {
    console.log("Entro en getClient", this.infoServicioVisualizado.client.id);
    this.infoClient = await (await this.usuarioService.detail(this.infoServicioVisualizado.client.id)).data();
    console.log("CLIENTE", this.infoClient)
  }

  traerNovedadesAsociadas() {
    this.novedadesServicio = [];
    this.metricasService.getNovedadesServicio(this.infoServicioVisualizado.id).then(res => {
      res.docs.forEach(data => {
        let element = data.data()
        element.actualizar = false
        this.novedadesServicio.push(element)
        console.log(this.novedadesServicio)
      });
    })
  }

  fileSelectedForUpload: File;
  onUploadComprobante(event) {
    let file: any;
    let image = false;
    let pictureName: any;
    console.log("files", event.target.files[0]);
    this.fileSelectedForUpload = event.target.files[0];
    file = event.target.files[0];
    pictureName = `comprobante_${this.infoServicioVisualizado.id}`;
    image = true;
    if (file && pictureName) {
      this.servicioService.putPictureComprobante(pictureName, file).then((value) => {
        if (value.state == "success") {
          this.servicioService.getURL(pictureName).subscribe((url) => {
            this.infoServicioVisualizado.comprobante = url;
            this.infoServicioVisualizado.estadoPago = "Pago";
            this.servicioService.updateServicioTerminado({ id: this.infoServicioVisualizado.id, comprobante: url }).then(() => {
              Swal.fire("Listo", 'El comprobante fue subido con exito', 'success');
            })
          });
        }
      })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no pasa el segundo if");
    }
  }

  activarEdicionServicio() {
    this.horarioEdidato = true;
    this.infoServicioVisualizado
    if (this.precios.length == 0) {
      this.priceService.get().then(res => {
        res.docs.forEach(data => {
          const price = data.data();
          this.precios.push(price);
        })
      })
    }
  }

  // validar qeu deberia pasa aqui
  onChangeDate(){
    this.infoServicioVisualizado.server = {};
    this.infoServicioVisualizado.idServer = "";
    this.infoServicioVisualizado.nombreServer = '';
  }

  elegirDireccionServicioEnEdicion(event) {
    let a = parseInt(event)
    console.log(this.infoServicioVisualizado)
    this.infoServicioVisualizado.destination = this.infoClient.direccionesArray[a];
    this.infoServicioVisualizado.direccion = this.infoServicioVisualizado.destination.direccion
    this.infoServicioVisualizado.observaciones = this.infoServicioVisualizado.destination.observaciones
    console.log(this.infoServicioVisualizado)
  }



  /*---------- AGREGAR NOVEDAD ---------*/
  public buscarNovedades(event, type) {
    let value = event.target.value;
    console.log(value, type);
    if (type == "tipo") {
      let index = this.arrayCategoriaNovedad.findIndex(categoria => categoria.name == value);
      console.log(index);
      this.arrayTiposNovedad = this.arrayCategoriaNovedad[index].tipo;
      console.log(this.arrayTiposNovedad);
    } else if (type == "area") {
      let index = this.arrayTiposNovedad.findIndex(tipo => tipo.name == value);
      console.log(this.arrayTiposNovedad[index])
      this.areaNovedad = this.arrayTiposNovedad[index].area;
      this.costoNovedad = this.arrayTiposNovedad[index].valor
      this.estadoNovedad = this.arrayTiposNovedad[index].estado;
      this.intencionNovedad = this.arrayTiposNovedad[index].intencion;
      console.log(this.areaNovedad);
    }
  }

  onUploadImgNovedad(event) {
    const dia = new Date().getTime()
    const filePath = `Novedades/${dia}`;
    this.fireStoreRef = this.angularFireStorage.ref(filePath);
    this.angularFireStorage.ref(filePath).put(event.target.files[0]).then(res => {
      this.angularFireStorage.ref(filePath).getDownloadURL().subscribe((url) => {
        this.archivoNovedad = url
      });
    }).catch(err => {
      console.log("Ocurrio un error---", err)
    });
  }


  public crearNovedad(descripcion, costo) {
    let novedad = {
      id: new Date().getTime(),
      categoria: this.categoriaNovedad,
      tipo: this.tipoNovedad,
      area: this.areaNovedad,
      descripcion: descripcion,
      emisor: this.usuarioEnSecion.displayName,
      estado: this.estadoNovedad,
      intencion: this.intencionNovedad,
      agenteDesignado: "Sin agente",
      costo: parseInt(costo),
      cliente: this.infoServicioVisualizado.client.name + " " + this.infoServicioVisualizado.client.apellido,
      clienteId: this.infoServicioVisualizado.client.id,
      server: this.infoServicioVisualizado.server.name ? this.infoServicioVisualizado.server.name + " " + this.infoServicioVisualizado.server.apellido : "Sin server",
      serverId: this.infoServicioVisualizado.server.id ? this.infoServicioVisualizado.server.id : "Sin server",
      servicioAsociado: this.infoServicioVisualizado.id,
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaEnProceso: "null",
      fechaSolucionada: "null",
      fechaDelServicio: this.infoServicioVisualizado.fecha,
      ciudad: this.infoServicioVisualizado.destination.cities,
      urlArchivo: this.archivoNovedad ? this.archivoNovedad : '',
      tipoServicio: this.infoServicioVisualizado.tipoServicio,
      department: this.infoServicioVisualizado.destination.department,
      estadoGeneral: 'vigente',
    }
    console.log(novedad)
    if (this.tipoNovedad == 'Cancelación de servicio' || this.tipoNovedad == 'Uniforme' || this.tipoNovedad == 'Calidad' ||
      this.tipoNovedad == 'Sin productos' || this.tipoNovedad == 'Calidad' || this.tipoNovedad == 'Daños') {
      this.serverService.detail(this.infoServicioVisualizado.server.id).then((data) => {
        const server = data.data()
        console.log(server);
        if (server.strikes === undefined) {
          server.strikes = [];
        }
        server.strikes.push({
          fecha: moment().format('YYYY-MM-DD'),
          razon: this.tipoNovedad,
          revisada: false,
        })
        this.serverService.updateServ(server)
      })
    } else if (this.tipoNovedad == 'Puntualidad' || this.tipoNovedad == 'Accidentes') {
      this.serverService.detail(this.infoServicioVisualizado.server.id).then((data) => {
        const server = data.data()
        console.log(server);
        if (server.numFallas === undefined) {
          server.numFallas = 0
        }
        server.numFallas++
        if (server.numFallas === 3) {
          if (server.strikes === undefined) {
            server.strikes = [];
          }
          server.strikes.push({
            fecha: moment().format('YYYY-MM-DD'),
            razon: this.tipoNovedad,
            revisada: false,
          })
        }
        this.serverService.updateServ(server);
      })
    }
    this.metricasService.crearNovedad(novedad).then(res => {
      Swal.fire("Creado", "La novedad fue creada exitosamente", "success");
      this.servicioService.servicioConNovedad(novedad.servicioAsociado).then(res => {
        console.log("se actualizo el servicio");
      })
      this.categoriaNovedad = "";
      this.tipoNovedad = "";
      this.descripcionNovedad = "";
      this.costoNovedad = 0;
    })
  }

  cambiarHorasDeServicio() {
    const tipoServicio = this.infoServicioVisualizado.tipoServicio == 'Plan Mensual' ? 'Plan' : 'Expres';
    const filter = this.precios.filter(res => res.tipoAgendamiento == tipoServicio && res.horas == `${this.infoServicioVisualizado.horasDeServicio}`)[0];
    this.infoServicioVisualizado.precio = filter.precio;
    this.infoServicioVisualizado.gananciaServer = filter.gananciaServer;
  }

  public actualizarNovedad(indice) {
    let fechaActual = moment().format("YYYY-MM-DD");
    this.novedadesServicio[indice];
    let novedadParaActualizar = this.novedadesServicio[indice];
    console.log(novedadParaActualizar);
    novedadParaActualizar.actualizar = false;
    if (novedadParaActualizar.estado == "En proceso") {
      novedadParaActualizar.fechaEnProceso = fechaActual;
      novedadParaActualizar.agenteDesignado = this.usuarioEnSecion.displayName;
    } else if (novedadParaActualizar.estado == "Solucionada") {
      novedadParaActualizar.fechaSolucionada = fechaActual;
    }
    this.metricasService.crearNovedad(novedadParaActualizar).then(res => {
      Swal.fire("Listo", "La novedad fue actualizada exitosamente", "success");
    })
  }
  /*-------------------------------------*/

  public actualizarServicio() {
    this.horarioEdidato = false
    let editados: any = {
      a: false
    }
    if (this.asignarPostVenta == true) {
      this.infoServicioVisualizado.estadoPostventa = "postventa pendiente";
      this.infoServicioVisualizado.fechaHoraPostventa = moment(this.fechaPostVenta).format('YYYY-MM-DD');
      this.infoServicioVisualizado.responsablePostventa = this.usuarioEnSecion.displayName;
      editados.postVenta = `Se agrego postventa al servicio`
      editados.a = true
    }
    if (!this.infoServicioVisualizado.hora.includes(':00')) {
      this.infoServicioVisualizado.hora = this.infoServicioVisualizado.hora + ":00"
    }
    this.servicioService.getServiciosbyId(this.infoServicioVisualizado.id).then(async res => {
      if (res.data().direccion != this.infoServicioVisualizado.direccion) {
        editados.direccion = `De ${res.data().direccion} a ${this.infoServicioVisualizado.direccion}`
        editados.a = true
      }
      if (res.data().horasDeServicio != this.infoServicioVisualizado.horasDeServicio) {
        editados.horas = `De ${res.data().horasDeServicio} a ${this.infoServicioVisualizado.horasDeServicio}`
        editados.a = true
      }
    
      if (res.data().fecha != this.infoServicioVisualizado.fecha) {
        editados.fechaEditada = `De ${res.data().fecha} a ${this.infoServicioVisualizado.fecha}`
        editados.a = true
        this.infoServicioVisualizado.estado = 'Pendiente'
        if (this.infoServicioVisualizado.tipoServicio === 'Plan Mensual') {
          console.log('Fecha plan')
          this.ajustarFechaPlan(this.infoServicioVisualizado)
        }
      }
      if (res.data().hora != this.infoServicioVisualizado.hora) {
        editados.horaInicio = `De ${res.data().hora} a ${this.infoServicioVisualizado.hora}`
        editados.a = true;
      }
      if (res.data().estado != this.infoServicioVisualizado.estado) {
        editados.estado = `De ${res.data().estado} a ${this.infoServicioVisualizado.estado}`
        editados.a = true;

        if (this.infoServicioVisualizado.coordenadasInicio !== undefined) {
          console.log(this.infoServicioVisualizado)
          const inicio = this.infoServicioVisualizado.fecha + ' ' + this.infoServicioVisualizado.coordenadasInicio.hora + ':00';
          const inicioServicio = this.infoServicioVisualizado.fecha + ' ' + this.infoServicioVisualizado.hora + ':00';
          console.log(Math.abs(moment(inicio).diff(moment(inicioServicio), 'minutes', true)));
          if (moment(inicio).diff(moment(inicioServicio), 'minutes', true) > 10 || moment(inicio).diff(moment(inicioServicio), 'minutes', true) < -60) {
            const resNovedad = await this.generarNovedadAutomatica("resta",'Puntualidad', 'Recursos', 5000,'Servers', `La server no llego a tiempo al servicio, era para ${inicioServicio} y se registra llegada a ${inicio}`);
            console.log('Tien el bono', resNovedad)
            this.infoServicioVisualizado.bonoPuntualidad_App = resNovedad;
          }
        }

        if (this.infoServicioVisualizado.estado == "Cancelado" && this.infoServicioVisualizado.tipoServicio == 'Plan Mensual') {
          await this.actualizarCobros(this.infoServicioVisualizado);
        }

        // Generar novedad de cambio de estado eliminando el bono de puntualidad
        else if (this.infoServicioVisualizado.estado == "En curso" && this.infoServicioVisualizado.coordenadasInicio == undefined && this.infoServicioVisualizado.tipoServicio != "Administrativo") {
          const resNovedad = await this.generarNovedadAutomatica('resta','No uso la app', 'Recursos', 5000,'Servers', `Se cambio el servicio de ${res.data().estado} a ${this.infoServicioVisualizado.estado} desde logistica`);
          // this.infoServicioVisualizado.bonoPuntualidad_App = resNovedad; 
          console.log('Tien el bono', resNovedad)
        } else if (this.infoServicioVisualizado.estado == "En camino" || this.infoServicioVisualizado.estado == "Terminado" && this.infoServicioVisualizado.tipoServicio != "Administrativo") {
          const resNovedad = await this.generarNovedadAutomatica('resta','No uso la app', 'Recursos', 5000,'Servers', `Se cambio el servicio de ${res.data().estado} a ${this.infoServicioVisualizado.estado} desde logistica`);
          // this.infoServicioVisualizado.bonoPuntualidad_App = resNovedad;
          console.log('Tien el bono', resNovedad)
        }
      }

      if (res.data().idServer != this.infoServicioVisualizado.idServer) {
        if(res.data().idServer != '' && this.infoServicioVisualizado.idServer != ''){
          const resNovedadCancelacion = await this.validarNovedadServerCancela(res.data().server.name,res.data().idServer);
        }
        
        editados.server = `De ${res.data().server.name} ${res.data().server.apellido} a ${this.infoServicioVisualizado.server.name} ${this.infoServicioVisualizado.server.apellido}`
        editados.a = true
        this.infoServicioVisualizado.estado = 'Pendiente';
        const resNovedad = await this.eliminarNovedadesServicio(this.infoServicioVisualizado.id);
      }

      if (this.infoServicioVisualizado.estado == "Subasta") {
        if (this.infoServicioVisualizado.fecha == moment().format('YYYY-MM-DD') || (this.infoServicioVisualizado.fecha == moment().add(1, 'day').format('YYYY-MM-DD') && moment().format('H') >= '16')) {
          const resNovedad = await this.generarNovedadAutomatica('resta','Server cancela', 'Recursos', 35000,'Servers', `La server cancelo el servicio`)
          console.log(resNovedad)
        } else if (this.infoServicioVisualizado.fecha == moment().add(1, 'day').format('YYYY-MM-DD') && moment().format('H') < '16') {
          const resNovedad = await this.generarNovedadAutomatica('resta','Server cancela', 'Recursos', 35000,'Servers', `La server cancelo el servicio`)
          console.log(resNovedad)
        }
        this.infoServicioVisualizado.server = {};
        this.infoServicioVisualizado.subasta = true;
        this.infoServicioVisualizado.idServer = "";
      }
      console.log(editados.a)
      if (editados.a) {
        editados.responsable = this.usuarioEnSecion.displayName
        editados.fecha = moment().format("YYYY-MM-DD")
        console.log("Cosas que edito", editados)
        if (!this.infoServicioVisualizado.historialEdiciones) {
          this.infoServicioVisualizado.historialEdiciones = [];
        }
        this.infoServicioVisualizado.historialEdiciones.push(editados)
      }
      if (this.infoServicioVisualizado.estado === "Orden de servicio" && this.infoServicioVisualizado.server !== "") {
        this.infoServicioVisualizado.estado = "Pendiente";
      }
      this.servicioService.updateServicioTerminado(this.infoServicioVisualizado).then((res) => {
        Swal.fire("Actualizado", "se actualizo el servicio con exito", "success")
      })
    })
  }

  generarNovedadCancelacion = '';
  validarNovedadServerCancela(serverName,serverId){
    return new Promise(resolve=>{

      Swal.fire({
        title: "Cambio de server",
        html: `
          <p>La server anterior canceló el servicio? Indica Si para generar nautomaticamente la novedade de cancelacion</p>
          <select id="generarNovedadCancelacion" class="input_form">
            <option value="" disabled selected>Seleccione una opción</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
        `,
        allowEscapeKey: false,  
        allowOutsideClick: false,
        confirmButtonText: 'Continuar',
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton() as HTMLButtonElement;
          confirmButton.disabled = true;
          console.log(confirmButton)
          const selectElement = document.getElementById('generarNovedadCancelacion') as HTMLSelectElement;
          selectElement.addEventListener('change', () => {
            if (selectElement.value) {
              confirmButton.disabled = false;
            } else {
              confirmButton.disabled = true;
            }
          });
        },
        preConfirm: () => {
          const selectedValue = (document.getElementById('generarNovedadCancelacion') as HTMLSelectElement).value;
          return selectedValue;
        }
      }).then((result) => {
        const selectedValue = result.value;
        if(selectedValue == 'si'){
          let novedad = {
            id: new Date().getTime(),
            categoria: 'Server directa',
            tipo: 'No trabajo este dia',
            area: 'Contabilidad',
            costo: (679000/15),
            urlArchivo: '',
            descripcion: 'La server cancelo el servicio',
            emisor: this.usuarioEnSecion.displayName,
            estado: 'Solucionada',
            intencion: 'resta',
            estadoGeneral: 'vigente',
            agenteDesignado: "Sin agente",
            clienteId: this.infoServicioVisualizado.client.id,
            servicioAsociado: this.infoServicioVisualizado.id,
            cliente: this.infoServicioVisualizado.client.name + " " + this.infoServicioVisualizado.client.apellido,
            server: serverName,
            serverId: serverId,
            fechaCreacion: this.infoServicioVisualizado.fecha,
            fechaEnProceso: "null",
            fechaSolucionada: "null",
            fechaDelServicio: this.infoServicioVisualizado.fecha,
            ciudad: this.infoServicioVisualizado.destination.cities !== undefined ? this.infoServicioVisualizado.destination.cities : '',
            tipoServicio: this.infoServicioVisualizado.tipoServicio,
            department: this.infoServicioVisualizado.destination.department !== undefined ? this.infoServicioVisualizado.destination.department : '',
          }
          this.metricasService.crearNovedad(novedad).then(res => {
            resolve(true);
          });
        }else {
          resolve(false);
        }
      });
    })
  }


  validarSeguridadSocialServer(id) {
    return new Promise(result => {
      this.serverService.detail(id).then(res => {
        console.log(res.data(), id);
        if (res.data().fechaVencimientoSeguridadSocial !== undefined) {

          if (res.data().fechaVencimientoSeguridadSocial > moment().format('YYYY-MM-DD') && res.data().estadoSeguridadSocial == 'aprobada') {
            result(false);
          } else {
            result(true);
          }
        } else {
          result(true);
        }
      });
    })
  }

  generarNovedadAutomatica(intencion, tipo, area, costo, categoria, descripcion) {
    return new Promise(resolve => {
      console.log('Entro en generar novedad atomatica')
      let novedad = {
        id: new Date().getTime(),
        categoria: categoria,
        tipo: tipo,
        area: area,
        costo: costo,
        urlArchivo: '',
        descripcion: descripcion,
        emisor: this.usuarioEnSecion.displayName,
        estado: 'Solucionada',
        intencion: intencion,
        estadoGeneral: 'vigente',
        agenteDesignado: "Sin agente",
        clienteId: this.infoServicioVisualizado.client.id,
        servicioAsociado: this.infoServicioVisualizado.id,
        cliente: this.infoServicioVisualizado.client.name + " " + this.infoServicioVisualizado.client.apellido,
        server: this.infoServicioVisualizado.server.name ? this.infoServicioVisualizado.server.name + " " + this.infoServicioVisualizado.server.apellido : "Sin server",
        serverId: this.infoServicioVisualizado.server.id ? this.infoServicioVisualizado.server.id : "Sin server",
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaEnProceso: "null",
        fechaSolucionada: "null",
        fechaDelServicio: this.infoServicioVisualizado.fecha,
        ciudad: this.infoServicioVisualizado.destination.cities !== undefined ? this.infoServicioVisualizado.destination.cities : '',
        tipoServicio: this.infoServicioVisualizado.tipoServicio,
        department: this.infoServicioVisualizado.destination.department !== undefined ? this.infoServicioVisualizado.destination.department : '',
      }
      if (tipo == 'Puntualidad') {
        this.metricasService.getNovedadesServicio(this.infoServicioVisualizado.id).then(res => {
          console.log(res.docs.length)
          if (res.docs.length > 0) {
            let yaConNovedad = false;
            res.docs.forEach((data, index) => {
              data.data();
              if (data.data().tipo == 'Puntualidad' || data.data().tipo == 'No uso la app') {
                yaConNovedad = true;
              }
              if (index == res.docs.length - 1) {
                console.log('ya con novedad', yaConNovedad)
                if (yaConNovedad == false) {
                  Swal.fire({
                    title: `Novedad a generar`,
                    text: `Se generara una novedad de tipo '${tipo}', desesa continiuar o descartar esta novedad`,
                    showConfirmButton: true,
                    confirmButtonText: 'Generar novedad',
                    showCancelButton: true,
                    cancelButtonText: "Descartar",
                    confirmButtonColor: '#1C6FF3',
                    cancelButtonColor: '#DDE9FD',
                    customClass: {
                      cancelButton: 'color: #1C6FF3',
                    }
                  }).then(res => {
                    if (res.value == true) {
                      this.metricasService.crearNovedad(novedad).then(res => {
                        resolve(false)
                      })
                    } else {
                      resolve(true)
                    }
                  })
                } else {
                  resolve(false)
                }
              }
            })
          } else {
            Swal.fire({
              title: `Novedad a generar`,
              text: `Se generara una novedad de tipo '${tipo}', desesa continiuar o descartar esta novedad`,
              showConfirmButton: true,
              confirmButtonText: 'Generar novedad',
              showCancelButton: true,
              cancelButtonText: "Descartar",
              confirmButtonColor: '#1C6FF3',
              cancelButtonColor: '#DDE9FD',
              customClass: {
                cancelButton: 'color: #1C6FF3',
              }
            }).then(res => {
              if (res.value == true) {
                this.metricasService.crearNovedad(novedad).then(res => {
                  resolve(false)
                })
              } else {
                resolve(true)
              }
            })
          }
        })
      } else {
        this.metricasService.crearNovedad(novedad).then(res => {
          resolve(false)
        })
      }
    })
  }

  onDeleteService(){
    Swal.fire({
      title:'Espera',
      text:'Seguro de qeu deseas eliminar el servicio',
      type: 'warning',
      confirmButtonText: "Si, eleiminar",
      cancelButtonText: 'No, cacelar',
      showCancelButton: true,
    }).then( res => {
      if(res.value == true){
        this.servicioService.delete(this.infoServicioVisualizado.id).then(()=>{
          Swal.fire('Listo','El servicio fule eliminado','success');
        })
      }else{
        console.log('NO se elimina')
      }
    })
  }

  eliminarNovedadesServicio(idServicio) {
    this.metricasService.getNovedadesServicio(idServicio).then(res => {
      if (res.docs.length > 0) {
        res.docs.forEach((data, index) => {
          if (data.data().tipo == 'Puntualidad' || data.data().tipo == 'No uso la app') {
            this.metricasService.deleteNovelty(data.data().id);
          }
        })
      }
    })
  }

  ajustarFechaPlan(servicio) {
    let contador = 0
    this.servicioService.getPlaneById(servicio.planAsociado).then(res => {
      let plan = res.data()
      plan.infoServicio.map(res => {
        if (res.id == servicio.id) {
          res.fecha = servicio.fecha
        }
        contador++
        if (contador == plan.infoServicio.length) {
          console.log(plan)
          this.servicioService.updatePlan(plan)
        }
      })
    })
  }

  actualizarCobros(servicio) {
    console.log("Entor en actualizarCobros")
    let cobros = []
    this.servicioService.getPagosUsuarioByIdPlanOServicio(servicio.planAsociado).then(res => {
      res.docs.forEach(data => {
        cobros.push(data.data());
        if (cobros.length == res.docs.length) {
          cobros = _.orderBy(cobros, ['fechaInicio'], ['asc'])
          let index = cobros.findIndex(info => moment(info.fechaInicio).format("MM-YYYY") == moment(servicio.fecha).format("MM-YYYY"))
          console.log(servicio.fecha, index, cobros)
          if (index + 1 < cobros.length) {
            cobros[index + 1].total = cobros[index + 1].total - cobros[index + 1].precio / cobros[index + 1].numeroServicios
            cobros[index + 1].descuento = cobros[index + 1].descuento + cobros[index + 1].total - cobros[index + 1].precio / cobros[index + 1].numeroServicios
            if (cobros[index + 1].descuentos == undefined) {
              cobros[index + 1].descuentos = []
              cobros[index + 1].descuentos.push("Servicio de factura anterior canselado ya pago, se repondra en esta")
            } else {
              cobros[index + 1].descuentos.push("Servicio de factura anterior canselado ya pago, se repondra en esta")
            }
            this.servicioService.setNuevoPagosUsuarios(cobros[index + 1])
              .then(() => console.log("Cobro actualizado"))
              .catch(err => console.log("Error al actualizar el cobro",))
          }
        }
      })
    })
  }

  async filtrarServers() {
    this.loaderGeneral = true;
    const info = {
      cliente: { blackList: this.infoServicioVisualizado.client.blackList ? this.infoServicioVisualizado.client.blackList : [] },
      destination: this.infoServicioVisualizado.destination,
      inicio: this.infoServicioVisualizado.hora,
      duracion: this.infoServicioVisualizado.horasDeServicio,
      fechas: [{ fecha: this.infoServicioVisualizado.fecha, id: this.infoServicioVisualizado.id }],
      dias: {},
      idPlan: this.infoServicioVisualizado.tipoServicio == "Plan Mensual" ? this.infoServicioVisualizado.planAsociado : '',
      servicio: this.infoServicioVisualizado.subCategoria
    }
    info.dias = await this.calcularDiasServicios(info.fechas)
    const infoValidada = await this.convertirNaNyNullAString(info);
    console.log(infoValidada)
    this.serversAsignables = [];

    this.servBackendService.post('api/serv/get_servers_avalible', infoValidada).subscribe(res => {
			console.log('Res api/serv/get_servers_avalible', res);
			if (res.success) {
        const data =  _.orderBy(res.data, [

          'filtroObstructor',
          (item) => {
            switch (item.estado) {
              case 'Directa':
                return 0;
              case 'Sepernumeraria':
                return 1;
              case '':
                return 2;
              default:
                return 3; // Manejo de caso inesperado
            }
          },
        ], "asc");
        this.serversAsignables = data;
        this.filteredOptions = data;
        this.loaderGeneral = false;
        this.activeUpdateServer = true;
        console.log(res.data);
			} else {
        this.loaderGeneral = false;
				Swal.fire('Error', 'Ocurrio un problema porfavor intentalo de nuevo', 'warning')
			}
		})
  }
  calcularDiasServicios(fechas) {
    return new Promise(resolve => {

      let diasServicio = []
      fechas.forEach(element => {
        let numDia = moment(element.fecha).day();
        let dia = "";
        if (numDia == 0) {
          dia = "domingo";
        } else if (numDia == 1) {
          dia = "lunes";
        } else if (numDia == 2) {
          dia = "martes";
        } else if (numDia == 3) {
          dia = "miercoles";
        } else if (numDia == 4) {
          dia = "jueves";
        } else if (numDia == 5) {
          dia = "viernes";
        } else if (numDia == 6) {
          dia = "sabado";
        }

        let diaAgregado = diasServicio.filter((day) => day == dia);
        if (diaAgregado.length == 0) diasServicio.push(dia);
      })
      resolve(diasServicio)
    })
  }
  convertirNaNyNullAString(obj: any): any {
    for (const clave in obj) {
      if (typeof obj[clave] === 'object' && obj[clave] !== null) {
        obj[clave] = this.convertirNaNyNullAString(obj[clave]); // Llamada recursiva para objetos anidados
      } else if (isNaN(obj[clave]) || obj[clave] === null) {
        obj[clave] = String(obj[clave]);
      }
    }
    return obj;
  }

  /* Mapa para seguimiento */
  loadMap() {
    this.gpsDestination = { lat: this.infoServicioVisualizado.destination.lat, lng: this.infoServicioVisualizado.destination.lng }
    // create a new map by passing HTMLElement
    const mapEle: HTMLElement = document.getElementById('map');
    this.map = new google.maps.Map(mapEle, {
      center: this.gpsDestination,
      zoom: 18,
    })

    const markerMapCliente = new google.maps.Marker({
      position: this.map.center,
      map: this.map,
      title: `Ubicacion del cliente`,
      icon: '../../assets/map/client-home.png',
    });
    if (this.infoServicioVisualizado.coordenadasInicio) {
      // this.calculateRoute(this.infoServicioVisualizado.coordenadasInicio.latitud,this.infoServicioVisualizado.coordenadasInicio.longitud);
      const markerMapInicio = new google.maps.Marker({
        position: { lat: this.infoServicioVisualizado.coordenadasInicio.latitud, lng: this.infoServicioVisualizado.coordenadasInicio.longitud },
        map: this.map,
        title: `Server Inicio el servicio,Hora ${this.infoServicioVisualizado.coordenadasInicio.hora}`,
        icon: '../../assets/map/server.png',
      });
    }
    if (this.infoServicioVisualizado.coordenadasFinalizacion) {
      const markerMapInicio = new google.maps.Marker({
        position: { lat: this.infoServicioVisualizado.coordenadasFinalizacion.latitud, lng: this.infoServicioVisualizado.coordenadasFinalizacion.longitud },
        map: this.map,
        title: `Server Finalizo el servicio,Hora: ${this.infoServicioVisualizado.coordenadasFinalizacion.hora}`,
        icon: '../../assets/map/server.png',
      });
    }
  }

  toggleAutocompleteList(){
    this.activeListOptionServers = false;
  }

  onClickAutocomplete(event: Event,type: any): void {
    event.stopPropagation();
    this.toggleAutocompleteList();
    this.updataeStateAutocompletes(type,true,null);
  }

  onInputAutocomplete(event: any,key: any,type: string){
    let value = event.target.value ? event.target.value : '';
    this.filteredOptions = this.serversAsignables.filter((res: any) =>
      res[key].toLowerCase().includes(value.toLowerCase())
    );
    console.log(this.filteredOptions)
    this.updataeStateAutocompletes(type,true,null);
  }

  onSelectAutocomplete(value: any,type: string): void {
    this.updataeStateAutocompletes(type,false,value);
  }

  updataeStateAutocompletes(type: string,state: boolean,value: any){
    console.log(value,type,state)
    switch (type){
      case 'nombreServer':
      this.activeListOptionServers = state;
      if (value != null && value.filtroObstructor == '') {
        this.serverSeleccionada = value;
        this.selectedServer();
        // this.filtroHorarioUnicaServer(server,'')
      } else {
        console.log('Bloqueada', value)
      }
      break;
    }
  }

  async selectedServer() {
    if (this.serverSeleccionada.filtroObstructor == "") {
      this.infoServicioVisualizado.server = await this.getServerSeleccionada();
      this.infoServicioVisualizado.idServer = this.serverSeleccionada.id;
      if (this.infoServicioVisualizado.estado == "orden de servicio") {
        this.infoServicioVisualizado.estado = "Pendiente";
        this.infoServicioVisualizado.subasta = false;
      }
      this.horarioEdidato = true;
    } else {
      console.log('Server bloqueada', this.serverSeleccionada)
      this.horarioEdidato = false
    }
  }

  getServerSeleccionada() {
    return new Promise(async resolve => {
      const server = (await this.serverService.detail(this.serverSeleccionada.id)).data()
      resolve(server)
    })
  }

  editar_taskList = false;
  clientTaskList = [];
  arrayDirationTask = [
    {name: '30 minutos', value: 30},
    {name: '60 minutos', value: 60},
    {name: '90 minutos', value: 90},
    {name: '120 minutos', value: 120},
  ];
  taskHours = 0;
  editarTaskList(){
    this.editar_taskList = true;
    this.clientTaskList = this.infoClient.taskList;
    console.log(this.clientTaskList,this.infoServicioVisualizado.checkList);
    this.clientTaskList.map(res=>{
      const value = this.infoServicioVisualizado.checkList.some(task=>task.name == res.name);
      console.log(value)
      res.seleccionado = value;
    })
  }

  onAddTaskToService(i){
    if (!this.clientTaskList[i].seleccionado) {
      this.taskHours += parseInt(this.clientTaskList[i].duration);
    } else {   
      this.taskHours -= parseInt(this.clientTaskList[i].duration);
      this.taskHours  = this.taskHours < 0 ? 0 : this.taskHours
    }
    this.clientTaskList[i].seleccionado = !this.clientTaskList[i].seleccionado
    console.log( this.clientTaskList, this.taskHours, parseInt(this.infoServicioVisualizado.horasDeServicio)*60)
  }

  changeTaskHours(){
    this.taskHours = 0;
    this.clientTaskList.map(res=>{
      this.taskHours += parseInt(res.duration);
    })
    console.log(this.taskHours);
  }

  validateTaskLaist(){
    const int_horasDeServicio = parseInt(this.infoServicioVisualizado.horasDeServicio);
    if( this.taskHours <= int_horasDeServicio*60 ) return true;
    else return false;
  }

  guradarTaskList(){
    let taskList = this.clientTaskList.filter(res=>res.seleccionado == true);
    console.log(taskList);
    let info = {id: this.infoServicioVisualizado,checkList:taskList};
    this.servicioService.actualizarServicio(info);
  }

}


