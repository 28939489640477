import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {

  private daysOfWeek: string[] = [
    'Domingo',   // 0
    'Lunes',     // 1
    'Martes',    // 2
    'Miércoles', // 3
    'Jueves',    // 4
    'Viernes',   // 5
    'Sábado'     // 6
  ];

  transform(value): string {
    let index = parseInt(value);
    return this.daysOfWeek[index];
  }

}
