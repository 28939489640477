import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';


@Injectable({
  providedIn: 'root'
})

export class PlanRecurrenteService {

  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
  ) { }

  public post(planRecurrente) {
    planRecurrente.createdAt = Date.now();
    return firebase.firestore().doc('plan-recurrente/' + planRecurrente.id).set(planRecurrente);
  }

  public getPlanRecurrente(id) {
    return firebase.firestore().collection('plan-recurrente').where('userId', "==", id).get()
  }

  public getPlanRecurrenteId(id){
    return firebase.firestore().collection('plan-recurrente').doc(id).get();
  }
  public getPlanByIdServer(id){
    return firebase.firestore().collection('plan-recurrente').where('idServerAsignada', "==", id).get()
  }
  public editPlanRecurrente(planRecurrente) {
    return firebase.firestore().collection('plan-recurrente').doc(planRecurrente.id).set(planRecurrente)
  }
  public getPlanesRecurrente() {
    return firebase.firestore().collection('plan-recurrente').get()
  }

  public deletePlanRecurrente(id) {
    return firebase.firestore().doc('plan-recurrente/' + id).delete()
  }
  public eliminarPlanRecurrente(planRecurrente) {
    return firebase.firestore().collection('plan-recurrente').doc(planRecurrente.id).delete();
  }

  /**
   * Funcion para crear id aleatorio
   */
  public makeId() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 25; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
