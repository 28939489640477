import { Injectable } from "@angular/core";
import { AngularFireStorageModule } from "angularfire2/storage";
import { Http } from "@angular/http";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireStorage } from "angularfire2/storage";
import * as firebase from "firebase";
import { AngularFirestore } from "angularfire2/firestore";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Helper } from "../utility/helper";
import * as moment from "moment";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ServicioService {
  public fecha = moment().subtract(3, "months").format("YYYY-MM-DD");
  public fechaActual = moment().format("YYYY-MM-DD");
  private dbRef = firebase.firestore().collection("servicios");

  constructor(
    private afd: AngularFireDatabase,

    private storage: AngularFireStorage
  ) { }

  detaill(id){
    return this.dbRef.doc(`${id}`).get();
  }
  getServicioRealTime(id) {
    return this.dbRef.where("id", "==", id);
  }

  delete(id){
    firebase.firestore().collection('servicios').doc(`${id}`).update({estado:'delete',eliminado: 'admin'});
    return firebase.firestore().collection('servicios').doc(`${id}`).delete().then(()=>console.log("eliminado"));
  }

  public getByDate(fecha) {
    return this.dbRef
      .where("fecha", "==", fecha)
      .get();
  }
  public getServiciosDesdeUnAfecha(startDate: string) {
    return this.dbRef
      .where("fecha", ">=", startDate)
      .get();
  }
  public getServiceByState(state: string) {
    return this.dbRef
      .where("estado", "==", state)
      .orderBy("fecha")
      .get();
  }

  // sevicios ultimos tres meses por usuario
  public getServicioUTMPU(id, fecha, fechaActual) {
    return this.dbRef
      .where("userid", "==", id)
      .where("fecha", ">=", fecha)
      .where("fecha", "<=", fechaActual)
      .get();
  }
  
  getInDateRange(start_date,end_date){
    return this.dbRef
      .where("fecha", ">=", start_date)
      .where("fecha", "<=", end_date)
      .get();
  }
  getServiciosRangoFechasPaginado(inicioSemana, finSemana){
    return this.dbRef
    .where("fecha", ">=", inicioSemana)
    .where("fecha", "<=", finSemana)
    .limit(100)
  }
  public getServiciosByRangeFechaRealTime(startDate: string, endDate: string) {
    return this.dbRef
      .where("fecha", ">=", startDate)
      .where("fecha", "<=", endDate);
  }
  public getByStateInDateRange(state, start_date, end_date) {
    return this.dbRef
      .where("estado", "==", state)
      .where("fecha", ">=", start_date)
      .where("fecha", "<=", end_date)
      .get();
  }
  public getServiciosPorPagoServer(inicioSemana,finSemana) {
    return this.dbRef
      .where("fecha", "<=", finSemana)
      .where("estadoPagoServer", "==", "Pendiente")
      .where("estado", "==", "Terminado")
      .get();
  }
  public getServiciosIndividualesPorFecha(inicioSemana, finSemana) {
    return this.dbRef
      .where("fecha", ">=", inicioSemana)
      .where("fecha", "<=", finSemana)
      .where("tipoServicio", "==", "Plan Individual")
      .get();
  }

  public getServiciosIndividualesTerminadosRangoDeFecha(fecha_inicio, fecha_fin) {
    return this.dbRef
      .where("fecha", ">=", fecha_inicio)
      .where("fecha", "<=", fecha_fin)
      .where("estado", "==", "Terminado")
      .where("tipoServicio", "==", "Plan Individual")
      .get();
  }
  public getServiciosIndividualesPostventa(fecha_inicio, fecha_fin,responsable) {
    console.log(fecha_inicio, fecha_fin,responsable);
    return this.dbRef
      .where("fecha", ">=", fecha_inicio)
      .where("fecha", "<=", fecha_fin)
      .where("estado", "==", "Terminado")
      .where("tipoServicio", "==", "Plan Individual")
      .where("asesor","==", responsable)
      .get();
  }
  public getTerminadosPorfechaDeAsesor(fecha_inicio, fecha_fin,asesor) {
    return this.dbRef
      .where("fecha", ">=", fecha_inicio)
      .where("fecha", "<=", fecha_fin)
      .where("estado", "==", "Terminado")
      .where("asesor","==", asesor)
      .get();
  }

  public getByUser(id) {
    return this.dbRef
      .where("userid", "==", id)
      .get();
  }

  public getClientesApp() {
    return firebase
      .firestore()
      .collection("users")
      .where("creado", "==", "app")
      .where("fecha", ">", "2023-01-01")
      .get();
  }

  getServiciosSubasta() {
    return this.dbRef
      .where("subasta", "==", true)
      .where("estadoPago", "==", "Pago")
      .where("idServer", "==", "")
      .where("fecha", ">=", moment().format("YYYY-MM-DD"))
      .get();
  }
  /* consultas por server */

  public getServiciosServer(id) {
    return this.dbRef
      .where("idServer", "==", id)
      .get();
  }

  getByServerInDateRange(id_server,star_date,end_date){
    return firebase
    .firestore()
    .collection("servicios")
    .where("idServer", "==", id_server)
    .where("fecha", ">=", star_date)
    .where("fecha", "<=", end_date)
    .get();
  }

  public getByStateAndServerInDataRange(id_server,star_date,end_date,state) {
    return this.dbRef
      .where("idServer", "==", id_server)
      .where("fecha", ">=", star_date)
      .where("fecha", "<=", end_date)
      // .where('estado','==',state)
      .get();
  }
  public getByServerOnADate(idServer, fecha) {
    return this.dbRef
      .where("idServer", "==", idServer)
      .where("fecha", "==", fecha)
      .get();
  }

  public getByServerFromADate(idServer, fecha) {
    return this.dbRef
      .where("idServer", "==", idServer)
      .where("fecha", ">=", fecha)
      .get();
  }
  
  public getServiciosIndividualesServer(id) {
    return this.dbRef
      .where("idServer", "==", id)
      .where('tipoServicio','==','Plan Individual')
      .get();
  }

  getLastServerService(id){
    return this.dbRef
    .orderBy('fecha','asc')
    .where("idServer", "==", id)
    .where("estado", "==","Terminado")
    .limit(1)
    .get();
  }

  public getServiciosServerTerminado(id) {
    return this.dbRef
      .where("idServer", "==", id)
      .where("estado", "==", "Terminado")
      .get();
  }

  public getByTipoInDateRange(id, tipo, inicioSemana, finSemana) {
    return this.dbRef
      .where("idServer", "==", id)
      .where("tipoServicio", "==", tipo)
      .where("fecha", ">=", inicioSemana)
      .where("fecha", "<=", finSemana)
      .get();
  }

  public getServicios() {
    return this.dbRef
      .orderBy("fecha", "asc")
      .get();
  }

  public getServiciosPorFechaCreacion(fchInicio, fchFin) {
    return this.dbRef
      .where("fechaCreacion", ">=", fchInicio)
      .where("fechaCreacion", "<=", fchFin)
      .get();
  }

  public getServiciosIndividualesPorFechaCreacion(fchInicio, fchFin) {
    return this.dbRef
      .where("fechaCreacion", ">=", fchInicio)
      .where("fechaCreacion", "<=", fchFin)
      .where("tipoServicio", "==", "Plan Individual")
      .get();
  }

  public getServiciosPostventa(inicioSemana, finSemana) {
    return this.dbRef
      .where("programarPostventa", "==", true)
      .get();
  }

  public getServiciosbyId(id) {
    return firebase.firestore().doc(`servicios/${id}`).get();
  }

  public getServiciosUsuarioTerminado(id) {
    return this.dbRef
      .where("userid", "==", id)
      .where("estado", "==", "Terminado")
      .get();
  }
  public getServiciosIndividualesUsuarioTerminado(id) {
    return this.dbRef
      .where("userid", "==", id)
      .where("tipoServicio", "==", "Plan Individual")
      .where("estado", "==", "Terminado")
      .get();
  }
  public getServiciosPlanUsuarioTerminado(id) {
    return this.dbRef
      .where("userid", "==", id)
      .where("tipoServicio", "==", "Plan Mensual")
      .where("estado", "==", "Terminado")
      .get();
  }

  public getUser(id) {
    return this.afd.object(`users/${id.userid}`).valueChanges();
  }

  public registerServicio(servicio) {
    console.log("dentro de la funcion", servicio);
    return firebase
      .firestore()
      .doc(`servicios/${servicio.id}`)
      .set(Object.assign({}, servicio));
  }

  public registerServicioMultiples(servicio) {
    return new Promise((r, e) => {
      servicio.id = new Date().getTime();
      servicio.fechaCreacion = new Date().getTime();
      firebase
        .firestore()
        .doc(`servicios/${servicio.id}`)
        .set(Object.assign({}, servicio))
        .then((dato) => {
          servicio;
        });
    });
  }


  public updateServicio(servicio: any) {
    console.log(servicio);
    servicio.fecha = moment(servicio.fecha).format("YYYY-MM-DD");
    return firebase.firestore().doc(`servicios/${servicio.id}`).set(servicio);
  }
  
  public actualizarServicio(info){
    return firebase.firestore().doc(`servicios/${info.id}`).update(info);
  }

  public updatePlanAsociado(idServicio, idPlan) {
    // const New_fecha = this.helper.dateDefaultNotHour(servicio.fecha);
    return firebase
      .firestore()
      .doc(`servicios/${idServicio}`)
      .update({ planAsociado: idPlan });
  }

  actualizaInfoCobroServicio(id, numFactura, estadoPago) {
    return firebase
      .firestore()
      .doc(`servicios/${id}`)
      .update({ numFactura: numFactura, estadoPago: estadoPago });
  }

  public updateServicioTerminado(servicio: any) {
    return firebase
      .firestore()
      .doc(`servicios/${servicio.id}`)
      .update(servicio);
  }

  public servicioConNovedad(idServicio) {
    return firebase
      .firestore()
      .doc(`servicios/${idServicio}`)
      .update({ novedad: true });
  }
  public actualizarEstadoServicio(id, info) {
    return firebase.firestore().doc(`servicios/${id}`).update({ estado: info });
  }
  public actualizarEstadoPostventa(idServicio, info) {
    return firebase
      .firestore()
      .doc(`servicios/${idServicio}`)
      .update({ estadoPostventa: info });
  }
  public actualizarEstadoDePago(idServicio, info) {
    return firebase
      .firestore()
      .doc(`servicios/${idServicio}`)
      .update({ updatePagoServer: info });
  }

  public actualizarfechaPostventa(idServicio, info, estado) {
    return firebase
      .firestore()
      .doc(`servicios/${idServicio}`)
      .update({ fechaHoraPostventa: info, estadoPostventa: estado });
  }
/* ----------------PLANES------------------------------ */

  public idPlanAgendado = "";
  public registrarPlan(plan, planRecurrente) {
    return firebase
      .firestore()
      .collection("plan")
      .add(Object.assign({}, plan))
      .then((ref) => {
        console.log(ref.id);
        planRecurrente.id = ref.id;
        this.idPlanAgendado = ref.id;
        firebase.firestore().doc(`plan/${ref.id}`).update({ id: ref.id });
        this.asignarPlanAServicios(plan, ref.id);
        this.asignarPlanRecurrete(planRecurrente);
      });
  }

  asignarPlanAServicios(plan, idPlan) {
    plan.infoServicio.forEach((element) => {
      firebase
        .firestore()
        .doc("servicios/" + element.id)
        .update({ planAsociado: idPlan });
    });
  }

  public getPlaneById(id) {
    return firebase.firestore().collection("plan").doc(`${id}`).get();
  }
  public getPlanesCliente(id) {
    return firebase
      .firestore()
      .collection("plan")
      .where("userid", "==", id)
      .get();
  }

  public getPlanesByAsesor(asesor) {
    return firebase
      .firestore()
      .collection("plan")
      .where("asesor", "==", asesor)
      .get();
  }

  /*------ Plan recurrente -------*/

  public updateServerPlanRecurrente(id, server) {
    let nameServer = `${server.name} ${server.apellido}`;
    firebase
      .firestore()
      .doc(`plan-recurrente/${id}`)
      .update({ idServerAsignada: server.id, serverName: nameServer });
  }
  public updatePlanRecurrente(info) {
    return firebase.firestore().doc(`plan-recurrente/${info.id}`).update(info);
  }

  public asignarPlanRecurrete(planRec) {
    console.log("datos que va gurdar el plan recurrente", planRec);
    return firebase
      .firestore()
      .doc("plan-recurrente/" + planRec.id)
      .set(planRec)
      .then((res) => {
        console.log("guardo el plan recurrente");
      })
      .catch((err) => {
        console.log("ocurrio un error en plan recurrete,", err);
      });
  }

  public getPlanRecurrenteIdServer(idServer) {
    return firebase
      .firestore()
      .collection("plan-recurrente/")
      .where("idServerAsignada", "==", idServer)
      .get();
  }
  public getPlanRecurrenteIdServer_rt(idServer) {
    return firebase
      .firestore()
      .collection("plan-recurrente/")
      .where("idServerAsignada", "==", idServer)
  }
  public getPlanRecurrenteIdClient(idCliente) {
    return firebase
      .firestore()
      .collection("plan-recurrente/")
      .where("clientId", "==", idCliente)
      .get();
  }

  public getAllPlanRecurrente() {
    return firebase.firestore().collection("plan-recurrente/").get();
  }
  public getAllPlanRecurrente_rt() {
    return firebase.firestore().collection("plan-recurrente/")
  }
  /*------*/

  public updatePlan(plan) {
    console.log("Entro en la funcion de actualizarPlan", plan.id);
    return firebase.firestore().collection("plan").doc(plan.id).update(plan);
  }

  public putPicture(pictureName, file) {
    return this.storage.ref(`comprobante-pago/${pictureName}`).put(file);
  }
  public putFile(pictureName, file) {
    return this.storage.ref(`${pictureName}`).put(file);
  }
  public putPictureComprobante(pictureName, file) {
    return this.storage.upload(`comprobante-pago/${pictureName}`, file);
  }

  public updatePlanPosventa(plan, info) {
    // console.log("Entro en la funcion de actualizarPlan",plan)
    return firebase
      .firestore()
      .doc(`plan/${plan.id}`)
      .update({ fechaPostventa: info });
  }

  public getURL(pictureName) {
    return this.storage.ref(`comprobante-pago/${pictureName}`).getDownloadURL();
  }

  public guardartransaccion(transaccion) {
    return firebase
      .firestore()
      .collection("transaccion")
      .add(Object.assign({}, transaccion))
      .then((ref) => {
        firebase
          .firestore()
          .doc(`transaccion/${ref.id}`)
          .update({ id: ref.id });
      });
  }

  public getTransaccion() {
    return firebase.firestore().collection("transaccion").get();
  }

  public getTransaccionId(id) {
    return firebase.firestore().doc(`transaccion/${id}`).get();
  }

  public updateTransaccion(transaccion) {
    return firebase
      .firestore()
      .doc(`transaccion/${transaccion.id}`)
      .update(Object.assign({}, transaccion));
  }
  public actualizarEstadoPostventaPlan(idPlan, info) {
    return firebase
      .firestore()
      .doc(`plan/${idPlan}`)
      .update({ estadoPostventa: info });
  }


  public getCalificacion(idServicio) {
    return firebase.firestore().doc(`calificaciones/${idServicio}`);
  }

  public getCalificacionByiD(idServicio) {
    return firebase.firestore().doc(`calificaciones/${idServicio}`).get();
  }


  public eliminarPlan(idplan) {
    // console.log(plan.id)
    let id = idplan.toString();
    return firebase
      .firestore().collection(`plan`).doc(id).delete()
      .catch((err) => {
        console.log(err);
      });
  }

  public guardarDiasBloqueados(datos) {
    return firebase
      .firestore()
      .doc(`disponibilidad/${datos.idServer}`)
      .set(datos);
  }

  public getDisponibilidad() {
    return firebase.firestore().collection("disponibilidad/").get();
  }

  public getDisponibilidadByIdDashboard(id) {
    return firebase
      .firestore()
      .collection("disponibilidad/").doc(id)
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelReportComercial({
    fileName,
    firstSheet,
    firstSheetName,
    secondSheet,
    secondSheetName,
  }): void {
    const firstWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(firstSheet);
    const secondWorksheet: XLSX.WorkSheet =
      XLSX.utils.json_to_sheet(secondSheet);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        [firstSheetName]: firstWorksheet,
        [secondSheetName]: secondWorksheet,
      },
      SheetNames: [firstSheetName, secondSheetName],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  // funciones paa cerar pagosUsuario
  public postPagosUsuario(pagoUsuario, tipoServicio) {
    console.log("entro en postPagoUsuario");
    //pagoUsuario.id = this.idPlanAgendado
    if (tipoServicio == "Plan Mensual") {
      pagoUsuario.cobrosRealizados[0].idPlanOServicio = this.idPlanAgendado;
    }
    return firebase
      .firestore()
      .collection("pagosUsuarios")
      .doc(pagoUsuario.id)
      .set(Object.assign({}, pagoUsuario));
  }
  postPagoPlanUsuario(pagoUsuario) {
    return firebase
      .firestore()
      .doc("pagosUsuarios/" + pagoUsuario.id)
      .set(pagoUsuario)
      .then((res) => {
        console.log("Hostorial de cobros actualizado");
      });
  }

  async addPagoPlanUsuario(info){
    console.log(info)
    const res = await firebase.firestore().collection('pagosUsuarios').add(info);
    res.update({id: res.id});
    return res.id
  }

  deletePagoPlan(id){
    return firebase.firestore().collection('pagosUsuarios').doc(`${id}`).delete;
  }

  public guardaruevoCobro(pagoUsuario, tipoServicio) {
    if (tipoServicio == "Plan Mensual") {
      pagoUsuario.cobrosRealizados[0].idPlanOServicio = this.idPlanAgendado;
    }
    return firebase
      .firestore()
      .doc("pagosUsuarios/" + pagoUsuario.id)
      .set(pagoUsuario);
  }

  public actualizarCobro(pagoUsuario) {
    return firebase
      .firestore()
      .doc("pagosUsuarios/" + pagoUsuario.id)
      .set(pagoUsuario);
  }
  public eliminarCobro(id) {
    return firebase.firestore().doc(`pagosUsuarios/${id}`).delete();
  }

  public getPagosUsuario() {
    return firebase.firestore().collection("pagosUsuarios").get();
  }

  public getPagosUsuarioByClientId(clientId) {
    return firebase
      .firestore()
      .collection("pagosUsuarios")
      .where("clientId", "==", clientId)
      .get();
  }

  public getServuiciosIdClientFecha(idCliente) {
    let fecha = moment().format("YYYY-MM-DD");
    return firebase
      .firestore()
      .collection("pagosUsuarios")
      .where("IdCliente", "==", idCliente)
      .where("fecha", ">=", fecha)
      .get();
  }

  getPagosPlanesTresMesesAtras() {
    const fechaLimite = moment().subtract(3, "month").format("YYYY-MM-DD");
    // const fechaLimite = '2023-02-01';
    console.log("Fecha limite de la consulta getPagos", fechaLimite);
    return firebase
      .firestore()
      .collection("pagosUsuarios")
      .where("fechaInicio", ">", fechaLimite)
      .where("tipoServicio", "==", "Plan Mensual")
      .get();
  }

  getAllPagosUsuario() {
    return firebase
      .firestore()
      .collection("pagosUsuarios")
      .where("fechaInicio", ">", "2022-08-31")
      .where("fechaInicio", "<", "2022-10-01")
      .get();
  }
  public getPagosUsuarioByIdPlanOServicio(id) {
    return firebase
      .firestore()
      .collection("pagosUsuarios")
      .where("idPlanOServicio", "==", id)
      .orderBy('fechaInicio','asc')
      .get();
  }
  public setNuevoPagosUsuarios(pagoUsuario) {
    return firebase
      .firestore()
      .doc("pagosUsuarios/" + pagoUsuario.id)
      .set(pagoUsuario);
  }

  public deleteNotisActulizar() {
    return firebase
      .firestore()
      .collection("notificaciones")
      .where("title", "==", "Servicio actualizado.")
      .get()
      .then((res) => {
        res.docs.forEach((data) => {
          const id = data.data().id.toString();
          firebase.firestore().collection("notificaciones").doc(id).delete()
            .then(() => {
              console.log("oti eliminada");
            });
        });
      });
  }

  public getDisponibilidadById(id) {
    return firebase
      .firestore()
      .collection("disponibilidad/")
      .where("idServer", "==", id)
      .get();
  }
  public getPagoServerbyId(id) {
    return firebase.firestore().doc(`pagos-server/${id}`).get();
  }
}
