import { BrowserModule } from "@angular/platform-browser";
import { MatNativeDateModule } from "@angular/material/core";
import { MatListModule } from "@angular/material/list";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { HttpModule } from "@angular/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
// import { IonicModule, IonicIconsModule } from '@ionic/angular';
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
	NbThemeModule,
	NbLayoutModule,
	NbCardModule,
	NbSidebarModule,
	NbSidebarService,
	NbMenuModule,
	NbMenuService,
	NbActionsModule,
	NbInputModule,
	NbListModule,
	NbButtonModule,
	NbCalendarModule,
	NbDatepickerModule,
	NbAccordionModule,
	NbPopoverModule,
	NbToastrModule,
	NbDialogModule,
	NbSpinnerModule,
	NbUserModule,
	NbSelectModule,
	NbTabsetModule,
	NbCalendarRangeModule,
	NbCheckboxModule,
} from "@nebular/theme";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { ChartModule } from "angular2-chartjs";
import { NbMomentDateModule } from "@nebular/moment";
import { NbDateFnsDateModule } from "@nebular/date-fns";
import { HttpClientModule } from "@angular/common/http";
import { MyDateRangePickerModule } from "mydaterangepicker";
import { NgApexchartsModule } from "ng-apexcharts";
import { registerLocaleData } from "@angular/common";
import localeEsAr from "@angular/common/locales/es-AR";
registerLocaleData(localeEsAr, "es-Ar");
//Calendar
import { CommonModule } from "@angular/common";
import { FlatpickrModule } from "angularx-flatpickr";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AgmDirectionModule } from "agm-direction";
// Servicios
import { CuponService } from "./services/cupon.service";
import { ServerService } from "./services/server.service";
import { ServicioService } from "./services/servicio.service";
import { UsuarioService } from "./services/usuario.service";
import { CandidatoService } from "./services/candidato.service";
import { GoogleService } from "./services/google.service";
import { UsuarioAdminService } from "./services/usuarios-admin.service";
//Firebase
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireModule } from "angularfire2";
import {
	AngularFireStorageModule,
	AngularFireStorage,
} from "angularfire2/storage";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
// Vistas
import { HomeComponent } from "./public/home/home.component";
import { TransaccionesComponent } from "./public/transacciones/transacciones.component";
import { DashboardComponent } from "./public/dashboard/dashboard.component";
import { LoginComponent } from "./public/login/login.component";
import { AgmCoreModule } from "@agm/core";
import { UsuariosComponent } from "./public/usuarios/usuarios.component";
import { ClientsComponent } from "./public/client/clients.component";
import { ServersComponent } from "./public/servers/servers.component";
import { ServiciosComponent } from "./public/servicios/servicios.component";
import { VerUsurarioComponent } from "./public/ver-usurario/ver-usurario.component";
import { VerServerComponent } from "./public/ver-server/ver-server.component";
import { PagosServerComponent } from "./public/pagos-server/pagos-server.component";
import { VerTransaccionComponent } from "./public/ver-transaccion/ver-transaccion.component";
import { CalendarioServiciosComponent } from "./public/calendario-servicios/calendario-servicios.component";
import { CuponesComponent } from "./public/cupones/cupones.component";

import { ListarPlanComponent } from "./public/listar-plan/listar-plan.component";
import { CandidatosComponent } from "./public/candidatos/candidatos.component";
import { VerCandidatoEntrevistaComponent } from "./public/candidatos/ver-candidato-entrevista/ver-candidato-entrevista.component";
import { VerCandidatoVerificacionComponent } from "./public/candidatos/ver-candidato-verificacion/ver-candidato-verificacion.component";
import { VerCandidatoComponent } from "./public/candidatos/ver-candidato/ver-candidato.component";
import { DisponibilidadComponent } from "./public/disponibilidad/disponibilidad.component";
import { NuevaDisponibilidadComponent } from "./public/disponibilidad/nuevaDisponibilidad/nueva-disponibilidad.component";
import { VerPlanComponent } from "./public/listar-plan/ver-plan/ver-plan.component";
import { PostventaComponent } from "./public/postventa/postventa.component";
import { LeadsComponent } from "./public/leads/leads.component";
import { LocationService } from "./services/location.service";
import { LocationComponent } from "./public/location/location.component";
import { MetricasComponent } from "./public/metricas/metricas.component";
import { verUsuarios } from './public/usuarios/ver_usuarios/ver_usuarios.component'
// Seguridad
import { AuthGuard } from "./guards/auth.guard";
// Variable de entorno
import { environment } from "src/environments/environment";
import { PlanRecurrenteService } from "./services/plan-recurrente.service";
import { AutocompleteComponent } from "./public/autocomplete/autocomplete.component";
import { VerPostventaComponent } from "./public/ver-postventa/ver-postventa.component";
import { VerLeadComponent } from "./public/ver-lead/ver-lead.component";
import { CrearCandidatoComponent } from "./public/candidatos/crear-candidato/crear-candidato.component";
import { VerPagoServerComponent } from "./public/pagos-server/ver-pago-server/ver-pago-server.component";
import { VerPagadosComponent } from "./public/pagos-server/ver-pagados/ver-pagados.component";
import { PagosServerService } from "./services/pagos-server.service";
import { FacturacionCarteraComponent } from "./public/facturacion-cartera/facturacion-cartera.component";
import { NovedadesComponent } from "./public/novedades/novedades.component";
import { NgxPaginationModule } from "ngx-pagination";
import { VerNovedadComponent } from "./public/ver-novedad/ver-novedad.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { PushNotificationService } from "./services/push-notification.service";
import { ConversationServices } from "./services/conversation.service";

import { NuevoServiciosComponent } from "./public/servicios/nuevoServicios.component";
import { ObservadorComponent } from "./public/observador/observador.component";
import { PatchNotesComponent } from "./public/patch-notes/patch-notes.component";
import { MdlsLeadsComponent } from "./public/mdls-leads/mdls-leads.component";
import { ModalServerComponent } from "./public/modals/modal-server/modal-server.component";
import { MdlsClientesComponent } from "./public/mdls-clientes/mdls-clientes.component";
import { MdlsInfoServicioComponent } from "./public/mdls-info-servicio/mdls-info-servicio.component";
import { GestionarPreciosComponent } from "./public/gestionar-precios/gestionar-precios.component";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ChatComponent } from "./public/chat/chat.component";

import { ListMetricsComponent } from "./public/list-metrics/list-metrics.component";
import { AbbrnumPipe } from "./utility/abbrnum.pipe";

// Angular Material
import {
	MatAutocompleteModule,
	MatBadgeModule,
	MatBottomSheetModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatExpansionModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	// MatListModule,
	MatMenuModule,
	// MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatRippleModule,
	MatSelectModule,
	MatSidenavModule,
	// MatSliderModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatSortModule,
	MatStepperModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
	MatTreeModule,
} from "@angular/material";
import { MdlsAgendarComponent } from "./public/mdls-agendar/mdls-agendar.component";
import { ClientesMarketingComponent } from "./public/clientes-marketing/clientes-marketing.component";
import { MdlsEnvioMensajesComponent } from "./public/mdls-envio-mensajes/mdls-envio-mensajes.component";
import { ExportarMetricasComponent } from "./public/exportar-metricas/exportar-metricas.component";
import { CandidatesComponent } from './public/candidates/candidates.component';
import { LeadsCaComponent } from './public/leads-ca/leads-ca.component';
import { CandidatesServComponent } from './public/candidates-serv/candidates-serv.component';
import { DayOfWeekPipe } from './pipes/day-of-week.pipe';
import { PreventIncrementDirective } from './directives/prevent-increment.directive';
import { EditableFieldComponent } from './public/editable-field/editable-field.component';
import { AutoGrowTextZoneDirective } from './directives/auto-grow-text-zone.directive';

@NgModule({
	declarations: [
		ExportarMetricasComponent,
		NuevoServiciosComponent,
		AppComponent,
		AbbrnumPipe,
		AutocompleteComponent,
		HomeComponent,
		TransaccionesComponent,
		DashboardComponent,
		LoginComponent,
		MetricasComponent,
		UsuariosComponent,
		ClientsComponent,
		ServersComponent,
		ServiciosComponent,
		VerUsurarioComponent,
		VerServerComponent,
		VerTransaccionComponent,
		CalendarioServiciosComponent,
		CuponesComponent,
		ListarPlanComponent,
		CandidatosComponent,
		VerCandidatoEntrevistaComponent,
		VerCandidatoVerificacionComponent,
		VerCandidatoComponent,
		DisponibilidadComponent,
		NuevaDisponibilidadComponent,
		VerPlanComponent,
		PostventaComponent,
		LeadsComponent,
		LocationComponent,
		VerPostventaComponent,
		VerLeadComponent,
		CrearCandidatoComponent,
		PagosServerComponent,
		VerPagoServerComponent,
		VerPagadosComponent,
		FacturacionCarteraComponent,
		NovedadesComponent,
		VerNovedadComponent,
		ObservadorComponent,
		PatchNotesComponent,
		MdlsLeadsComponent,
		ModalServerComponent,
		MdlsClientesComponent,
		MdlsInfoServicioComponent,
		ChatComponent,
		ListMetricsComponent,
		AbbrnumPipe,
		MdlsAgendarComponent,
		GestionarPreciosComponent,
		ClientesMarketingComponent,
		MdlsEnvioMensajesComponent,
		CandidatesComponent,
		verUsuarios,
		LeadsCaComponent,
		CandidatesServComponent,
		//Pipes ----------------
		DayOfWeekPipe,
		// Directives ----------
		PreventIncrementDirective,
		EditableFieldComponent,
		AutoGrowTextZoneDirective,
		
	],
	imports: [
		// IonicModule.forRoot(), IonicIconsModule,
		CommonModule,
		NgxPaginationModule,
		MatNativeDateModule,
		MatListModule,
		NgMultiSelectDropDownModule.forRoot(),
		NgbModule,
		AgmCoreModule.forRoot({
			apiKey: "AIzaSyCjgDSWwtQstvHkCB-gytm1l_o7ByUJMyw",
		}),
		AgmDirectionModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		NgApexchartsModule,
		FormsModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NoopAnimationsModule,
		NbThemeModule.forRoot({ name: "default" }),
		NbCheckboxModule,
		NbLayoutModule,
		NbSidebarModule,
		NbActionsModule,
		NbCardModule,
		AutocompleteLibModule,
		NbAccordionModule,
		NbInputModule,
		NbListModule,
		NbButtonModule,
		NbSelectModule,
		NbTabsetModule,
		NbCalendarModule,
		MatSliderModule,
		Ng2SmartTableModule,
		NbDatepickerModule.forRoot(),
		NbMenuModule.forRoot(),
		ReactiveFormsModule,
		NbToastrModule.forRoot(),
		NbDialogModule.forChild(),
		NbSpinnerModule,
		NbCalendarRangeModule,
		NbUserModule,
		ChartModule,
		MyDateRangePickerModule,
		NbMomentDateModule,
		NbDateFnsDateModule.forRoot({ format: "dd.MM.yyyy" }),
		NbDateFnsDateModule.forChild({ format: "dd.MM.yyyy" }),
		NbDatepickerModule.forRoot(),
		HttpModule,
		HttpClientModule,
		AngularFireModule.initializeApp(environment.hashDB),
		AngularFireFunctionsModule,
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		AngularFirestoreModule,
		CommonModule,
		FormsModule,
		//Angular material
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		MatMenuModule,
		MatTabsModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatToolbarModule,
		MatTooltipModule,
		MatTreeModule,

		FlatpickrModule.forRoot(),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: environment.production,
		}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		{ provide: LOCALE_ID, useValue: "es-Ar" },
		NbSidebarService,
		NbMenuService,
		CuponService,
		ServerService,
		PushNotificationService,
		PagosServerService,
		LeadsComponent,
		ServicioService,
		UsuarioAdminService,
		UsuarioService,
		GoogleService,
		CandidatoService,
		AngularFireStorage,
		PlanRecurrenteService,
		AuthGuard,
		LocationService,
		ConversationServices,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
