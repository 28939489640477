import { Injectable } from '@angular/core';
import { HttpModule, Http, Headers } from "@angular/http";
import { map, switchMap } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})

export class TransaccionesService {
  public baseURL = 'https://serv-admin.herokuapp.com/';
  public abrirSidebar: boolean;
  headers: Headers;
  constructor(    
    private http: Http
  ){
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  }

  post(object: string, data: any) {
    return this.http.post(this.baseURL + '' + object, JSON.stringify(data), { headers: this.headers })
      .pipe(
        map((res: any) => res.json())
      );
  }

  
}